import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import type { SectionWithProducts } from '../section/section-with-products';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { Section } from '../section/section';
import { exists } from '../../../functions/exists';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnCannabinoidType } from '../../enum/shared/section-column-cannabinoid-type';

export class BellaLunaPrintMenu extends PrintMenu {

  getThemeClass(): string {
    return 'bella-luna-print-theme';
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.courierRegular,
      FONT.courierBold
    ];
  }

  getPageTopMargin(): string {
    return '1in';
  }

  getPageBottomMargin(): string {
    return '1in';
  }

  getPageLeftMargin(): string {
    return '1in';
  }

  getPageRightMargin(): string {
    return '1in';
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4, false),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantType, 9],
      [SectionColumnProductInfoType.Stock, 10],
      [SectionColumnCannabinoidType.THCAndCBD, 11],
      [SectionColumnProductInfoType.VariantPrice, 12],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 13],
    ]);
  }

  getHeaderWrapperClass(): string {
    return 'header-container-wrapper';
  }

  getTitleSectionTopMargin(): string {
    return '1rem';
  }

  getTitleSectionBottomMargin(): string {
    return '3.75rem';
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    const rowStyling = super.getProductWrapperStyling(section, sectionRowViewModel, odd);
    const borderColor = section.metadata.sectionBodyTextColor || this.menuOptions.bodyTextColor;
    rowStyling['border-bottom'] = `1px solid ${borderColor}`;
    return rowStyling;
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getShouldInflateColumnForRow(
    s: Section,
    col: SectionColumnViewModel,
    row: SectionRowViewModel
  ): boolean {
    const badge = col?.columnType === SectionColumnProductInfoType.Badge;
    return !badge || (badge && exists(row?.hasBadges()));
  }

}
