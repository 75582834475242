import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { DougFiveBySevenBackgroundViewModel } from './doug-five-by-seven-background-view-model';
import type { GroupedByBrandShelfTalkerCardData } from '../../../../../../../models/shelf-talkers/grouped-by-brand-shelf-talker-card-data';

@Component({
  selector: 'app-doug-five-by-seven-background',
  templateUrl: './doug-five-by-seven-background.component.html',
  styleUrls: ['./doug-five-by-seven-background.component.scss'],
  providers: [DougFiveBySevenBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougFiveBySevenBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public override viewModel: DougFiveBySevenBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: GroupedByBrandShelfTalkerCardData;

}
