import { Type } from '@angular/core';
import { MenuHeaderSproutComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-sprout/menu-header-sprout.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionColumnCannabinoidType } from '../../enum/shared/section-column-cannabinoid-type';

export class SproutMenu extends PrintMenu {

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'sprout-theme';
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.AllPages;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderSproutComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.None;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowSectionHeaderColorAsIcon(): boolean {
    return true;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.acuminProWideBold,
      FONT.acuminProWideSemibold,
      FONT.acuminProWideRegular
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4, false),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantType, 9],
      [SectionColumnProductInfoType.Stock, 10],
      [SectionColumnCannabinoidType.THCAndCBD, 11],
      [SectionColumnProductInfoType.VariantPrice, 12],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 13],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
