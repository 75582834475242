export enum SectionColumnConfigDataValue {
  // Asset Column - Image Fit Information
  Contain = 'Contain',
  Cover = 'Cover',
  // Secondary Price Column - Price Information
  SecondaryPrice = 'SecondaryPrice',
  PricePerUOM = 'PricePerUOM',
  OriginalPrice = 'OriginalPrice',
  SaleOriginalPrice = 'SaleOriginalPrice',
  OriginalAndSalePrice = 'OriginalAndSalePrice',
  TaxesInPrice = 'TaxesInPrice',
  TaxesInRoundedPrice = 'TaxesInRoundedPrice',
  PreTaxPrice = 'PreTaxPrice',
  // Strain Type Column - Strain Type Layout
  StrainTypeSymbol = 'StrainTypeSymbol',
  StrainTypeWord = 'StrainTypeWord',
  // Cannabinoids / Terpenes
  NumericValue = 'NumericValue',
  PresenceIcon = 'PresenceIcon',
}
