<div class="main-content">
  <div class="top-container">
    <div class="left-content">
      <div
        *rxIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        appPrintCardScaleDownText
        class="product-card-name"
        [maxHeightInPx]="20"
        [maxWidthInPx]="212"
        [adjustLineHeight]="false"
        [transparentWhileScaling]="true"
        [style.color]="viewModel.headerTextColor$ | push">
        {{ title | trimSafely }}
      </div>

      <div #brandAndLabelContainer class="brand-and-label-container">
        <div class="product-brand-wrapper">
          <div
            *rxIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
            appPrintCardScaleDownText
            class="product-card-brand"
            [maxHeightInPx]="14"
            [maxWidthInPx]="212"
            [adjustLineHeight]="false"
            [transparentWhileScaling]="true"
            [style.color]="viewModel.headerTextColor$ | push">
            {{ brand | uppercase }}
          </div>
        </div>

        <div>
          <app-label-inflator
            class="print-card-label"
            [columnLabel]="false"
            [labelComponentInterface]="data"
            [reset]="false">
          </app-label-inflator>
        </div>
      </div>
    </div>

    <div class="right-content">
      <div
        class="product-card-strain-type"
        *rxIf="viewModel.strainText$ | push as strainType"
        [style.background-color]="viewModel.cardAccentColor$ | push"
        [style.color]="viewModel.cardColor$ | push">
        {{ strainType | uppercase }}
      </div>

      <div
        *rxIf="
          (viewModel.atLeastOneGridVariantOnSale$ | push) &&
          (viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | push) : true : false | push) as size
        "
        class="product-card-size"
        [style.color]="viewModel.bodyTextColor$ | push">
        {{ size }}
      </div>
    </div>
  </div>

  <div class="bottom-container">
    <div class="cannabinoids-and-terpenes-container">
      <div #propertyColumn1 class="property-column">
        <ng-container *rxFor="let property of viewModel.firstColumnProperties$ | push">
          <div class="property-container">
            <div class="title" [style.color]="viewModel.bodyTextColor$ | push">{{ property?.title }}</div>
            <div
              #me1
              class="value"
              appPrintCardScaleDownText
              [maxWidthInPx]="me1.offsetWidth - 1"
              [maxHeightInPx]="16"
              [adjustLineHeight]="false"
              [startScalingAfterNMillisecondsAfterViewInit]="500"
              [transparentWhileScaling]="true"
              [style.color]="viewModel.bodyTextColor$ | push">
              {{ property?.value }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="property-column">
        <ng-container *rxFor="let property of viewModel.secondColumnProperties$ | push">
          <div class="property-container">
            <div class="title" [style.color]="viewModel.bodyTextColor$ | push">{{ property?.title }}</div>
            <div
              #me2
              class="value"
              appPrintCardScaleDownText
              [maxWidthInPx]="me2.offsetWidth - 1"
              [maxHeightInPx]="16"
              [adjustLineHeight]="false"
              [startScalingAfterNMillisecondsAfterViewInit]="500"
              [transparentWhileScalingDebounceTimeInMilliseconds]="501"
              [transparentWhileScaling]="true"
              [style.color]="viewModel.bodyTextColor$ | push">
              {{ property?.value }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div #rightSide class="badge-size-and-price-container">
      <div *rxIf="viewModel.hasBadges$ | push" class="badge-container">
        <img
          *rxFor="let variantBadge of data | printCardBadges"
          appImage
          class="product-card-badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>

      <div class="size-and-price-container">
        <div
          *rxIf="
            !(viewModel.atLeastOneGridVariantOnSale$ | push) &&
            (viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | push) : true : false | push) as size
          "
          class="product-card-size"
          [style.color]="viewModel.bodyTextColor$ | push">
          {{ size }}
        </div>

        <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | push">
          <app-column-primary-price
            [menu]="viewModel.cardStackMenu$ | push"
            [section]="viewModel.cardStack$ | push"
            [colVm]="viewModel.spoofedColViewModel$ | push"
            [rowVm]="viewModel.cardData$ | push"
            [locConfig]="viewModel.locationConfig$ | push"
            [locationId]="viewModel.locationId$ | push"
            [productStylingVm]="viewModel">
          </app-column-primary-price>
        </div>
      </div>
    </div>
  </div>
</div>
