import { Pipe, PipeTransform } from '@angular/core';
import { AssetUtils } from '../../../../../../../../../utils/asset-utils';
import type { VariantsGroupedByBrand } from '../../../../../../../models/variants-grouped-by-brand';

@Pipe({
  name: 'dougBrandFallback'
})
export class DougBrandFallbackPipe implements PipeTransform {

  transform(variantsGroupedByBrand: VariantsGroupedByBrand): string {
    const productType = variantsGroupedByBrand?.productType();
    const variantType = variantsGroupedByBrand?.variantType();
    return AssetUtils.budSenseProductTypeUrls(productType, variantType);
  }

}
