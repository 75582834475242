import { Component, Input } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../product-menu-header.component';
import type { ProductMenu } from '../../../../../../../../../models/menu/product-menu';

@Component({
  selector: 'app-menu-header-title-image',
  templateUrl: './menu-header-title-image.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderTitleImageComponent }]
})
export class MenuHeaderTitleImageComponent extends ProductMenuHeaderComponent {

  @Input() menu: ProductMenu;

}
