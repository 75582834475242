import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RefreshAssetService } from '../../../services/refresh-asset.service';
import { Asset } from '../../../models/image/dto/asset';
import { CachePolicy } from '../../../models/enum/shared/cachable-image-policy.enum';
import { MediaUtils } from '../../../utils/media-utils';
import { AssetSize } from '../../../models/enum/dto/asset-size.enum';
import { BehaviorSubject, merge, ReplaySubject } from 'rxjs';
import { exists } from '../../../functions/exists';
import { RenderContextDirective } from '../../../models/base/render-context-directive';

@Directive()
export abstract class AssetDirective extends RenderContextDirective implements AfterViewInit, OnChanges {

  constructor(
    public el: ElementRef,
    public sanitizer: DomSanitizer,
    private refreshAssetService: RefreshAssetService,
  ) {
    super();
  }

  @Input() localAsset: string;
  @Input() asset: Asset;
  @Input() borderRadius: string = '';
  @Input() cachePolicy: CachePolicy = CachePolicy.Service;
  @Input() cacheForNSeconds: number = MediaUtils.DefaultCacheTimeInSeconds;
  @Input() size: AssetSize = MediaUtils.DefaultImageSize;
  @Input() reset: boolean = false;
  protected resetSubject = new BehaviorSubject<boolean>(false);
  protected resetSignal$ = merge(
    this.resetSubject.pipe(distinctUntilChanged()),
    this.refreshAssetService.globalRefreshSignal$
  );

  // Asset URL
  protected subject: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  // Reset
  protected resetMechanism = this.resetSignal$.pipe(
    debounceTime(1),
    takeUntil(this.onDestroy)
  ).subscribe(reset => {
    if (reset) {
      this.fetchAsset();
    }
  });

  protected abstract setupAssetUrlBinding(): void;

  ngOnChanges(changes: SimpleChanges): void {
    const changed = (changes.asset?.previousValue !== undefined && changes.asset?.previousValue !== null);
    const oldHash = changes?.asset?.previousValue?.md5Hash;
    const newHash = changes?.asset?.currentValue?.md5Hash;
    const md5Changed = (changed && oldHash && newHash && (oldHash !== newHash));
    if (md5Changed || !changed) {
      this.setupAssetUrlBinding();
    }
  }

  setupViews() {
    this.setupLocalAsset();
    this.setupAssetBinding();
  }

  private setupLocalAsset() {
    if (exists(this.localAsset)) {
      this.subject.next(this.sanitizer.bypassSecurityTrustResourceUrl(this.localAsset));
    }
  }

  private setupAssetBinding() {
    this.resetRenderedLogic();
    this.fetchAsset();
    const assetKey = 'assetKey';
    const assetLoadingKey = 'loadingKey';
    this.destroyImageSub(assetKey);
    this.destroyImageSub(assetLoadingKey);
    let blobSub;
    const priorityUrl$ = this.asset?.sizePriorityUrl$;
    if (priorityUrl$) blobSub = this.subject.bind(priorityUrl$);
    if (blobSub) this.pushImageSub(assetKey, blobSub);
  }

  setupBindings() {
    this.setupAssetUrlBinding();
  }

  protected fetchAsset() {
    const asset = this.asset;
    const size = this.size;
    const cacheForNSeconds = this.cacheForNSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (exists(asset) && exists(size) && validCacheTime) {
      const policy = this.cachePolicy ?? CachePolicy.Service;
      asset.getAsset(policy, size, cacheForNSeconds);
    }
  }

  protected resetRenderedLogic() {
    if (!this.localAsset && !this.asset?.isValid()) this.noAssetToRender();
  }

  noAssetToRender() {
    // if no asset, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  renderFailed() {
    // if failed, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  renderSucceeded() {
    this.rendered.next(true);
  }

  public ngAfterViewInit() {
    // Don't call super.ngAfterViewInit(), because we don't want to call rendered.next(true)
    this.setupBindings();
  }

}
