import { exists } from '../functions/exists';
import type { Section } from '../models/menu/section/section';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import type { ProductSection } from '../models/menu/section/product-section';
import type { AssetSection } from '../models/menu/section/asset-section';
import type { TitleSection } from '../models/menu/section/title-section';
import type { EmptySection } from '../models/menu/section/empty-section';
import { SectionColumnConfigFontStyle } from '../models/enum/shared/section-column-config-font-style';

export class SectionUtils {

  public static getColumnTextDecoration(fontStyle: SectionColumnConfigFontStyle): string {
    switch (fontStyle) {
      case SectionColumnConfigFontStyle.Strikethrough:
        return 'line-through';
      case SectionColumnConfigFontStyle.Underline:
        return 'underline';
      default:
        return null;
    }
  }

  /**
   * Used to remove circular imports
   */
  public static isSectionWithProducts = (s: Section): s is SectionWithProducts => {
    return exists((s as any)?.mergeColumnConfigWithThemeColumnConfig);
  };

  /**
   * Used to remove circular imports
   */
  public static isProductSection = (s: Section): s is ProductSection => {
    return exists((s as any)?.getScopedVisibleLineItemDisplayDurationCalculationData);
  };

  /**
   * Used to remove circular imports
   */
  public static isAssetSection = (s: Section): s is AssetSection => {
    return exists((s as any)?.hasPrimaryAsset);
  };

  /**
   * Used to remove circular imports
   */
  public static isTitleSection = (s: Section): s is TitleSection => {
    return exists((s as any)?.isTitleSection);
  };

  /**
   * Used to remove circular imports
   */
  public static isEmptySection = (s: Section): s is EmptySection => {
    return exists((s as any)?.isEmptySection);
  };

}
