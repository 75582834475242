import { TypeCheckUtils } from './type-check-utils';
import { exists } from '../functions/exists';

export {};
declare global {
  interface Array<T> {
    contains(v: T): boolean;
    orEmpty(): Array<T>;
    unique(ignoreEmpty?: boolean): Array<T>;
    uniqueByProperty(property: string, ignoreNull?: boolean): Array<T>;
    uniqueInstance(ignoreEmpty?: boolean): T;
    filterNulls(): Array<T>;
    filterFalsies(): Array<T>;
    equals(arr: T[]): boolean;
    firstOrNull(): T;
    last(): T;
    toStringArray(): Array<string>;
    intersection(arr: T[]): T[];
    difference(arr: T[]): T[];
    flatMap<V>(x: (y: T) => V, depth?: number): V;
    flatten<V>(depth?: number): V;
    findLastIndex(searchKey, searchValue): number;
    count(x: T): number;
    mode(): T;
    take(n: number): T[];
    removeNFromFront(n: number): T[];
    insertAt(item: T, index: number): T[];
    rotateInPlaceClockwise(): T[];
    rotateInPlaceCounterClockwise(): T[];
    chunkedList(chunkSize: number): T[][];
    deepCopy(): Array<T>;
    shallowCopy(): T[];
    splitAt(index: number): [T[], T[]];
  }
}

Array.prototype.count = function(x) {
  return this.filter(it => it === x).length;
};

Array.prototype.contains = function(v) {
  for (const item of this) {
    if (item === v) {
      return true;
    }
  }
  return false;
};

Array.prototype.unique = function(ignoreEmpty: boolean = true) {
  const arr = [];
  for (const item of this) {
    if (!arr.contains(item)) {
      if (ignoreEmpty) {
        if (item && item !== '') {
          arr.push(item);
        }
      } else {
        arr.push(item);
      }
    }
  }
  return arr;
};

Array.prototype.uniqueByProperty = function(property: string, ignoreNull: boolean = true) {
  const uniqueProps = [];
  const arr = [];
  for (const item of this) {
    if (item.hasOwnProperty(property)) {
      const uniqueProp = item[property];
      if (!uniqueProps.contains(uniqueProp)) {
        if (ignoreNull) {
          if (exists(item)) {
            uniqueProps.push(uniqueProp);
            arr.push(item);
          }
        } else {
          uniqueProps.push(uniqueProp);
          arr.push(item);
        }
      }
    }
  }
  return arr;
};

Array.prototype.uniqueInstance = function(ignoreEmpty: boolean = false) {
  const uniqueVals = this.unique(ignoreEmpty);
  if (uniqueVals.length === 1) {
    return uniqueVals[0];
  } else {
    return null;
  }
};

Array.prototype.orEmpty = function() {
  return this || [];
};

Array.prototype.filterNulls = function() {
  return this.filter(v => v);
};

Array.prototype.filterFalsies = function() {
  return this.filter(v => exists(v));
};

Array.prototype.equals = function(array) {
  if (!array) {
    return false;
  }
  if (this.length !== array.length) {
    return false;
  }
  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false;
      }
    } else if (this[i] !== array[i]) {
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false });

Array.prototype.firstOrNull = function() {
  if (this.length === 0) {
    return null;
  } else {
    return this[0];
  }
};

Array.prototype.last = function() {
  if (this.length === 0) {
    return null;
  } else {
    return this[this.length - 1];
  }
};

Array.prototype.toStringArray = function() {
  return this.map(v => v.toString());
};

Array.prototype.intersection = function <T>(compare: T[]): T[] {
  if (!compare) {
    return [];
  }
  const res = [];
  const { length: len1 } = this;
  const { length: len2 } = compare;
  const smaller = (len1 < len2 ? this : compare).slice();
  const bigger = (len1 >= len2 ? this : compare).slice();
  for (let i = 0; i < smaller.length; i++) {
    if (bigger.indexOf(smaller[i]) !== -1) {
      res.push(smaller[i]);
      bigger.splice(bigger.indexOf(smaller[i]), 1, undefined);
    }
  }
  return res;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'intersection', { enumerable: false });

Array.prototype.difference = function <T>(compare: T[]): T[] {
  if (!compare) {
    return this;
  }
  return this.filter(x => !compare.includes(x));
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'difference', { enumerable: false });

/**
 * Use to return Array.prototype.concat.apply([], this.map(lambda));
 * - Array.prototype.concat.apply([], ...) spreads elements manually. O(n²) in worst case
 * - Each concat call results in copying array elements into a new array, leading to repeated memory allocations.
 * - flat() is specifically designed for this purpose and handles data more efficiently. O(n) (optimized internally)
 * - flat() iterates through the array once and directly inserts values, avoiding unnecessary memory copying.
 */
Array.prototype.flatMap = function(lambda, depth?: number) {
  return this.map(lambda).flat(depth);
};

Array.prototype.flatten = function<V>(depth?: number): V {
  return this.flatMap(it => it, depth);
};

Array.prototype.findLastIndex = function(searchKey, searchValue): number {
  const index = this.slice().reverse().findIndex(x => x[searchKey] === searchValue);
  const count = this.length - 1;
  return index >= 0 ? count - index : index;
};

Array.prototype.mode = function() {
  const frequency = new Map<string, number>();
  let maxFreq = 0;
  this.forEach((val) => {
    frequency.set(val, (frequency.get(val) || 0) + 1);
    if (frequency.get(val) > maxFreq) {
      maxFreq = frequency.get(val);
    }
  });
  let modeVal = null;
  frequency.forEach((val, key) => {
    if (val === maxFreq) {
      modeVal = key;
    }
  });
  return modeVal;
};

Array.prototype.take = function <T>(n: number): T[] {
  return this.slice(0, n);
};

Array.prototype.removeNFromFront = function(n: number) {
  if (n <= this.length) {
    // loop n times
    [...Array(n).keys()].forEach(_ => {
      this.shift();
    });
    return this;
  } else if (n > this.length) {
    return [];
  } else {
    return this;
  }
};

Array.prototype.insertAt = function(value: any, index: number) {
  this.splice(index, 0, value);
  return this;
};

Array.prototype.rotateInPlaceClockwise = function() {
  const last = this.pop();
  this.unshift(last);
  return this;
};

Array.prototype.rotateInPlaceCounterClockwise = function() {
  const first = this.shift();
  this.push(first);
  return this;
};

/**
 * An extension function that returns a 2D array of the original array paginated into sub-arrays up to length n.
 * If the last page does not have n elements, it will be shorter than n.
 * This extension method does not mutate the original array.
 *
 * @return A 2D paginated array of the original.
 */
Array.prototype.chunkedList = function(chunkSize: number): any[][] {
  const res = [];
  if (this.length < 1) return [];
  for (let i = 0; i < this.length; i += chunkSize) {
    res.push(this.slice(i, i + chunkSize));
  }
  return res;
};

Array.prototype.deepCopy = function() {
  const copy = [];
  this.forEach(val => {
    const isPrimitive = TypeCheckUtils.isPrimitive(val);
    if (isPrimitive) {
      copy.push(JSON.parse(JSON.stringify(val)));
    } else if (val instanceof Array) {
      copy.push(val.deepCopy());
    } else if (typeof (val as any)?.onDeserialize === 'function') {
      copy.push(window?.injector?.Deserialize?.instanceOf(val.constructor, val));
    } else {
      Object.assign(Object.create((val as any).prototype), val);
    }
  });
  return copy;
};

Array.prototype.shallowCopy = function<T>(): T[] {
  return [...this];
};

Array.prototype.splitAt = function(index: number): [any[], any[]] {
  return [this.slice(0, index), this.slice(index)];
};
