import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardGridViewModel } from './card-grid-view-model';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import type { CardData } from '../../../../models/print-cards/card-data';
import type { ShelfTalkerCardData } from '../../../../models/shelf-talkers/shelf-talker-card-data';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
  providers: [CardGridViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardGridComponent extends RenderContextComponent {

  constructor(
    public viewModel: CardGridViewModel
  ) {
    super();
  }

  @Input() cardsAsData: (CardData | ShelfTalkerCardData)[];
  setupBindings() {}
  setupViews() {}

}
