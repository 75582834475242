import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'popsMaxCompoundWidth'
})
export class PopsMaxCompoundWidthPipe implements PipeTransform {

  transform(maxCalculatedWidth: number, header: HTMLDivElement): number {
    const headerWidth = header?.getBoundingClientRect()?.width;
    if (Number.isFinite(maxCalculatedWidth)) {
      return Math.max(maxCalculatedWidth, Math.ceil(headerWidth));
    }
    return maxCalculatedWidth;
  }

}
