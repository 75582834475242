<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator"
  [calculateVirtualLabel]="true"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>
<div class="main-content">
  <div class="label-container">
    <app-label-inflator
      *ngIf="viewModel.hasLabel$ | async"
      class="print-card-label"
      [columnLabel]="false"
      [labelComponentInterface]="cardData"
      [reset]="false">
    </app-label-inflator>
  </div>
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getBrand' as brand"
    class="product-card-brand"
    [style.color]="viewModel.bodyTextColor$ | async">
    {{ brand }}
  </div>
  <div class="name-badges-desc">
    <div #productCardName class="product-card-name" [style.color]="viewModel.headerTextColor$ | async">
      {{ cardData | sectionRowViewModelBasicMethod: 'getRowTitle' | trimSafely }}
    </div>
    <app-print-card-description-container
      [cardData]="cardData"
      [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
  </div>
  <div class="type-and-cannabinoids-container">
    <div class="type-and-cannabinoids" [style.color]="viewModel.bodyTextColor$ | async">
      <div class="type-and-strain">
        <div *ngIf="viewModel.isCannabinoidVariant$ | async" class="product-card-type">
          {{ cardData | sectionRowViewModelBasicMethod: 'fikaVariantType' | trimSafely }}
        </div>
        <div
          *ngIf="
            (viewModel.isCannabinoidVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod: 'fikaVariantType' | trimSafely) &&
            (cardData | sectionRowViewModelBasicMethod: 'getReadableStrainType' | trimSafely)
          "
          class="type-and-strain-separator">
          |
        </div>
        <div
          *ngIf="
            (viewModel.isCannabinoidVariant$ | async) &&
            (cardData | sectionRowViewModelBasicMethod: 'getReadableStrainType' | trimSafely) as strainType
          "
          class="strain-type">
          {{ strainType }}
        </div>
      </div>
      <hr
        *ngIf="
          (viewModel.isCannabinoidVariant$ | async) &&
          ((cardData | sectionRowViewModelBasicMethod: 'fikaVariantType' | trimSafely) ||
            (cardData | sectionRowViewModelBasicMethod: 'getReadableStrainType') | trimSafely)
        "
        class="type-and-properties-separator"
        [style.background-color]="(viewModel.cardAccentColor$ | async) || (viewModel.bodyTextColor$ | async)" />
      <div *ngIf="viewModel.isCannabinoidVariant$ | async" class="properties">
        <ng-container *rxLet="viewModel.propertyPoolsByRow$; let pools">
          <div *ngIf="pools?.firstRow?.length" class="property-row">
            <ng-container *ngFor="let property of pools.firstRow; let last = last">
              <div class="property">
                <div class="property-title">{{ property?.title }}</div>
                <div class="property-value">{{ property?.value | trimSafely }}</div>
              </div>
              <div *ngIf="!last" class="property-separator">|</div>
            </ng-container>
          </div>
          <div *ngIf="pools?.secondRow?.length" class="property-row">
            <ng-container *ngFor="let property of pools.secondRow; let last = last">
              <div class="property">
                <div class="property-title">{{ property?.title }}</div>
                <div class="property-value">{{ property?.value | trimSafely }}</div>
              </div>
              <div *ngIf="!last" class="property-separator">|</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="fika-size-and-price">
    <app-fika-size-and-price
      [includeSize]="viewModel.includeSizeInPrice$ | async"
      [rowVm]="viewModel.cardData$ | async"
      [colVms]="viewModel.spoofedGridColViewModels$ | async"
      [locationConfig]="viewModel.locationConfig$ | async"
      [saleText]="viewModel.virtualLabelText$ | async">
    </app-fika-size-and-price>
  </div>
</div>
