import { LabelComponentInterface } from '../../labels/components/label-component-interface';
import { exists } from '../../../functions/exists';
import { VariantTypeUtils } from '../../../utils/variant-type-utils';
import { PriceUtils } from '../../../utils/price-utils';
import { SortUtils } from '../../../utils/sort-utils';
import { BadgeUtils } from '../../../utils/badge-utils';
import { ProductType } from '../../../models/enum/dto/product-type.enum';
import { VariantType } from '../../../models/enum/dto/variant-type.enum';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';
import type { Variant } from '../../../models/product/dto/variant';
import type { ShelfTalkerCardData } from '../../../models/shelf-talkers/shelf-talker-card-data';
import type { ShelfTalkerMenu } from '../../../models/menu/shelf-talkers/shelf-talker-menu';
import type { ShelfTalkerCard } from '../../../models/menu/section/shelf-talker-card/shelf-talker-card';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import type { Menu } from '../../../models/menu/menu';
import type { VariantBadge } from '../../../models/product/dto/variant-badge';

export class VariantsGroupedByBrand implements LabelComponentInterface {

  constructor(
    public menu: ShelfTalkerMenu,
    public section: ShelfTalkerCard,
    public variants: Variant[],
    public locationConfig: LocationConfiguration,
    public companyConfig: CompanyConfiguration,
  ) {
  }

  productType(): ProductType {
    return this.variants?.map(v => v?.productType)?.filterNulls()?.mode();
  }

  variantType(): VariantType {
    return this.variants?.map(v => v?.variantType)?.filterNulls()?.mode();
  }

  getBrandName(): string {
    return this.variants?.map(v => v?.brand)?.filterNulls()?.mode();
  }

  /**
   * The number of strains is equal to the number of variants within the brand grouping
   */
  getNumberOfStrains(): number {
    return this.variants?.length;
  }

  getAdditionalInfo(): string | null {
    const productType = this.productType();
    switch (productType) {
      case ProductType.Beverage:
      case ProductType.Edible:
        return this.howManyFlavorsText();
      case ProductType.Flower:
        return this.howManyStrainsText();
      default:
        return this.howManyProductsText();
    }
  }

  howManyFlavorsText(): string | null {
    const nFlavors = this.variants?.length;
    if (nFlavors > 1) {
      return `${nFlavors} flavors`;
    }
    return null;
  }

  howManyStrainsText(): string | null {
    const nStrains = this.getNumberOfStrains();
    if (nStrains > 1) {
      return `${nStrains} strains`;
    }
    return null;
  }

  howManyProductsText(): string | null {
    const nProducts = this.variants?.length;
    if (nProducts > 1) {
      return `${nProducts} products`;
    }
    return null;
  }

  getFormattedUnitSizeFor(variant: Variant): string | null {
    const size = variant?.getFormattedUnitSize();
    switch (true) {
      case !size:
      case size === '-':
        return null;
      default:
        return size;
    }
  }

  public getSizing(): string {
    const productType = this.productType();
    const variantType = this.variantType();
    switch (true) {
      case ProductType.Seed === productType || VariantType.Seed === variantType:
        return this.getSizeHelperForSeeds();
      case VariantTypeUtils.isPreRollType(variantType):
        return this.getSizeHelperForPreRolls();
      case VariantTypeUtils.isCapsuleType(variantType):
        return this.getSizeHelperForCapsules();
      case (productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(variantType)):
        return this.getSizeHelperForReadyToDrinkBeverages();
      case (productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(variantType)):
        return this.getSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages();
      default:
        return this.getSizeHelperForEverythingElse();
    }
  }

  protected getSizeHelperForSeeds(): string | null {
    const packagedQuantities = this.variants?.map(v => v?.packagedQuantity)?.filterNulls()?.unique();
    switch (true) {
      case packagedQuantities?.length > 1:
        return `${packagedQuantities?.length} sizes`;
      case packagedQuantities?.length === 1:
        return `${packagedQuantities?.firstOrNull()} ${this.seedPackageQuantityWord()}`;
      default:
        return null;
    }
  }

  protected getSizeHelperForPreRolls(): string | null {
    const getSizeInfo = (variant: Variant) => {
      const quantity = variant?.packagedQuantity;
      const formattedUnitSize = this.getFormattedUnitSizeFor(variant);
      const hasQty = Number.isFinite(quantity);
      const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
      switch (true) {
        case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
        case hasQty && !hasSize: return `${quantity}`;
        default:                 return null;
      }
    };
    const sizes = this.variants?.map(variant => getSizeInfo(variant))?.filterNulls()?.unique();
    switch (true) {
      case sizes?.length > 1:
        return `${sizes?.length} sizes`;
      case sizes?.length === 1:
        return sizes?.firstOrNull();
      default:
        return null;
    }
  }

  protected getSizeHelperForCapsules(): string | null {
    const quantities = this.variants?.map(v => v?.packagedQuantity)?.filterNulls()?.unique();
    switch (true) {
      case quantities?.length > 1:
        return `${quantities?.length} sizes`;
      case quantities?.length === 1:
        return `${quantities?.firstOrNull()} cap`
          .pluralizer()
          .addRule({ isPlural: quantities?.firstOrNull() > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      default:
        return null;
    }
  }

  protected getSizeHelperForReadyToDrinkBeverages(): string | null {
    const getSizeInfo = (variant: Variant) => {
      const quantity = variant?.packagedQuantity;
      const formattedUnitSize = this.getFormattedUnitSizeFor(variant);
      switch (true) {
        case !quantity:
        case quantity === 1:
          return formattedUnitSize;
        default:
          return `${quantity} x ${formattedUnitSize}`;
      }
    };
    const sizes = this.variants?.map(variant => getSizeInfo(variant))?.filterNulls()?.unique();
    switch (true) {
      case sizes?.length > 1:
        return `${sizes?.length} sizes`;
      case sizes?.length === 1:
        return sizes?.firstOrNull();
      default:
        return null;
    }
  }

  protected getSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages(): string | null {
    const getSizeInfo = (variant: Variant) => {
      const quantity = variant?.packagedQuantity;
      switch (true) {
        case !quantity:
          return this.getFormattedUnitSizeFor(variant);
        default:
          return `${variant?.packagedQuantity} ${this.edibleNonReadyToDrinkPackageQuantityWord()}`;
      }
    };
    const sizes = this.variants?.map(variant => getSizeInfo(variant))?.filterNulls()?.unique();
    switch (true) {
      case sizes?.length > 1:
        return `${sizes?.length} sizes`;
      case sizes?.length === 1:
        return sizes?.firstOrNull();
      default:
        return null;
    }
  }

  protected getSizeHelperForEverythingElse(): string | null {
    const sizes = this.variants?.map(v => v?.getFormattedUnitSize())?.unique();
    switch (true) {
      case sizes?.length > 1:
        return `${sizes?.length} sizes`;
      case sizes?.length === 1:
        return sizes?.firstOrNull();
      default:
        return null;
    }
  }

  protected seedPackageQuantityWord(): string {
    return 'pk';
  }

  protected edibleNonReadyToDrinkPackageQuantityWord(): string {
    return 'pc';
  }

  protected quantityWordForEverythingElse(): string {
    return 'pc';
  }

  getPriceRange(shelfTalkerCardData: ShelfTalkerCardData): string {
    const themeId = shelfTalkerCardData?.menu?.theme;
    const locationId = shelfTalkerCardData?.locationConfig?.locationId;
    const companyId = shelfTalkerCardData?.companyConfig?.companyId;
    const priceStream = shelfTalkerCardData?.locationConfig?.priceFormat;
    const hideSale = shelfTalkerCardData?.menu?.menuOptions?.hideSale;
    const hidePriceDecimal = shelfTalkerCardData?.menu?.menuOptions?.hidePriceDecimal;
    const prices = this.variants
      ?.map(v => v?.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale))
      ?.unique(true)
      ?.sort(SortUtils.numberAscending);
    switch (true) {
      case prices?.length >= 2:
        const minPrice = PriceUtils.formatPriceAndRemoveTrailingZeros(prices?.firstOrNull(), hidePriceDecimal);
        const maxPrice = PriceUtils.formatPriceAndRemoveTrailingZeros(prices?.last(), hidePriceDecimal);
        return `${minPrice} - ${maxPrice}`;
      case prices?.length === 1:
        return `${PriceUtils.formatPriceAndRemoveTrailingZeros(prices?.firstOrNull(), hidePriceDecimal)}`;
      default:
        return '-';
    }
  }

  getMinPrice(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): number | null {
    const prices = this.variants?.map(v => v?.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale));
    return (prices?.length > 0) ? Math.min(...prices) : null;
  }

  getMaxPrice(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    const prices = this.variants?.map(v => v?.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale));
    return (prices?.length > 0) ? Math.max(...prices) : null;
  }

  getBadges(): VariantBadge[] {
    return BadgeUtils.getVariantVisibleBadges(this.menu, this.section, this.variants);
  }

  /* ************************** Label Component Interface ************************** */

  getMenuForLabelComponent(): Menu {
    return this.menu;
  }

  getSectionForLabelComponent(): SectionWithProducts {
    return this.section;
  }

  getVariantsForLabelComponent(): Variant[] {
    return this.variants;
  }

  getLocationConfigForLabelComponent(): LocationConfiguration {
    return this.locationConfig;
  }

  getCompanyConfigForLabelComponent(): CompanyConfiguration {
    return this.companyConfig;
  }

  getShutOffLabelForLabelComponent(): boolean {
    return !this.menu?.getShowInlineLabels();
  }

  getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return null;
  }

}
