import { Pipe, PipeTransform } from '@angular/core';
import type { CardData } from '../../../models/print-cards/card-data';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'leftCutLineText'
})
export class LeftCutLineTextPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, cardsOnPage: CardData[], i: number): string | null {
    return cardsThatLayoutOntoPaper?.getLeftCutLineText(cardsOnPage?.[i]);
  }

}
