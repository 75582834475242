import { BaseViewModel } from '../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { SystemLabelKey } from '../../../models/enum/dto/system-label-key.enum';
import { DisplayAttribute } from '../../../models/display/dto/display-attribute';
import { Label } from '../../../models/menu/labels/label';
import { ColorUtils } from '../../../utils/color-utils';
import { SaleLabelFormat } from '../../../models/enum/shared/sale-label-format.enum';
import { Variant } from '../../../models/product/dto/variant';
import { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';
import { Menu } from '../../../models/menu/menu';
import { SortUtils } from '../../../utils/sort-utils';
import { SaleSystemLabel } from '../../../models/menu/labels/sale-system-label';
import { LabelComponentInterface } from './label-component-interface';
import { Injectable } from '@angular/core';
import { MarketingFeaturedProductMenu } from '../../../models/menu/marketing/FeaturedProduct/marketing-featured-product-menu';
import { MarketingComboMenu } from '../../../models/menu/marketing/marketing-combo-menu';
import { DeprecatedMarketingMenu } from '../../../models/menu/deprecated-marketing-menu';
import { LabelDomainModel } from '../../../domain/label-domain-model';
import { exists } from '../../../functions/exists';
import { Section } from '../../../models/menu/section/section';
import { CachedLabelsService } from '../../services/cached-labels.service';
import { DistinctUtils } from '../../../utils/distinct.utils';

type SystemLabelCalculationData = [Label, Menu, Section, number, number, LocationPriceStream, any]

/**
 * There are TWO pools of LABEL objects: Location and Company.
 * This allows us to:
 * 1) Allow locations to have their labels update and managed by the company.
 * 2) Allow locations to unlink from the company and manage their labels independently.
 *
 * When a location is linked to a company, then the label object at the company
 * layer is used as a "Template" to send updates to all location level labels that
 * are a copy of the company level label "Template". This means if an update happens
 * at the company level, then the company level label will find all location level
 * labels that have the same ID as itself, and update them to match itself.
 *
 * If a user disconnects a label from being managed by the company, then the label
 * will not receive updates from the label in the company label pool. This location
 * label will be a "standalone" label that is managed by the location, and will not
 * receive updates from changes at the company level.
 *
 * - user applied = custom (labelOverride) or featured (variantFeature)
 * - system applied = sale, low stock, restock, new
 * - can have multiple labels in each pool when dealing with multiple variants
 * - highest priority pool for user defined labels is used for label calculation
 * - DA stands for display attribute data model.
 * - Display attributes can exist at a company and location level.
 --------------------------------------------------------------------------------
 |                  User Applied Labels                    |   System Applied   |
 -------------------------------------------------------------------------------|
 |  Highest Priority                                       |                    |
 |        ↑             Override Labels (Green Label) (purp|    System Label    |
 |        |                 ** Sorted by Hierarchy **      |                    |
 |        |            ------------------------------------|    (Sale Label) (re|
 |        |                                                |                    |
 |  Middle Priority  Location DA Labels (Yellow Label) (blu|   **  Sorted   **  |
 |        |                 ** Sorted by Hierarchy **      |   **    by     **  |
 |        |            ------------------------------------|   ** Hierarchy **  |
 |        |                                                |                    |
 |  Lowest Priority  Company DA Labels (Violet Label) (oran|                    |
 |        |                 ** Sorted by Hierarchy **      |                    |
 |____________________________ ↓ __________________________|________ ↓ _________|
 |                  User Applied Label Output              |   System Output    |
 |                             ↓                           |         ↓          |
 |                        Green Label                      |         |          |
 | - does the user applied label exist as a Location Label |         |          |
 |   data object?                                          |         |          |
 |   Yes. Then send Green Label into the next step         |         |          |
 |   No. Then the location has explicitly removed this     |         |          |
 |       label from their location and don't want to see   |     Sale Label     |
 |       it, so stop the green label from making it to the |         |          |
 |       next step                                         |         |          |
 |                             ↓                           |         |          |
 |    greenInLocationLabelPool ? Green Label : null        |         |          |
 | --------------------------- ↓ ----------------------------------- ↓ ---------|
 |  1) User applied: Green Label, System applied: Sale Label                    |
 |  2) Apply label hierarchy to green label and sale label                      |
 |  3) The label that is displayed is the one with the higher                   |
 |     priority in the label hierarchy                                          |
 --------------------------------------------------------------------------------
 */

@Injectable()
export class LabelViewModel extends BaseViewModel {

  constructor(
    protected labelDomainModel: LabelDomainModel,
    protected cachedLabelsService: CachedLabelsService
  ) {
    super();
  }

  private _labelComponentInterface = new BehaviorSubject<LabelComponentInterface>(null);
  public readonly labelComponentInterface$ = this._labelComponentInterface as Observable<LabelComponentInterface>;
  connectToLabelComponentInterface = (s: LabelComponentInterface) => this._labelComponentInterface.next(s);

  private readonly _checkForPriceChange = new BehaviorSubject<boolean>(true);
  public readonly checkForPriceChange$ = this._checkForPriceChange as Observable<boolean>;
  connectToCheckForPriceChange = (check: boolean) => this._checkForPriceChange.next(check);

  /* ************************************ Private internal computation ************************************ */

  private readonly themeId$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getMenuForLabelComponent?.()?.theme),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationConfig$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getLocationConfigForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationId$ = this.locationConfig$.pipe(
    map(locationConfig => locationConfig?.locationId),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly overridePriceStream$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getOverridePriceStreamForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly overrideBaseSaleFormatText$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getOverrideBaseSaleFormatText?.() || null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly overrideDollarOffSaleFormatText$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getOverrideDollarOffSaleFormatText?.() || null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly overridePercentOffSaleFormatText$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getOverridePercentOffSaleFormatText?.() || null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly saleTextOverrides$ = combineLatest([
    this.overrideBaseSaleFormatText$,
    this.overrideDollarOffSaleFormatText$,
    this.overridePercentOffSaleFormatText$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly priceStream$ = combineLatest([
    this.overridePriceStream$,
    this.locationConfig$
  ]).pipe(
    map(([overridePriceStream, locationConfig]) => overridePriceStream || locationConfig?.priceFormat),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly companyConfig$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getCompanyConfigForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly companyId$ = this.companyConfig$.pipe(
    map(companyConfig => companyConfig?.companyId),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly menu$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getMenuForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly section$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getSectionForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly variants$ = this.labelComponentInterface$.pipe(
    map(componentInterface => componentInterface?.getVariantsForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly onSale$ = combineLatest([
    this.menu$,
    this.variants$,
    this.priceStream$,
    this.checkForPriceChange$
  ]).pipe(
    map(([menu, variants, priceStream]) => {
      return variants?.some(v => v?.onSale(menu?.theme, menu?.locationId, menu?.menuOptions?.hideSale, priceStream));
    }),
    startWith(false),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationLabels$ = this.labelDomainModel.locationLabels$;
  private readonly posLabels$ = this.labelDomainModel.posLabels$;
  private readonly lowStockSystemLabel$ = this.labelDomainModel.lowStockSystemLabel$;
  private readonly restockSystemLabel$ = this.labelDomainModel.restockSystemLabel$;
  private readonly saleSystemLabel$ = this.labelDomainModel.saleSystemLabel$;
  private readonly newSystemLabel$ = this.labelDomainModel.newSystemLabel$;

  private readonly overrideLabelIds$ = combineLatest([
    this.variants$,
    this.section$,
    this.menu$
  ]).pipe(
    map(([variants, section, menu]) => {
      const overrideLabelKeys = variants?.map(variant => section?.customLabelMap?.get(variant.id))?.filterNulls();
      // The exclusion logic can be removed when the excluded menu types are updated
      // to the "Section" data model structure on the backend
      const isFeaturedProductMenu = menu instanceof MarketingFeaturedProductMenu;
      const isDriveThruMenu = menu instanceof MarketingComboMenu;
      const excludeTheseMarketingMenus = isFeaturedProductMenu || isDriveThruMenu;
      const isFeatured = variants?.some(v => {
        const featured = (menu as DeprecatedMarketingMenu)?.hydratedVariantFeature?.variantIds?.contains(v?.id);
        return !excludeTheseMarketingMenus && featured;
      });
      if (isFeatured) overrideLabelKeys.push(SystemLabelKey.Featured);
      return overrideLabelKeys;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationLabelIds$ = this.variants$.pipe(
    map(variants => {
      return variants
        ?.map(variant => variant?.getDisplayAttributes()?.filter(attr => attr?.isLocationDA()) ?? [])
        ?.flatten<DisplayAttribute[]>()
        ?.map(locationDisplayAttributes => locationDisplayAttributes?.defaultLabel)
        ?.filterNulls()
        ?.unique();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly companyLabelIds$ = this.variants$.pipe(
    map(variants => {
      return variants
        ?.map(variant => variant?.getDisplayAttributes()?.filter(attr => attr?.isCompanyDA()) ?? [])
        ?.flatten<DisplayAttribute[]>()
        ?.map(companyDisplayAttribute => companyDisplayAttribute?.defaultLabel)
        ?.filterNulls()
        ?.unique();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly priorityBasedLabelIdPool$ = combineLatest([
    this.overrideLabelIds$,
    this.locationLabelIds$,
    this.companyLabelIds$
  ]).pipe(
    map(([overrideLabelIds, locationLabelIds, companyLabelIds]) => {
      let labelIds: string[] = [];
      if (overrideLabelIds?.length > 0) {
        labelIds = overrideLabelIds;
      } else if (locationLabelIds?.length > 0) {
        labelIds = locationLabelIds;
      } else if (companyLabelIds?.length > 0) {
        // POS labels will only ever be included in company DA labels
        labelIds = companyLabelIds;
      }
      return labelIds;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Company label objects are a lot like templates for location level label objects.
   * If a location level object doesn't exist with the same Id as a company level object,
   * that means the location has explicitly removed that label from their location and don't want to see it.
   * Therefore, exclude company label objects from object pool.
   */
  private readonly sortedPriorityBasedLabelPool$ = combineLatest([
    this.priorityBasedLabelIdPool$,
    this.locationLabels$.notNull(),
    this.posLabels$,
  ]).pipe(
    map(([labelIdPool, locationLabelObjects, posLabels]) => {
      const labelToCheck = [...(locationLabelObjects || []), ...(posLabels || [])];
      return labelToCheck.filter(l => labelIdPool?.contains(l?.id));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly labelToBeComparedWithSystemLabel$ = this.sortedPriorityBasedLabelPool$.pipe(
    map(labels => labels?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private systemLabelCalculationData = <K>(
    systemLabel$: Observable<Label>,
    additionalInput$?: Observable<K>
  ): Observable<SystemLabelCalculationData> => {
    return combineLatest([
      systemLabel$,
      this.menu$,
      this.section$,
      this.locationId$,
      this.companyId$,
      this.priceStream$,
      additionalInput$ ? additionalInput$ : of(null)
    ]).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private variantLowStockSystemLabelOrNull(variant: Variant): Observable<Label | null> {
    return this.systemLabelCalculationData(this.lowStockSystemLabel$.notNull()).pipe(
      map(([lowStockLabel, menu, section]) => {
        if (menu?.menuOptions?.hideInventoryLabels) return null;
        if (section?.metadata?.hideLowStockLabels === 'true') return null;
        return variant?.isLowStockLabelActive(lowStockLabel) ? lowStockLabel : null;
      })
    );
  }

  private variantRestockSystemLabelOrNull(variant: Variant): Observable<Label | null> {
    return this.systemLabelCalculationData(this.restockSystemLabel$.notNull()).pipe(
      map(([restockLabel, menu, section]) => {
        if (menu?.menuOptions?.hideInventoryLabels) return null;
        if (section?.metadata?.hideRestockedLabels === 'true') return null;
        return variant?.isRestockLabelActive(restockLabel) ? restockLabel : null;
      })
    );
  }

  private variantSaleLabelOrNull(variant: Variant): Observable<Label | null> {
    return this.systemLabelCalculationData(this.saleSystemLabel$.notNull(), this.checkForPriceChange$).pipe(
      map(([saleLabel, menu, _, locationId, companyId, priceStream]) => {
        if (menu?.menuOptions?.hideSale) return null;
        return variant?.isSaleLabelActive(menu?.theme, locationId, companyId, priceStream) ? saleLabel : null;
      })
    );
  }

  private variantNewLabelOrNull(variant: Variant): Observable<Label | null> {
    return this.systemLabelCalculationData(this.newSystemLabel$.notNull()).pipe(
      map(([newLabel, _, section]) => {
        if (section?.metadata?.hideNewLabels === 'true') return null;
        return variant?.isNewLabelActive(newLabel) ? newLabel : null;
      })
    );
  }

  private readonly systemLabelPool$ = this.variants$.pipe(
    switchMap(variants => {
      const parallelVariantLabelPipelines$ = variants?.map(variant => {
        return combineLatest([
          this.variantLowStockSystemLabelOrNull(variant),
          this.variantRestockSystemLabelOrNull(variant),
          this.variantSaleLabelOrNull(variant),
          this.variantNewLabelOrNull(variant),
        ]).pipe(
          map(systemLabels => systemLabels?.filterNulls())
        );
      });
      // Unique list of system labels provided from all variants within this line item.
      const getUniqueListOfLabelsForVariants$ = combineLatest(parallelVariantLabelPipelines$).pipe(
        map((parallelVariantSystemLabels: Label[][]) => parallelVariantSystemLabels.flatten<Label[]>()),
        map((systemLabels: Label[]) => systemLabels?.uniqueByProperty('id'))
      );
      const hasParallelPipes = parallelVariantLabelPipelines$?.length > 0;
      return hasParallelPipes ? getUniqueListOfLabelsForVariants$ : of([]);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly sortedSystemLabelPool$ = this.systemLabelPool$.pipe(
    map((systemLabelPool: Label[]) => systemLabelPool?.sort(SortUtils.sortLabelsByPriority)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly systemLabelToBeComparedWithCustomLabel$ = this.sortedSystemLabelPool$.pipe(
    map(systemLabels => systemLabels?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ************************************ Main outputs ************************************ */

  public readonly saleLabelFormat$ = combineLatest([
    this.saleSystemLabel$,
    combineLatest([
      this.section$,
      this.variants$,
      this.priceStream$
    ]),
    combineLatest([
      this.themeId$,
      this.locationConfig$,
      this.companyConfig$,
    ]),
    this.saleTextOverrides$
  ]).pipe(
    map(([
      saleLabel,
      [section, variants, priceStream],
      [themeId, locationConfig, companyConfig],
      [saleOverrideText, dollarOffOverrideText, percentOffOverrideText]
    ]) => {
      const hasMultipleVariantsPerLineItem = section?.hasMultipleVariantsPerLineItem();
      const sectionLevel = section?.saleLabelFormat;
      const locationLevel = locationConfig?.saleLabelFormat;
      const companyLevel = companyConfig?.saleLabelFormat;
      const saleFormat = sectionLevel || locationLevel || companyLevel || SaleLabelFormat.SALE;
      const locId = locationConfig?.locationId;
      const companyId = companyConfig?.companyId;
      let uniqueSharedLabel = false;
      if (hasMultipleVariantsPerLineItem) {
        const saleLabels = variants?.map(v => {
          return saleLabel?.getSaleText(
            [v, priceStream, saleFormat],
            [themeId, locId, companyId],
            saleOverrideText,
            dollarOffOverrideText,
            percentOffOverrideText
          );
        });
        uniqueSharedLabel = exists(saleLabels?.uniqueInstance());
      }
      return (hasMultipleVariantsPerLineItem && !uniqueSharedLabel) ? SaleLabelFormat.SALE : saleFormat;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /*
    We want to cache and track both real labels and null/undefined labels (absence of label)
    By tracking both, we can avoid unnecessary compute cycles that will yield the same result (label or not)
    The following will be performed:
      1. All labels are calculated on first render
      2. All labels are cached (regardless of null/undefined/Label/SaleLabel)
      3. Next time it has to be rendered on screen, it returns the cached value in order to bypass calculateLabel$
      all together, and goes directly to saved label or non-existent label
   */
  private getLabelPipeline$(
    listenToHideLabelSignal: boolean = true,
    ignoreCache: boolean = false
  ): Observable<Label | null> {
    const cachedInputs$ = combineLatest([
      this.menu$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
      this.section$.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
      this.variants$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
      this.priceStream$.notNull().pipe(distinctUntilChanged()),
      this.onSale$.pipe(pairwise(), map(([prev, curr]) => [curr, prev !== curr] as [boolean, boolean]))
    ]).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
    const hasCachedLabel$ = defer(() => {
      return cachedInputs$.pipe(
        switchMap(([menu, section, variants, priceStream, [onSale, saleChanged]]) => {
          const menuId = menu?.id;
          const secId = section?.id;
          const key = CachedLabelsService.getLabelKey(menuId, secId, variants?.map(v => v?.id), priceStream, onSale);
          if (saleChanged) this.cachedLabelsService.clearCacheFor(key);
          return iif(
            () => CachedLabelsService.hasValidKey(menu, section, variants, priceStream),
            this.cachedLabelsService.hasCachedLabel$(key),
            of(false)
          );
        }),
        distinctUntilChanged()
      );
    });
    const cachedLabel$ = defer(() => {
      return cachedInputs$.pipe(
        switchMap(([menu, section, variants, priceStream, [onSale]]) => {
          const menuId = menu?.id;
          const secId = section?.id;
          const key = CachedLabelsService.getLabelKey(menuId, secId, variants?.map(v => v?.id), priceStream, onSale);
          return this.cachedLabelsService.getCachedLabel$(key);
        })
      );
    });
    const calculateLabel$ = defer(() => {
      return combineLatest([
        this.menu$,
        this.labelToBeComparedWithSystemLabel$,
        this.systemLabelToBeComparedWithCustomLabel$,
      ]).pipe(
        map(([menu, customLabelKey, systemLabelKey]) => {
          if (listenToHideLabelSignal && menu?.menuOptions?.hideLabel) return null;
          const topLabels: Label[] = [customLabelKey, systemLabelKey];
          return topLabels?.filterNulls()?.sort(SortUtils.sortLabelsByPriority)?.firstOrNull();
        }),
        tap(label => {
          if (!ignoreCache) {
            cachedInputs$.once(([menu, section, variants, pStream, [onSale]]) => {
              if (CachedLabelsService.hasValidKey(menu, section, variants, pStream)) {
                const menuId = menu?.id;
                const secId = section?.id;
                const key = CachedLabelsService.getLabelKey(menuId, secId, variants?.map(v => v?.id), pStream, onSale);
                this.cachedLabelsService.cacheLabel(key, label);
              }
            });
          }
        })
      );
    });
    return hasCachedLabel$.pipe(
      switchMap(hasCachedLabel => iif(() => (hasCachedLabel && !ignoreCache), cachedLabel$, calculateLabel$)),
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
      shareReplay({ bufferSize: 1, refCount: true }),
      startWith<Label>(undefined)
    );
  }

  public readonly label$ = this.getLabelPipeline$();

  private getOriginalLabelTextPipeline$(label$: Observable<Label | null>): Observable<string> {
    return label$.pipe(
      map(label => label?.text ?? '')
    );
  }

  public readonly originalLabelText$ = this.getOriginalLabelTextPipeline$(this.label$);

  private systemSaleLabelText$ = combineLatest([
    combineLatest([
      this.variants$.pipe(map(variants => variants?.firstOrNull())),
      this.priceStream$,
    ]),
    combineLatest([
      this.saleSystemLabel$,
      this.saleLabelFormat$,
    ]),
    combineLatest([
      this.themeId$,
      this.locationId$,
      this.companyId$,
    ]),
    this.saleTextOverrides$
  ]).pipe(
    map(([
      [variant, priceStream],
      [saleLabel, saleLabelFormat],
      [themeId, locationId, companyId],
      [saleOverrideText, dollarOffOverrideText, percentOffOverrideText]
    ]) => {
      return saleLabel?.getSaleText(
        [variant, priceStream, saleLabelFormat],
        [themeId, locationId, companyId],
        saleOverrideText,
        dollarOffOverrideText,
        percentOffOverrideText
      );
    })
  );

  private getLabelTextPipeline$(
    label$: Observable<Label | null>,
    originalLabelText$: Observable<string>
  ): Observable<string> {
    return label$.pipe(
      switchMap((label: Label) => {
        return iif(() => label instanceof SaleSystemLabel, this.systemSaleLabelText$, originalLabelText$);
      }),
      debounceTime(100),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public readonly labelText$ = this.getLabelTextPipeline$(this.label$, this.originalLabelText$);

  private getLabelBackgroundColorPipeline$(label$: Observable<Label | null>): Observable<string> {
    return label$.pipe(
      map(label => label?.color ?? ColorUtils.COLOR_BLACK)
    );
  }

  public readonly labelBackgroundColor$ = this.getLabelBackgroundColorPipeline$(this.label$);

  private getLabelTextColorPipeline$(
    label$: Observable<Label | null>,
    labelBackgroundColor$: Observable<string>
  ): Observable<string> {
    return combineLatest([
      label$,
      labelBackgroundColor$
    ]).pipe(
      map(([labelKey, labelBackgroundColor]) => {
        const offWhite = ColorUtils.COLOR_OFF_WHITE;
        const black = ColorUtils.COLOR_BLACK;
        const labelTextColor = labelKey?.textColor ?? offWhite;
        switch (true) {
          case exists(labelTextColor):
            return labelTextColor;
          case exists(labelBackgroundColor):
            return ColorUtils.isDarkColor(labelBackgroundColor) ? offWhite : black;
          default:
            return offWhite;
        }
      })
    );
  }

  public readonly labelTextColor$ = this.getLabelTextColorPipeline$(this.label$, this.labelBackgroundColor$);

  /* ************************************ Virtual Outputs ************************************ */

  /**
   * Virtual label ignores the hide label signal from the menu.
   * This is used so the label can still be calculated and known if the hide label signal is enabled.
   */
  public readonly virtualLabel$ = defer(() => this.getLabelPipeline$(false, true));

  public readonly originalVirtualLabelText$ = defer(() => this.getOriginalLabelTextPipeline$(this.virtualLabel$));

  public readonly virtualLabelText$ = defer(() => {
    return this.getLabelTextPipeline$(this.virtualLabel$, this.originalVirtualLabelText$);
  });

}
