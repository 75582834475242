import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { HarlequinAddressCardStackBackgroundViewModel } from './harlequin-address-card-stack-background-view-model';
import { CardBackgroundComponent } from '../../card-background.component';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-harlequin-address-card-stack-background',
  templateUrl: './harlequin-address-card-stack-background.component.html',
  styleUrls: ['./harlequin-address-card-stack-background.component.scss'],
  providers: [HarlequinAddressCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HarlequinAddressCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: HarlequinAddressCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: CardData;

}
