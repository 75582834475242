import { ProductMenu } from '../product-menu';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class TrueNorthDarkMenu extends ProductMenu {

  getThemeClass(): string {
    return 'true-north-dark-theme';
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.montserratExtraBold,
      FONT.montserratBold,
      FONT.montserratMedium,
      FONT.montserratSemiBold
    ];
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'product-row-overlay' : '';
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.Brand, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantType, 9],
      [SectionColumnProductInfoType.Stock, 10],
      [SectionColumnProductInfoType.VariantPrice, 11],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 12],
    ]);
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowColor(rowViewModel: SectionRowViewModel, odd: boolean): string {
    if (this.currentRowIsBanded(odd)) {
      const opacity = '0.03';
      return `rgba(0, 0, 0, ${opacity})`;
    } else {
      return null;
    }
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getTitleSectionTopMargin(): string {
    return '1.5rem';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

}
