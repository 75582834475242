import { Component, Input } from '@angular/core';
import { ProductMenuFooterComponent } from '../../../../product-menu/building-blocks/menu-footer/product-menu-footer.component';
import type { CompanyConfiguration } from '../../../../../../../../models/company/dto/company-configuration';
import type { SpotlightMenu } from '../../../../../../../../models/menu/spotlight-menu';
import type { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';

@Component({
  selector: 'app-spotlight-footer',
  templateUrl: './spotlight-footer.component.html'
})
export class SpotlightFooterComponent extends ProductMenuFooterComponent {

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: SpotlightMenu;
  @Input() reset: boolean;

  setupViews() {
  }

  setupBindings() {
  }

}
