@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/shared/components/doug-shelf-talker-content/doug-shelf-talker-item/doug-shelf-talker-item.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.doug-shelf-talker-item {
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}
.doug-shelf-talker-item .brand-logo-and-badges {
  display: flex;
  flex-direction: column;
}
.doug-shelf-talker-item .brand-logo-and-badges .brand-logo {
  height: 3.5rem;
  width: 3.5rem;
  object-fit: contain;
  align-content: center;
}
.doug-shelf-talker-item .brand-logo-and-badges .badges {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.doug-shelf-talker-item .brand-logo-and-badges .badges .badge {
  height: 1rem;
  width: 1rem;
}
.doug-shelf-talker-item .text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.doug-shelf-talker-item .text-content .brand-name {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  white-space: nowrap;
  overflow-x: clip;
  max-width: 6rem;
  line-height: 100%;
  letter-spacing: 0.28px;
}
.doug-shelf-talker-item .text-content .additional-info {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 100%;
  letter-spacing: 0.26px;
}
.doug-shelf-talker-item .text-content .sizing-info {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.24px;
}
.doug-shelf-talker-item .text-content .price-info {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 100%;
}
.doug-shelf-talker-item .text-content .shelf-talker-label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
.doug-shelf-talker-item .text-content .shelf-talker-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5625rem;
  overflow: clip;
  overflow-clip-margin: 0;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 140%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0.2rem 0;
  width: fit-content;
}
.doug-shelf-talker-item.larger {
  height: 6rem;
  gap: 1rem;
}
.doug-shelf-talker-item.larger .brand-logo-and-badges .brand-logo {
  height: 4.375rem;
  width: 4.375rem;
}
.doug-shelf-talker-item.larger .brand-logo-and-badges .badges {
  gap: 0.5rem;
}
.doug-shelf-talker-item.larger .brand-logo-and-badges .badges .badge {
  height: 1.25rem;
  width: 1.25rem;
}
.doug-shelf-talker-item.larger .text-content .brand-name {
  font-size: 1rem;
  letter-spacing: 0.32px;
  max-width: 7.5rem;
}
.doug-shelf-talker-item.larger .text-content .additional-info {
  font-size: 1rem;
  letter-spacing: 0.32px;
}
.doug-shelf-talker-item.larger .text-content .sizing-info {
  font-size: 0.9375rem;
  letter-spacing: 0.3px;
}
.doug-shelf-talker-item.larger .text-content .price-info {
  font-size: 1rem;
}
.doug-shelf-talker-item.larger .text-content .shelf-talker-label ::ng-deep .product-label {
  font-size: 0.8125rem;
}
/*# sourceMappingURL=doug-shelf-talker-item.component.css.map */
