// noinspection JSUnusedLocalSymbols

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RenderContextComponent } from '../../../models/base/render-context-component';
import { BehaviorSubject, combineLatest, merge, ReplaySubject, Subject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Asset } from '../../../models/image/dto/asset';
import { AssetSize } from '../../../models/enum/dto/asset-size.enum';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { CachePolicy } from '../../../models/enum/shared/cachable-image-policy.enum';
import { LoadingTechnique } from './loading-technique.enum';
import { AssetUrl } from '../../../models/image/dto/asset-url';
import { RefreshAssetService } from '../../../services/refresh-asset.service';
import { DistinctUtils } from '../../../utils/distinct.utils';
import { IsMenuReadyService } from '../../services/is-menu-ready.service';
import { MediaUtils } from '../../../utils/media-utils';
import { exists } from '../../../functions/exists';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent extends RenderContextComponent implements AfterViewInit, OnChanges {

  constructor(
    public el: ElementRef,
    private refreshAssetService: RefreshAssetService,
    private isMenuReadyService: IsMenuReadyService
  ) {
    super();
  }

  @ViewChild('image') private img: ElementRef<HTMLImageElement>;
  @ViewChild('video') private video: ElementRef<HTMLVideoElement>;
  @Input() asset: Asset;
  @Input() autoplay: boolean = false;
  @Input() borderRadius: string = '';
  @Input() cachePolicy: CachePolicy = CachePolicy.Service;
  @Input() cacheForNSeconds: number = MediaUtils.DefaultCacheTimeInSeconds;
  @Input() easeInFromTop: boolean = false; // animation on load
  @Input() loadingTechnique: LoadingTechnique = LoadingTechnique.THUMB;
  @Input() loopVideo: boolean = true;
  @Input() reset: boolean = false;
  @Input() scaleFit: boolean = false;
  @Input() showControls: boolean = false;
  @Input() size: AssetSize = MediaUtils.DefaultImageSize;
  @Input() styleOverrides: any;
  @Input() sweepFromBottomToTop: boolean = false; // animation on load
  @Input() sweepFromMiddleToTop: boolean = false; // animation on load
  @Input() playAudio: boolean = false;
  @Output() duration: EventEmitter<number> = new EventEmitter<number>();
  @Output() ratio: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentVideoTime = new EventEmitter<number>(true);
  @Output() videoStartedToLoad: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() videoEnded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private resetSubject = new BehaviorSubject<boolean>(false);
  private resetSignal$ = merge(
    this.resetSubject.pipe(distinctUntilChanged()),
    this.refreshAssetService.globalRefreshSignal$
  ).pipe(debounceTime(1));
  private isVideo = new BehaviorSubject<boolean>(false);
  public videoCanPlay = new BehaviorSubject<boolean>(false);

  // Reset video
  private resetVideo = new Subject<boolean>();
  private listenToReset = this.resetVideo.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy)
  ).subscribe(resetVid => {
    this.video?.nativeElement?.load();
    this.video?.nativeElement?.pause();
  });

  // Play video
  private playVideo = new Subject<boolean>();
  private listenToPlay = this.playVideo.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy)
  ).subscribe(playVideo => {
    this.video?.nativeElement?.play().then().catch(console.error);
  });

  // Reset
  private resetMech = combineLatest([
    this.resetSignal$,
    this.isVideo.pipe(startWith(false), distinctUntilChanged()),
    this.duration.asObservable().pipe(startWith(0), distinctUntilChanged()),
    this.videoCanPlay.pipe(distinctUntilChanged())
  ]).pipe(debounceTime(100), takeUntil(this.onDestroy))
    .subscribe(([reset, isVid, duration, canPlay]) => {
      if (isVid) {
        const loaded = duration > 0;
        const startedPlaying = ((this.video?.nativeElement?.currentTime ?? 0) >= 1);
        if (reset && loaded && startedPlaying) {
          this.resetVideo.next(true);
        } else if (!reset && canPlay) {
          this.playVideo.next(true);
        }
      } else if (reset) {
        this.fetchAsset();
      }
    });

  // Asset URL
  private assetSubject = new ReplaySubject<Asset>(1);
  private asset$ = this.assetSubject.pipe(distinctUntilChanged(DistinctUtils.distinctAsset));
  private imageAssetUrl: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public imageAssetUrl$ = this.imageAssetUrl.pipe(distinctUntilChanged());
  private videoAssetUrl: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public videoAssetUrl$ = this.videoAssetUrl.pipe(distinctUntilChanged());

  // Loading
  private loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private loadingTechniqueSubject = new BehaviorSubject<LoadingTechnique>(LoadingTechnique.THUMB);
  private loadingEnabled$ = this.loadingTechniqueSubject.pipe(map(technique => technique !== LoadingTechnique.THUMB));
  public loading$ = combineLatest([
    this.loadingEnabled$.pipe(distinctUntilChanged()),
    this.loading.pipe(startWith(true), distinctUntilChanged()),
  ]).pipe(map(([enabled, loading]) => enabled && loading));
  public notLoading$ = this.loading$.pipe(map(it => !it));

  // Show img
  public showImage$ = combineLatest([
    this.loading$,
    this.asset$
  ]).pipe(
    map(([loading, asset]) => (!loading) && (asset?.isImage() || asset?.isPDFImage(this.size)) && asset?.isValid())
  );

  // Show video
  public showVideo$ = combineLatest([
    this.loading$,
    this.asset$
  ]).pipe(
    map(([loading, asset]) => !loading && asset?.isVideo() && asset?.isValid()),
    distinctUntilChanged()
  );

  // Render Failed
  private _videoRenderFailed = new BehaviorSubject(false);
  private listenToRenderFailed = this._videoRenderFailed.pipe(
    debounceTime(10000),
    takeUntil(merge(this.onDestroy, this.isMenuReadyService.killIsMenuReadyService)),
  ).subscribe(renderFailed => {
    if (renderFailed) this.rendered.next(true);
  });

  ngOnChanges(changes: SimpleChanges): void {
    this.forceVideosToOriginalSize();
    const changed = (changes.asset?.previousValue !== undefined && changes.asset?.previousValue !== null);
    const oldHash = changes?.asset?.previousValue?.md5Hash;
    const newHash = changes?.asset?.currentValue?.md5Hash;
    const md5Changed = (changed && oldHash && newHash && (oldHash !== newHash));
    if (md5Changed || !changed) {
      this.setupImageBinding();
    }
    if (changes.reset) {
      this.checkReset();
    }
    if (this.loadingTechnique) {
      this.checkLoadingTechnique();
    }
  }

  setupViews() {
    this.ratio.emit(1);
    this.checkReset();
    this.checkLoadingTechnique();
    this.setupImageBinding();
  }

  private checkReset() {
    this.resetSubject.next(this.reset);
  }

  private checkLoadingTechnique() {
    this.loadingTechniqueSubject.next(this.loadingTechnique);
  }

  private setupImageBinding() {
    this.resetRenderedLogic();
    this.forceVideosToOriginalSize();
    this.fetchAsset();
    const imgKey = 'assetKey';
    const imgLoadingKey = 'loadingKey';
    this.destroyImageSub(imgKey);
    this.destroyImageSub(imgLoadingKey);
    let blobSub;
    let loadingSub;
    let loadingAssetUrl: AssetUrl | null | undefined;
    if (this.loadingTechnique === LoadingTechnique.SHIMMER) {
      loadingAssetUrl = this?.asset?.isVideo()
        ? this?.asset?.getAssetUrl(AssetSize.Original)
        : this?.asset?.getAssetUrl(this.size);
    } else if (this.loadingTechnique === LoadingTechnique.SHIMMER_THUMB) {
      loadingAssetUrl = this?.asset?.isVideo()
        ? this?.asset?.getAssetUrl(AssetSize.Original)
        : this?.asset?.getAssetUrl(AssetSize.Thumb);
    }
    if (loadingAssetUrl) {
      loadingSub = this.loading.bind(loadingAssetUrl.loading);
    } else {
      this.loading.next(false);
    }
    const priorityUrl$ = this.asset?.sizePriorityUrl$;
    if (priorityUrl$) {
      if (this.asset?.isImage() || this.asset?.isPDFImage(this.size)) {
        blobSub = this.imageAssetUrl.bind(priorityUrl$);
      } else if (this.asset?.isVideo()) {
        blobSub = this.videoAssetUrl.bind(priorityUrl$);
      }
    }
    if (blobSub) {
      this.pushImageSub(imgKey, blobSub);
    }
    if (loadingSub) {
      this.pushImageSub(imgLoadingKey, loadingSub);
    }
  }

  setupBindings() {
  }

  private forceVideosToOriginalSize() {
    if (this.asset) {
      if (this.asset.isVideo() || !this.asset.fileName) {
        this.isVideo.next(true);
        // if video force use original file size
        this.size = AssetSize.Original;
      } else {
        this.isVideo.next(false);
      }
    }
  }

  protected fetchAsset() {
    this.assetSubject.next(this.asset);
    const asset = this.asset;
    const size = this.size;
    const cacheForNSeconds = this.cacheForNSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (exists(asset) && exists(size) && validCacheTime) {
      const policy = this.cachePolicy ?? CachePolicy.Service;
      asset.getAsset(policy, size, cacheForNSeconds);
    }
  }

  resetRenderedLogic() {
    const hasAsset = exists(this.asset) && (this.asset.isValid());
    if (!hasAsset) {
      this.noAssetToRender();
    }
  }

  noAssetToRender() {
    // if no asset, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  imageRenderFailed() {
    // if failed, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  videoRenderFailed() {
    this._videoRenderFailed.next(true);
  }

  renderSucceeded() {
    this.rendered.next(true);
  }

  timeUpdate(ev: Event) {
    const tag = ev?.target as HTMLVideoElement;
    this.currentVideoTime.next(tag?.currentTime);
  }

  public ngAfterViewInit() {
    // Don't call super.ngAfterViewInit(), because we don't want to call rendered.next(true)
    this.setupBindings();
  }

}
