/* src/app/modules/print-cards/components/render-print-card-live-view/render-print-card-live-view.component.scss */
:host {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
:host .blank-white-card-for-initial-load {
  display: block;
  position: absolute;
  inset: 0;
  z-index: 1;
  background: white;
}
/*# sourceMappingURL=render-print-card-live-view.component.css.map */
