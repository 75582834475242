import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { FireAndFlowerShelfTalkerPostCardBackgroundViewModel } from './fire-and-flower-shelf-talker-post-card-background-view-model';
import { GroupedByBrandShelfTalkerCardData } from '../../../../../../../models/shelf-talkers/grouped-by-brand-shelf-talker-card-data';

@Component({
  selector: 'app-fire-and-flower-shelf-talker-post-card-background',
  templateUrl: './fire-and-flower-shelf-talker-post-card-background.component.html',
  styleUrl: './fire-and-flower-shelf-talker-post-card-background.component.scss',
  providers: [FireAndFlowerShelfTalkerPostCardBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlowerShelfTalkerPostCardBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public override viewModel: FireAndFlowerShelfTalkerPostCardBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: GroupedByBrandShelfTalkerCardData;

}
