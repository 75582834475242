import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { CacheService } from '../modules/services/cache-service';
import { LoggingService } from '../services/logging-service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { CompanyAPI } from '../api/company-api';
import { Cannabinoid } from '../models/enum/shared/cannabinoid';
import { SectionColumnCannabinoidType } from '../models/enum/shared/section-column-cannabinoid-type';
import { SectionColumnTerpeneType } from '../models/enum/shared/section-column-terpene-type';

@Injectable({ providedIn: 'root' })
export class CompanyDomainModel extends BaseDomainModel {

  constructor(
    private cacheService: CacheService,
    private companyAPI: CompanyAPI,
    private logging: LoggingService,
  ) {
    super();
  }

  private readonly _companyConfig = new BehaviorSubject<CompanyConfiguration|null>(null);
  public readonly companyConfig$ = this._companyConfig as Observable<CompanyConfiguration|null>;

  /**
   * THC and CBD are always enabled, and the rest are based on the company config.
   */
  public readonly enabledCannabinoids$ = this.companyConfig$.pipe(
    map(companyConfig => {
      const enabled = [
        Cannabinoid.THC, Cannabinoid.CBD, Cannabinoid.THCAndCBD,
        ...(companyConfig?.enabledCannabinoids || []),
      ];
      return enabled.map(c => SectionColumnCannabinoidType[c]);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpenes$ = this.companyConfig$.pipe(
    map(companyConfig => {
      const enabled = companyConfig?.enabledTerpenes || [];
      return enabled.map(c => SectionColumnTerpeneType[c]);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getCompanyConfig(companyId: number): Observable<CompanyConfiguration> {
    return this.companyAPI.GetCompanyConfiguration(companyId).pipe(
      tap(companyConfig => {
        this.logging.loadedCompanyConfig(companyId);
        this._companyConfig.next(companyConfig);
        this.cacheService.cacheObject(companyConfig.cacheKey(), companyConfig, true);
      }),
      catchError(err => {
        console.error('getCompanyConfig api error');
        return throwError(err);
      })
    );
  }

  public companyConfigSentFromDashboardApp(companyConfig: CompanyConfiguration): void {
    this._companyConfig.next(companyConfig);
  }

}
