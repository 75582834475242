import { FireAndFlowerOldStyleCardStack } from './fire-and-flower-old-style-card-stack';
import type { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { FireAndFlowerMemberCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/custom-cards-2x2/fire-and-flower-member-card-stack-background/fire-and-flower-member-card-stack-background.component';
import { FireAndFlowerMemberCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/custom-cards-2x2/fire-and-flower-member-card-stack-content/fire-and-flower-member-card-stack-content.component';

export class FireAndFlowerMemberCardStack extends FireAndFlowerOldStyleCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return FireAndFlowerMemberCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FireAndFlowerMemberCardStackContentComponent;
  }

}
