import { CardData } from './card-data';
import { LocationPriceStream } from '../enum/shared/location-price-stream';
import { VariantType } from '../enum/dto/variant-type.enum';
import { ProductType } from '../enum/dto/product-type.enum';
import { SectionColumnConfigDataValue } from '../enum/shared/section-column-config-data-value';
import { Cannabinoid } from '../enum/shared/cannabinoid';
import type { Variant } from '../product/dto/variant';

export class PopsCannabisCardData extends CardData {

  override getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return LocationPriceStream.TaxesInRounded;
  }

  public getLeftLargeText(): string {
    const productType = this.rowProductType();
    const variantType = this.rowVariantType();
    switch (true) {
      /* Select following based off variant type */
      case variantType === VariantType.Baking:
        return 'Baked Goods';
      case variantType === VariantType.Capsule:
        return 'Capsules';
      case variantType === VariantType.Gummy:
        return 'Gummies';
      case variantType === VariantType.HardCandy:
        return 'Lozenges';
      case variantType === VariantType.Oil:
        return 'Oils';
      case variantType === VariantType.Edible:
        return 'Edibles';
      /* Select following based off product type */
      case productType === ProductType.Flower:
      case productType === ProductType.Vape:
      case productType === ProductType.Concentrate:
        return this.getReadableStrainType();
      case productType === ProductType.Accessories:
        return 'Accessories';
      case productType === ProductType.Beverage:
        return 'Beverages';
      case productType === ProductType.Seed:
        return 'Seeds';
      case productType === ProductType.Wellness:
        return 'Topicals';
      default:
        return variantType;
    }
  }

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    const value = super.getCannabinoid(cannabinoid, displayFormat);
    if (cannabinoid === Cannabinoid.CBD) {
      return value?.includes('<') ? null : value;
    }
    return value;
  }

  protected override seedPackageQuantityWord(variant: Variant): string {
    return 'Pack';
  }

  protected override edibleNonReadyToDrinkPackageQuantityWord(variant: Variant): string {
    return 'Pack';
  }

  protected override quantityWordForEverythingElse(variant: Variant): string {
    return 'Piece';
  }

}
