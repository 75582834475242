import { AddressCardStack } from './address-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { ShelfTalkerCardData } from '../../../../shelf-talkers/shelf-talker-card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { HarlequinAddressCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/address-cards-1x3/harlequin-address-card-stack-background/harlequin-address-card-stack-background.component';
import { HarlequinAddressCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/address-cards-1x3/harlequin-address-card-stack-content/harlequin-address-card-stack-content.component';

export class HarlequinAddressCardStack extends AddressCardStack {

  getCardBackgroundComponent(data: CardData | ShelfTalkerCardData): Type<CardBackgroundComponent> | null {
    return HarlequinAddressCardStackBackgroundComponent;
  }

  getCardContentComponent(data: CardData): Type<PrintCardContentComponent> | null {
    return HarlequinAddressCardStackContentComponent;
  }

}
