<div class="doug-shelf-talker">
  <div class="doug-shelf-talker-header">
    <div
      class="header-title"
      [style.background-color]="viewModel.accentColor$ | push"
      [style.color]="viewModel.headerTextColor$ | push">
      {{ viewModel.headerText$ | push }}
    </div>
    <img
      *rxIf="viewModel.companyLogo$ | push as logo; strategy: 'immediate'"
      appImage
      class="header-logo"
      [asset]="logo"
      alt="company logo"
      src="" />
  </div>
  <div class="doug-shelf-talker-body">
    <div
      *rxFor="let rowVariants of viewModel.variantsSplitInto6Rows$; strategy: 'immediate'; let odd = odd"
      class="shelf-talker-row"
      [style.padding-left]="
        (viewModel.cardSize$ | push) === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? '0.8135rem' : '0.4375rem'
      "
      [class.odd]="odd">
      <app-doug-shelf-talker-item
        *ngIf="rowVariants?.[0] as firstGrouping"
        [shelfTalkerCardData]="viewModel.shelfTalkerCardData$ | push"
        [cardSize]="viewModel.cardSize$ | push"
        [variantsGroupedByBrand]="firstGrouping"
        [viewingInLiveView]="viewModel.viewingInLiveView$ | async" />
      <app-doug-shelf-talker-item
        *ngIf="rowVariants?.[1] as secondGrouping"
        [shelfTalkerCardData]="viewModel.shelfTalkerCardData$ | push"
        [cardSize]="viewModel.cardSize$ | push"
        [variantsGroupedByBrand]="secondGrouping"
        [viewingInLiveView]="viewModel.viewingInLiveView$ | async" />
    </div>
  </div>
</div>
