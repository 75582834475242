import { ShelfTalkerCardData } from './shelf-talker-card-data';
import type { Variant } from '../product/dto/variant';
import { VariantsGroupedByBrand } from '../../modules/print-cards/models/variants-grouped-by-brand';
import { Move, SortUtils } from '../../utils/sort-utils';
import type { LocationPriceStream } from '../enum/shared/location-price-stream';
import { type SectionSortOption, SectionSortProductInfo } from '../enum/dto/section-sort-option.enum';

export class GroupedByBrandShelfTalkerCardData extends ShelfTalkerCardData {

  protected getVariantsGroupedByBrand(): VariantsGroupedByBrand[] {
    const brandGroupedVariants = new Map<string, Variant[]>(); // Map<Brand, Variant[]>
    this.variants?.forEach(variant => {
      const brand = variant?.brand?.toLowerCase()?.trim();
      if (brand) {
        const variants = brandGroupedVariants.get(brand) || [];
        variants.push(variant);
        brandGroupedVariants.set(brand, variants);
      }
    });
    return [...(brandGroupedVariants.values() || [])].map(variants => {
      return new VariantsGroupedByBrand(this.menu, this.section, variants, this.locationConfig, this.companyConfig);
    });
  }

  getVariantsGroupedByBrandThenSortedBySectionSpecifications(): VariantsGroupedByBrand[] {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const compId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.menu?.menuOptions?.hideSale;
    const variantsGroupedByBrand = this.getVariantsGroupedByBrand();
    const primarySort = this.getSortingLambda(this.section?.sorting) ?? this.variantsGroupedByBrandSortedByBrandAsc;
    const secondarySort = this.getSortingLambda(this.section?.secondarySorting);
    return variantsGroupedByBrand.sort((a, b) => {
      return primarySort(a, b, themeId, locId, compId, priceStream, hideSale)
          || (secondarySort?.(a, b, themeId, locId, compId, priceStream, hideSale) || Move.Nothing);
    });
  }

  variantsGroupedByBrandSortedByBrandAsc = (a: VariantsGroupedByBrand, b: VariantsGroupedByBrand): Move => {
    return SortUtils.getMovement(a?.getBrandName()?.localeCompare(b?.getBrandName(), 'en', { numeric: true }));
  };

  variantsGroupedByBrandSortedByBrandDesc = (a: VariantsGroupedByBrand, b: VariantsGroupedByBrand): Move => {
    return SortUtils.getMovement(b?.getBrandName()?.localeCompare(a?.getBrandName(), 'en', { numeric: true }));
  };

  variantsGroupedByBrandSortedByPriceAsc = (
    a: VariantsGroupedByBrand,
    b: VariantsGroupedByBrand,
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): Move => {
    return SortUtils.numberAscendingZerosAndNullsLast(
      a?.getMinPrice(themeId, locationId, companyId, priceStream, hideSale),
      b?.getMinPrice(themeId, locationId, companyId, priceStream, hideSale)
    );
  };

  variantsGroupedByBrandSortedByPriceDesc = (
    a: VariantsGroupedByBrand,
    b: VariantsGroupedByBrand,
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): Move => {
    return SortUtils.numberDescendingZerosAndNullsLast(
      a?.getMaxPrice(themeId, locationId, companyId, priceStream, hideSale),
      b?.getMaxPrice(themeId, locationId, companyId, priceStream, hideSale)
    );
  };

  private getSortingLambda(
    sorting: SectionSortOption
  ): (
    a: VariantsGroupedByBrand,
    b: VariantsGroupedByBrand,
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ) => Move {
    switch (sorting) {
      case SectionSortProductInfo.BrandAsc:
        return this.variantsGroupedByBrandSortedByBrandAsc;
      case SectionSortProductInfo.BrandDesc:
        return this.variantsGroupedByBrandSortedByBrandDesc;
      case SectionSortProductInfo.PriceAsc:
        return this.variantsGroupedByBrandSortedByPriceAsc;
      case SectionSortProductInfo.PriceDesc:
        return this.variantsGroupedByBrandSortedByPriceDesc;
      default:
        return null;
    }
  }

}
