import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MenuItemComponent } from '../menu-item.component';
import { ProductSectionItemViewModel } from './product-section-item-view-model';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import type { ProductMenu } from '../../../../../../../../models/menu/product-menu';
import type { LabelInflatorComponent } from '../../../../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import type { SectionColumnViewModel } from '../../menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { VariantBadge } from '../../../../../../../../models/product/dto/variant-badge';

@Component({
  selector: 'app-product-section-item',
  templateUrl: './product-section-item.component.html',
  styleUrls: ['./product-section-item.component.scss'],
  providers: [ProductSectionItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSectionItemComponent extends MenuItemComponent implements OnChanges, AfterViewInit {

  constructor(
    public viewModel: ProductSectionItemViewModel
  ) {
    super();
  }

  @Input() override menu: ProductMenu;
  @Input() override section: SectionWithProducts;
  @ViewChild('labelCalculator') labelCalculator?: LabelInflatorComponent;

  setupViews() {
    super.setupViews();
    this.viewModel.connectToCalculationMode(this.calculationMode);
    this.viewModel.connectToReset(this.reset);
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToSection(this.section);
    this.viewModel.connectToRowViewModel(this.rowViewModel);
    this.viewModel.connectToSectionRowViewModels(this.sectionRowViewModels);
    this.viewModel.connectToOdd(this.odd);
  }

  setupBindings() {
    super.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes?.calculationMode) this.viewModel.connectToCalculationMode(this.calculationMode);
    if (changes?.reset) this.viewModel.connectToReset(this.reset);
    if (changes?.menu) this.viewModel.connectToMenu(this.menu);
    if (changes?.section) this.viewModel.connectToSection(this.section);
    if (changes?.rowViewModel) this.viewModel.connectToRowViewModel(this.rowViewModel);
    if (changes?.sectionRowViewModels) this.viewModel.connectToSectionRowViewModels(this.sectionRowViewModels);
    if (changes?.odd) this.viewModel.connectToOdd(this.odd);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    const connect = (labelComponent?: LabelInflatorComponent) => {
      labelComponent?.currentLabelText$
        ?.pipe(takeUntil(this.onDestroy))
        ?.subscribe(labelText => this.viewModel.connectToLabelText(labelText));
    };
    connect(this.labelCalculator);
  }

  trackByColViewModelId = (index: number, columnViewModel: SectionColumnViewModel): string => {
    return columnViewModel?.uniqueId();
  };

  trackByBadgeUniqueIdentifier = (index: number, badge: VariantBadge): string => {
    return badge?.getUniqueIdentifier();
  };

}
