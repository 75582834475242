import { cacheResult } from '../modules/shared/decorators/cache-result';
import { Cannabinoid } from '../models/enum/shared/cannabinoid';
import { Terpene } from '../models/enum/shared/terpene';
import { StringUtils } from './string-utils';

export class EnumUtils {

  static createEnum<T extends string | number>(values: T[]): { [K in T]: K } {
    return values.reduce((prev, curr) => { prev[curr] = curr; return prev; }, {} as { [K in T]: K});
  }

  @cacheResult
  static getCannabinoids(): Cannabinoid[] {
    return Object.values(Cannabinoid);
  }

  @cacheResult
  static getTerpenes(): Terpene[] {
    return Object.values(Terpene);
  }

  @cacheResult
  static getTerpenesPascalCased(): string[] {
    return EnumUtils.getTerpenes().map(t => StringUtils.toPascalCase(t));
  }

  @cacheResult
  static getTerpenesCamelCasedAndPascalCased(): { camelCase: string, pascalCase: string }[] {
    return EnumUtils.getTerpenes().map(terp => {
      return {
        camelCase: StringUtils.camelize(terp),
        pascalCase: StringUtils.toPascalCase(terp)
      };
    });
  }

}
