import { OpacityLocation } from '../shared/opacity-location.enum';
import { CardBackground } from '../protocols/card-background';
import type { Asset } from '../image/dto/asset';
import type { ShelfTalkerMenu } from '../menu/shelf-talkers/shelf-talker-menu';
import type { ShelfTalkerCard } from '../menu/section/shelf-talker-card/shelf-talker-card';
import type { Product } from '../product/dto/product';
import type { Variant } from '../product/dto/variant';
import type { CompanyConfiguration } from '../company/dto/company-configuration';
import type { LocationConfiguration } from '../company/dto/location-configuration';
import type { VariantBadge } from '../product/dto/variant-badge';

export class ShelfTalkerCardData implements CardBackground {

  menu: ShelfTalkerMenu;
  section: ShelfTalkerCard;
  products: Product[];
  variants: Variant[];
  hideLabel = false;
  companyConfig: CompanyConfiguration;
  locationConfig: LocationConfiguration;
  variantBadgeMap: Map<string, VariantBadge[]> = new Map<string, VariantBadge[]>();

  getLayoutInstructions(): ShelfTalkerCard {
    return this.section;
  }

  getBackgroundImage(): Asset {
    return this.menu?.hydratedTheme?.themeFeatures?.backgroundMedia ? this.section?.image : null;
  }

  getBackgroundImageAlignment(): string {
    return this.section?.metadata?.imageAlignment;
  }

  getBackgroundImageObjectFit(): string {
    return this.section?.metadata?.objectFit;
  }

  getBackgroundImageWidthAsPercentage(): string {
    return this.section?.metadata?.width;
  }

  getBackgroundAssetWrapperClass(): string {
    return '';
  }

  getBackgroundOpacity(): string {
    const opacityEnabled = this.menu?.getOpacityEnabled();
    const opacityLocationIsBackgroundAsset = this.menu?.getOpacityLocation() === OpacityLocation.BACKGROUND_ASSET;
    return opacityEnabled && opacityLocationIsBackgroundAsset ? this.section?.metadata?.cardOpacity : '0';
  }

  getColorBackgroundAssetBorderRadius(): string {
    return this.menu?.getColorBackgroundAssetBorderRadius();
  }

  getCardColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionBodyBackgroundColor
      ? this.section?.metadata?.productsContainerBackgroundColor
      : '';
  }

  getAccentColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionHeaderBackgroundColor
      ? this.section?.metadata?.productsInfoBackgroundColor
      : '';
  }

  getHeaderTextColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionHeaderTextColor
      ? this.section?.metadata?.sectionHeaderTextColor
      : '';
  }

  getBodyTextColor(): string {
    return this.menu?.hydratedTheme?.themeFeatures?.sectionBodyTextColor
      ? this.section?.metadata?.sectionBodyTextColor
      : '';
  }

  getCardTitle(): string {
    return this.section?.title;
  }

}
