import { CardData } from './card-data';
import { exists } from '../../functions/exists';
import { StrainType } from '../enum/dto/strain-classification.enum';
import { LocationPriceStream } from '../enum/shared/location-price-stream';
import { ProductType } from '../enum/dto/product-type.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { memoize } from '../../modules/shared/decorators/memoize';

export class SessionsCardData extends CardData {

  @memoize
  override getQuantityAndSizeString(): string {
    const rowVariant = this.rowVariants?.firstOrNull();
    const productType = rowVariant?.productType;
    const variantType = rowVariant?.variantType;
    const packagedQuantity = rowVariant?.packagedQuantity;
    const productAndVariantTypeCheck = (pt: ProductType, vt: VariantType): boolean => {
      return pt === ProductType.Seed
        || vt === VariantType.Seed
        || VariantTypeUtils.isPreRollType(vt)
        || VariantTypeUtils.isCapsuleType(vt)
        || pt === ProductType.Edible
        || VariantTypeUtils.isReadyToDrinkBeverageType(vt);
    };
    const size = this.getSize();
    if (productAndVariantTypeCheck(productType, variantType) || packagedQuantity < 1) {
      return size;
    }
    const quantity = this.getQuantityString();
    return (!size || size === '-' || size === '--') ? size : [quantity, size].filter(s => exists(s)).join('x');
  }

  public override getSize(): string {
    const v = this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return null;
      }
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
        switch (true) {
          case hasQty && hasSize:  return `${quantity}x${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        return quantity === 1 ? `${formattedUnitSize}` : `${quantity} x ${formattedUnitSize}`;
      }
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        return `${v?.packagedQuantity} pc`;
      }
      default: {
        return v?.getFormattedUnitSize();
      }
    }
  }

  override getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return LocationPriceStream.TaxesInRounded;
  }

  getColorStripColor(): string {
    const productsInfoBackgroundColor = this.section?.metadata?.productsInfoBackgroundColor;
    if (exists(productsInfoBackgroundColor)) return productsInfoBackgroundColor;
    const rowVariant = this.rowVariants?.firstOrNull();
    const strain = rowVariant?.classification;
    switch (strain) {
      case StrainType.Indica:
      case StrainType.IndicaDominant:
        return '#875d76';
      case StrainType.Sativa:
      case StrainType.SativaDominant:
        return '#cb554b';
      case StrainType.Hybrid:
        return '#45846a';
      case StrainType.CBD:
        return '#4b6fcb';
      default:
        return '#ea6a54';
    }
  }

}
