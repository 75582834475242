import { SectionRowViewModel } from './SectionRowViewModel';
import { ProductType } from '../../../../../../../../../models/enum/dto/product-type.enum';
import { VariantTypeUtils } from '../../../../../../../../../utils/variant-type-utils';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/enum/shared/section-column-config-data-value';

export class SectionRowViewModelDoubleDutch extends SectionRowViewModel {

  public getSize(): string {
    const variant = this.rowVariants?.firstOrNull();
    if (VariantTypeUtils.isPreRollType(variant?.variantType)) {
      const quantity = variant?.packagedQuantity;
      const size = variant?.getFormattedUnitSize();
      return `${quantity}x${size}`;
    } else if (VariantTypeUtils.isCapsuleType(variant?.variantType)) {
      const n = variant?.packagedQuantity ?? 0;
      return n > 0 ? `${n} cap${n > 1 ? 's' : ''}` : '';
    } else if (VariantTypeUtils.isReadyToDrinkBeverageType(variant?.variantType)) {
      return `${variant?.unitSize} ${variant?.unitOfMeasure}`;
    } else if (variant?.productType === ProductType.Edible) {
      return `${variant?.packagedQuantity} pc`;
    } else if (variant?.packagedQuantity > 1) {
      return `${variant?.packagedQuantity} pc`;
    } else {
      return super.getSize();
    }
  }

  override getReadableStrainType(
    strainTypeMode: SectionColumnConfigDataValue = SectionColumnConfigDataValue.StrainTypeSymbol
  ): string {
    return super.getReadableStrainType(strainTypeMode);
  }

}
