@charset "UTF-8";

/* src/app/modules/print-cards/components/print-labels/label-content/custom-labels-2x4/base-inventory-label-stack-content/base-inventory-label-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.inventory-label {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  gap: 1rem;
}
.inventory-label .left-side {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inventory-label .left-side .top-left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.inventory-label .left-side .top-left-side .product-name {
  font-family: poppins, sans-serif;
  font-weight: 700;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 1.5rem;
  line-height: 120%;
}
.inventory-label .left-side .top-left-side .product-brand {
  font-family: poppins, sans-serif;
  font-weight: 500;
  overflow: clip;
  overflow-clip-margin: 0.12rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.25rem;
  line-height: 100%;
}
.inventory-label .left-side .product-size {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 700;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 1.25rem;
  line-height: 100%;
}
.inventory-label .right-side {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.inventory-label .right-side .top-right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;
}
.inventory-label .right-side .top-right-side .product-image {
  max-width: 80%;
  height: 4.375rem;
  object-fit: contain;
}
.inventory-label .right-side .top-right-side .classification-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  height: 2.625rem;
  width: 2.625rem;
  line-height: 1;
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}
.inventory-label .right-side .product-sku {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 1.125rem;
  align-self: flex-end;
}
.inventory-label .right-side .bar-code-value {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
}
.inventory-label .barcode-container {
  position: relative;
  width: 100%;
}
.inventory-label .barcode-element {
  position: relative;
  z-index: 1;
}
.inventory-label .invalid-barcode-overlay {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.66);
  color: black;
  z-index: 2;
}
/*# sourceMappingURL=base-inventory-label-stack-content.component.css.map */
