import { Move, SortUtils } from './sort-utils';
import { Variant } from '../models/product/dto/variant';
import { StrainType } from '../models/enum/dto/strain-classification.enum';
import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import { StringUtils } from './string-utils';
import type { SectionSortOption } from '../models/enum/dto/section-sort-option.enum';
import type { Cannabinoid } from '../models/enum/shared/cannabinoid';
import { SectionColumnConfigDataValue } from '../models/enum/shared/section-column-config-data-value';
import { SectionColumnConfigProductInfoKey } from '../models/enum/shared/section-column-config-product-info-key';

// @dynamic
export class SortVariantUtils extends SortUtils {

  /** Explicitly defined ordering */
  public static strainTypeSortAsc = (a: StrainType, b: StrainType): Move => {
    const getOrderNumber = (strainType: StrainType) => {
      switch (strainType) {
        case StrainType.Sativa:         return 1;
        case StrainType.SativaDominant: return 2;
        case StrainType.Hybrid:         return 3;
        case StrainType.Blend:          return 4;
        case StrainType.Indica:         return 5;
        case StrainType.IndicaDominant: return 6;
        case StrainType.CBD:            return 7;
        default:                        return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortVariantUtils.numberAscending(aOrderNumber, bOrderNumber);
  };
  /** Explicitly defined ordering */
  public static strainTypeSortDesc = (a: StrainType, b: StrainType): Move => {
    const getOrderNumber = (strainType: StrainType) => {
      switch (strainType) {
        case StrainType.CBD:            return 1;
        case StrainType.IndicaDominant: return 2;
        case StrainType.Indica:         return 3;
        case StrainType.Blend:          return 4;
        case StrainType.Hybrid:         return 5;
        case StrainType.SativaDominant: return 6;
        case StrainType.Sativa:         return 7;
        default:                        return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortVariantUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

  static doubleDutchStrainTypeSort(
    a: StrainType,
    b: StrainType
  ): Move {
    return SortVariantUtils.genericStrainTypeSort(a, b);
  }

  static plantlifeStrainTypeSort(
    a: StrainType,
    b: StrainType
  ): Move {
    const getOrderNumber = (strainType: StrainType) => {
      switch (strainType) {
        case StrainType.Sativa:         return 1;
        case StrainType.SativaDominant: return 2;
        case StrainType.Hybrid:         return 3;
        case StrainType.Indica:         return 4;
        case StrainType.IndicaDominant: return 5;
        case StrainType.Blend:          return 6;
        case StrainType.CBD:            return 7;
        default:                        return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortVariantUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  static luxLeafStrainTypeSort(
    a: StrainType,
    b: StrainType
  ): Move {
    const getOrderNumber = (strainType: StrainType) => {
      switch (strainType) {
        case StrainType.Sativa:         return 1;
        case StrainType.SativaDominant: return 2;
        case StrainType.Hybrid:         return 3;
        case StrainType.Indica:         return 4;
        case StrainType.IndicaDominant: return 5;
        case StrainType.Blend:          return 6;
        case StrainType.CBD:            return 7;
        default:                        return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortVariantUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  static genericStrainTypeSort(
    a: StrainType,
    b: StrainType
  ): Move {
    const getOrderNumber = (strainType: StrainType) => {
      switch (strainType) {
        case StrainType.Sativa:         return 1;
        case StrainType.SativaDominant: return 2;
        case StrainType.Indica:         return 3;
        case StrainType.IndicaDominant: return 4;
        case StrainType.Hybrid:         return 5;
        case StrainType.Blend:          return 6;
        case StrainType.CBD:            return 7;
        default:                        return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortVariantUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  /* *******************************************************************************************
   *                                  Variant - Sort Options                                   *
   * *******************************************************************************************/

  static variantsBrandAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringAscendingNullsLast(a?.brand, b?.brand);
  };
  static variantsBrandDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringDescendingNullsLast(a?.brand, b?.brand);
  };

  static variantsCannabinoidSortAsc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMinCannabinoidOrTerpene(cannabinoid)
      : a?.getNumericCannabinoidOrTerpene(cannabinoid);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
      : b?.getNumericCannabinoidOrTerpene(cannabinoid);
    return SortVariantUtils.numberAscending(aNumeric, bNumeric);
  };
  static variantsCannabinoidSortDesc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
      : a?.getNumericCannabinoidOrTerpene(cannabinoid);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
      : b?.getNumericCannabinoidOrTerpene(cannabinoid);
    return SortVariantUtils.numberDescending(aNumeric, bNumeric);
  };

  static variantsTACAsc = (a: Variant, b: Variant, enabledSecondaryCannabinoids: Cannabinoid[]): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMinTAC(enabledSecondaryCannabinoids)
      : a?.getNumericTAC(enabledSecondaryCannabinoids);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMinTAC(enabledSecondaryCannabinoids)
      : b?.getNumericTAC(enabledSecondaryCannabinoids);
    return SortVariantUtils.numberAscendingNegativesAndZerosAndNullsLast(aNumeric, bNumeric);
  };
  static variantsTACDesc = (a: Variant, b: Variant, enabledSecondaryCannabinoids: Cannabinoid[]): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMaxTAC(enabledSecondaryCannabinoids)
      : a?.getNumericTAC(enabledSecondaryCannabinoids);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMaxTAC(enabledSecondaryCannabinoids)
      : b?.getNumericTAC(enabledSecondaryCannabinoids);
    return SortVariantUtils.numberDescendingNegativesAndZerosAndNullsLast(aNumeric, bNumeric);
  };

  static variantsTerpeneSortAsc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
      : a?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
      : b?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    return SortVariantUtils.numberAscending(aNumeric, bNumeric);
  };
  static variantsTerpeneSortDesc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
      : a?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
      : b?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    return SortVariantUtils.numberDescending(aNumeric, bNumeric);
  };

  static variantsTATAsc = (a: Variant, b: Variant, enabledTerpenesPascalCased: string[]): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMinTAT(enabledTerpenesPascalCased)
      : a?.getNumericTAT(enabledTerpenesPascalCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMinTAT(enabledTerpenesPascalCased)
      : b?.getNumericTAT(enabledTerpenesPascalCased);
    return SortVariantUtils.numberAscendingNegativesAndZerosAndNullsLast(aNumeric, bNumeric);
  };
  static variantsTATDesc = (a: Variant, b: Variant, enabledTerpenesPascalCased: string[]): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMaxTAT(enabledTerpenesPascalCased)
      : a?.getNumericTAT(enabledTerpenesPascalCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMaxTAT(enabledTerpenesPascalCased)
      : b?.getNumericTAT(enabledTerpenesPascalCased);
    return SortVariantUtils.numberDescendingNegativesAndZerosAndNullsLast(aNumeric, bNumeric);
  };

  static getTerpeneAccessor = (sortType: SectionSortOption): { terpeneCamelCased: string, order: 'ASC'|'DESC' } => {
    const [terpene, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    const terpeneCamelCased = terpene
      .split('_')
      .map((it, index) => index === 0 ? it.toLowerCase() : StringUtils.sentenceCase(it))
      .join('');
    return { terpeneCamelCased, order };
  };

  static getCannabinoidAccessor = (sortType: SectionSortOption): { cannabinoid: string, order: 'ASC'|'DESC' } => {
    const [cannabinoid, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    return { cannabinoid, order };
  };

  static variantsTopTerpeneAsc = (a: Variant, b: Variant, enabledTerpenesPascalCased: string[]): Move => {
    return SortVariantUtils.stringAscendingNullsLast(
      a?.getTopTerpene(enabledTerpenesPascalCased),
      b?.getTopTerpene(enabledTerpenesPascalCased)
    );
  };
  static variantsTopTerpeneDesc = (a: Variant, b: Variant, enabledTerpenesPascalCased: string[]): Move => {
    return SortVariantUtils.stringDescendingNullsLast(
      a?.getTopTerpene(enabledTerpenesPascalCased),
      b?.getTopTerpene(enabledTerpenesPascalCased)
    );
  };

  static variantsManufacturerAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringAscendingNullsLast(a?.manufacturer, b?.manufacturer);
  };
  static variantsManufacturerDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringDescendingNullsLast(a?.manufacturer, b?.manufacturer);
  };

  static variantsPackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberAscending(a?.packagedQuantity, b?.packagedQuantity);
  };
  static variantsPackagedQuantityDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberDescending(a?.packagedQuantity, b?.packagedQuantity);
  };

  static variantsPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantUtils.numberAscending(aVisiblePrice, bVisiblePrice);
  };
  static variantsPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantUtils.numberDescending(aVisiblePrice, bVisiblePrice);
  };

  static variantsPricePerUOMAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aPricePerUOM = a?.getPricePerUOM(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bPricePerUOM = b?.getPricePerUOM(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantUtils.numberAscending(aPricePerUOM, bPricePerUOM);
  };
  static variantsPricePerUOMDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aPricePerUOM = a?.getPricePerUOM(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bPricePerUOM = b?.getPricePerUOM(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantUtils.numberDescending(aPricePerUOM, bPricePerUOM);
  };

  static variantsOriginalPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aOriginalPrice = a?.getPriceWithoutDiscounts(themeId, locationId, companyId, locationPriceStream);
    const bOriginalPrice = b?.getPriceWithoutDiscounts(themeId, locationId, companyId, locationPriceStream);
    return SortUtils.numberAscending(aOriginalPrice, bOriginalPrice);
  };
  static variantsOriginalPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aOriginalPrice = a?.getPriceWithoutDiscounts(themeId, locationId, companyId, locationPriceStream);
    const bOriginalPrice = b?.getPriceWithoutDiscounts(themeId, locationId, companyId, locationPriceStream);
    return SortUtils.numberDescending(aOriginalPrice, bOriginalPrice);
  };

  static variantsSaleOriginalPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aSaleOriginalPrice = a?.getSaleOriginalPriceOrNull(themeId, locationId, companyId, locationPriceStream);
    const bSaleOriginalPrice = b?.getSaleOriginalPriceOrNull(themeId, locationId, companyId, locationPriceStream);
    return SortUtils.numberAscending(aSaleOriginalPrice, bSaleOriginalPrice);
  };
  static variantsSaleOriginalPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aSaleOriginalPrice = a?.getDiscountedPriceOrNull(themeId, locationId, companyId, locationPriceStream);
    const bSaleOriginalPrice = b?.getDiscountedPriceOrNull(themeId, locationId, companyId, locationPriceStream);
    return SortUtils.numberDescending(aSaleOriginalPrice, bSaleOriginalPrice);
  };

  static variantsOriginalAndSalePriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aSaleOriginalPrice = a?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, false);
    const bSaleOriginalPrice = b?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, false);
    return SortUtils.numberAscending(aSaleOriginalPrice, bSaleOriginalPrice);
  };
  static variantsOriginalAndSalePriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aOriginalAndSalePrice = a?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, false);
    const bOriginalAndSalePrice = b?.getVisiblePrice(themeId, locationId, companyId, locationPriceStream, false);
    return SortUtils.numberDescending(aOriginalAndSalePrice, bOriginalAndSalePrice);
  };

  static variantsTaxesInPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInPrice(themeId, locationId, companyId,);
    const bTaxesInPrice = b?.getTaxesInPrice(themeId, locationId, companyId);
    return SortUtils.numberAscending(aTaxesInPrice, bTaxesInPrice);
  };
  static variantsTaxesInPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInPrice(themeId, locationId, companyId);
    const bTaxesInPrice = b?.getTaxesInPrice(themeId, locationId, companyId);
    return SortUtils.numberDescending(aTaxesInPrice, bTaxesInPrice);
  };

  static variantsTaxesInRoundedPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInRoundedPrice(themeId, locationId, companyId,);
    const bTaxesInPrice = b?.getTaxesInRoundedPrice(themeId, locationId, companyId);
    return SortUtils.numberAscending(aTaxesInPrice, bTaxesInPrice);
  };
  static variantsTaxesInRoundedPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInRoundedPrice(themeId, locationId, companyId);
    const bTaxesInPrice = b?.getTaxesInRoundedPrice(themeId, locationId, companyId);
    return SortUtils.numberDescending(aTaxesInPrice, bTaxesInPrice);
  };

  static variantsPreTaxPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aPreTaxPrice = a?.getPreTaxPrice(themeId, locationId, companyId,);
    const bPreTaxPrice = b?.getPreTaxPrice(themeId, locationId, companyId);
    return SortUtils.numberAscending(aPreTaxPrice, bPreTaxPrice);
  };
  static variantsPreTaxPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locationId, companyId]: [string, number, number]
  ): Move => {
    const aPreTaxPrice = a?.getPreTaxPrice(themeId, locationId, companyId);
    const bPreTaxPrice = b?.getPreTaxPrice(themeId, locationId, companyId);
    return SortUtils.numberDescending(aPreTaxPrice, bPreTaxPrice);
  };

  static variantsProductTypeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringAscendingNullsLast(a?.productType, b?.productType);
  };
  static variantsProductTypeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringDescendingNullsLast(a?.productType, b?.productType);
  };

  static variantsSecondaryPriceAsc = (
    [a, b]: [Variant, Variant],
    [themeId, locId, companyId]: [string, number, number],
    [section, priceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return SortVariantUtils.variantsPricePerUOMAsc([a, b], [themeId, locId, companyId], [priceStream, hideSale]);
      case SectionColumnConfigDataValue.OriginalPrice:
        return SortVariantUtils.variantsOriginalPriceAsc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        return SortVariantUtils.variantsSaleOriginalPriceAsc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.OriginalAndSalePrice:
        return SortVariantUtils.variantsOriginalAndSalePriceAsc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.TaxesInPrice:
        return SortVariantUtils.variantsTaxesInPriceAsc([a, b], [themeId, locId, companyId]);
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return SortVariantUtils.variantsTaxesInRoundedPriceAsc([a, b], [themeId, locId, companyId]);
      case SectionColumnConfigDataValue.PreTaxPrice:
        return SortVariantUtils.variantsPreTaxPriceAsc([a, b], [themeId, locId, companyId]);
      default:
        return a?.getSecondaryPrice(themeId, companyId, locId, priceStream)
             - b?.getSecondaryPrice(themeId, companyId, locId, priceStream);
    }
  };
  static variantsSecondaryPriceDesc = (
    [a, b]: [Variant, Variant],
    [themeId, locId, companyId]: [string, number, number],
    [section, priceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return SortVariantUtils.variantsPricePerUOMDesc([a, b], [themeId, locId, companyId], [priceStream, hideSale]);
      case SectionColumnConfigDataValue.OriginalPrice:
        return SortVariantUtils.variantsOriginalPriceDesc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        return SortVariantUtils.variantsSaleOriginalPriceDesc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.OriginalAndSalePrice:
        return SortVariantUtils.variantsOriginalAndSalePriceDesc([a, b], [themeId, locId, companyId], [priceStream]);
      case SectionColumnConfigDataValue.TaxesInPrice:
        return SortVariantUtils.variantsTaxesInPriceDesc([a, b], [themeId, locId, companyId]);
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return SortVariantUtils.variantsTaxesInRoundedPriceDesc([a, b], [themeId, locId, companyId]);
      case SectionColumnConfigDataValue.PreTaxPrice:
        return SortVariantUtils.variantsPreTaxPriceDesc([a, b], [themeId, locId, companyId]);
      default:
        return b?.getSecondaryPrice(themeId, companyId, locId, priceStream)
             - a?.getSecondaryPrice(themeId, companyId, locId, priceStream);
    }
  };

  static variantsStockAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberAscending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };
  static variantsStockDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberDescending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };

  static variantsTitleAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringAscendingNullsLast(a?.getVariantTitle(), b?.getVariantTitle());
  };
  static variantsTitleDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringDescendingNullsLast(a?.getVariantTitle(), b?.getVariantTitle());
  };

  static variantsVariantTypeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringAscendingNullsLast(a?.variantType, b?.variantType);
  };
  static variantsVariantTypeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.stringDescendingNullsLast(a?.variantType, b?.variantType);
  };

  static variantsUnitSizeAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberAscending(a?.unitSize, b?.unitSize);
  };
  static variantsUnitSizeDesc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.numberDescending(a?.unitSize, b?.unitSize);
  };

  static sortVariantsByUnitSizeAscElsePackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortVariantUtils.variantsUnitSizeAsc(a, b) || SortVariantUtils.variantsPackagedQuantityAsc(a, b);
  };

}
