import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-variant-type',
  templateUrl: './column-variant-type.component.html',
  styleUrls: ['./column-variant-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnVariantTypeComponent extends ColumnComponent {
}
