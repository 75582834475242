import type { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { IndexCardStack } from './index-card-stack';
import { PlainJaneIndexCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/index-cards-3x5/plain-jane-index-card-stack-background/plain-jane-index-card-stack-background.component';
import { PlainJaneIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/index-cards-3x5/plain-jane-index-card-stack-content/plain-jane-index-card-stack-content.component';

export class PlainJaneIndexCardStack extends IndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> {
    return PlainJaneIndexCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return PlainJaneIndexCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
