import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class TaxRate implements Deserializable, UniquelyIdentifiable {

  public id: string;
  public name: string;
  public percent: number;
  public layer: number;

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `
      -${this.id}
      -${this.name}
      -${this.percent}
      -${this.layer}
    `;
  }

}
