import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { BaseService } from '../../models/base/base-service';
import { debounceTime, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';
import { exists } from '../../functions/exists';

@Injectable({ providedIn: 'root' })
export class ScaleDownService extends BaseService {

  private scaleDownMap = new BehaviorSubject<Map<string, number>>(new Map());
  private resize = fromEvent(window, 'resize')
    .pipe(takeUntil(this.onDestroy))
    .subscribe(_ => {
      const m = this.scaleDownMap.getValue();
      m.clear();
      this.scaleDownMap.next(m);
    });

  public windowChange$ = fromEvent(window, 'resize').pipe(
    debounceTime(10),
    map(it => exists(it)),
    startWith(false),
    debounceTime(1),
    tap(() => this.clearMap()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  connectToScaleDownMap(): Observable<Map<string, number>> {
    return this.scaleDownMap.asObservable().pipe(debounceTime(10));
  }

  setValueInMap(key: string, val: number) {
    const m = this.scaleDownMap.getValue();
    const curr = m.get(key);
    if (!curr || (val < curr)) {
      m.set(key, val);
      this.scaleDownMap.next(m);
    }
  }

  clearMap() {
    this.scaleDownMap.next(new Map());
  }

}
