<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator"
  [calculateVirtualLabel]="true"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>
<div class="main-content">
  <div
    class="left-container"
    *ngIf="viewModel.strainText$ | push as strainType"
    [style.background-color]="viewModel.cardAccentColor$ | push">
    <div class="product-card-strain-type" [style.color]="viewModel.cardColor$ | push">
      {{ strainType | uppercase }}
    </div>
  </div>
  <div class="right-container">
    <div class="top-content">
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        appPrintCardScaleDownText
        class="product-card-name"
        [maxHeightInPx]="20"
        [maxWidthInPx]="254"
        [transparentWhileScaling]="true"
        [style.color]="viewModel.headerTextColor$ | push">
        {{ title | trimSafely }}
      </div>

      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
        appPrintCardScaleDownText
        class="product-card-brand"
        [maxHeightInPx]="16"
        [maxWidthInPx]="254"
        [transparentWhileScaling]="true"
        [style.color]="viewModel.headerTextColor$ | push">
        {{ brand | uppercase }}
      </div>
    </div>

    <div class="bottom-content">
      <div class="bottom-left-content">
        <img
          *ngFor="let variantBadge of data | printCardBadges"
          appImage
          class="product-card-badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
        <app-label-inflator
          class="print-card-label"
          [columnLabel]="false"
          [labelComponentInterface]="data"
          [reset]="false">
        </app-label-inflator>
      </div>
      <div class="bottom-right-content">
        <div
          *ngIf="
            viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) : true : false | async as size
          "
          class="product-card-size"
          [style.color]="viewModel.bodyTextColor$ | push">
          {{ size }}
        </div>
        <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | push">
          <app-column-primary-price
            [menu]="viewModel.cardStackMenu$ | push"
            [section]="viewModel.cardStack$ | push"
            [colVm]="viewModel.spoofedColViewModel$ | push"
            [rowVm]="viewModel.cardData$ | push"
            [locConfig]="viewModel.locationConfig$ | push"
            [locationId]="viewModel.locationId$ | push"
            [productStylingVm]="viewModel">
          </app-column-primary-price>
        </div>
      </div>
    </div>
  </div>
</div>
