import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardGridViewModel } from '../../card-grid/card-grid-view-model';
import { MarketingFooterComponent } from '../marketing-footer.component';
import type { Card } from '../../../../../../../../models/menu/product-menu-cards/card';
import type { MarketingComboMenu } from '../../../../../../../../models/menu/marketing/marketing-combo-menu';

@Component({
  selector: 'app-combo-footer',
  templateUrl: './combo-footer.component.html',
  providers: [CardGridViewModel],
})
export class ComboFooterComponent extends MarketingFooterComponent implements OnInit, OnChanges {

  constructor(
    public viewModel: CardGridViewModel
  ) {
    super();
  }

  @Input() menu: MarketingComboMenu;
  @Input() cards: Card[] = [];

  ngOnInit(): void {
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToCards(this.cards);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.cards) this.viewModel.connectToCards(this.cards);
  }

}
