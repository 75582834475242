import { Component, Input } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../../product-menu/building-blocks/menu-header/product-menu-header.component';
import type { CompanyConfiguration } from '../../../../../../../../models/company/dto/company-configuration';
import type { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';
import type { SpotlightMenu } from '../../../../../../../../models/menu/spotlight-menu';

@Component({
  selector: 'app-spotlight-header',
  templateUrl: './spotlight-header.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: SpotlightHeaderComponent }]
})
export class SpotlightHeaderComponent extends ProductMenuHeaderComponent {

  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: SpotlightMenu;
  @Input() reset: boolean;

}
