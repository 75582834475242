import { SpotlightMenu } from '../spotlight-menu';
import { Type } from '@angular/core';
import { ColWidth } from '../../shared/col-width';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SpotlightFooterComponent } from '../../../modules/display/components/menus/spotlight-menu/building-blocks/menu-footer/spotlight-footer/spotlight-footer.component';
import { EmptySpotlightFooterComponent } from '../../../modules/display/components/menus/spotlight-menu/building-blocks/menu-footer/empty-spotlight-footer/empty-spotlight-footer.component';
import { SortUtils } from '../../../utils/sort-utils';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class TopProductsSpotlightMenu extends SpotlightMenu {

  getThemeClass(): string {
    return 'top-products';
  }

  getFooterType(): Type<SpotlightFooterComponent> {
    return EmptySpotlightFooterComponent;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel, this.isLandscape() ? 8 : 13)
  ): SectionColumnViewModel[] {
    const vms = this.getThemeStandardizedColumnViewModels(sectionRowViewModels, rowViewModel, widths);
    const sortedColumnViewModels = vms?.sort(SortUtils.columnViewModelByOrdering);
    // TODO eventually apply column grouping to spotlight menu
    // let columnGroupings: ColumnGrouping[];
    // if (rowViewModel?.variantLineItemMode) {
    //   columnGroupings = rowViewModel?.menu?.getLineModeColumnGroupings(sortedColumnViewModels);
    // } else {
    //   columnGroupings = rowViewModel?.menu?.getGridModeColumnGroupings(sortedColumnViewModels);
    // }
    if (rowViewModel.variantLineItemMode) {
      const deleteIndex = sortedColumnViewModels.findIndex(it => it.columnType === SectionColumnProductInfoType.Size);
      if (deleteIndex > -1) {
        sortedColumnViewModels.splice(deleteIndex, 1);
      }
    }
    return sortedColumnViewModels
      .filter(it => it.columnType !== SectionColumnProductInfoType.Spacer)
      .filter(it => it.columnType !== SectionColumnProductInfoType.TinySpacer);
  }

}
