import { Deserializable } from '../../protocols/deserializable';
import { SectionThemeDefaultColumnKeys } from '../../enum/shared/section-theme-default-column-keys';
import type { SectionColumnConfigKey } from '../../enum/shared/section-column-config-key';
import type { SectionColumnConfigFontStyle } from '../../enum/shared/section-column-config-font-style';
import type { SectionColumnConfigDataValue } from '../../enum/shared/section-column-config-data-value';
import type { SectionColumnConfigState } from '../../enum/shared/section-column-config-state';
import { EnumUtils } from '../../../utils/enum-utils';

export class SectionColumnConfig implements Deserializable {

  public defaultState: SectionColumnConfigState;
  public columnName: string;
  public columnWidth: number;
  public dataValue: SectionColumnConfigDataValue;
  public fontStyle: SectionColumnConfigFontStyle;
  public fontColor: string = null;
  public columnColor: string = null;
  public columnOpacity: number = null;

  static mergeUserAndThemeColumnConfigs(
    userSpecifiedColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>,
    themeSpecifiedColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): Map<SectionColumnConfigKey, SectionColumnConfig> {
    const merged = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    const larger = userSpecifiedColumnConfig.size > themeSpecifiedColumnConfig.size
      ? userSpecifiedColumnConfig
      : themeSpecifiedColumnConfig;
    const terpeneKeys = EnumUtils.getTerpenesPascalCased();
    [...larger.keys()]?.forEach((key: SectionColumnConfigKey) => {
      const themeKey = terpeneKeys?.includes(key) ? SectionThemeDefaultColumnKeys.IndividualTerpenes : key;
      const user = userSpecifiedColumnConfig?.get(key);
      const theme = themeSpecifiedColumnConfig?.get(themeKey);
      const mergedConfig = SectionColumnConfig.merge(user, theme);
      merged.set(key, mergedConfig);
    });
    return merged;
  }

  static merge(user: SectionColumnConfig, theme: SectionColumnConfig): SectionColumnConfig {
    const result = new SectionColumnConfig();
    result.defaultState = user?.defaultState || theme?.defaultState;
    if (user?.columnName === '') {
      result.columnName = '';
    } else {
      result.columnName = user?.columnName || theme?.columnName || '';
    }
    result.columnWidth = user?.columnWidth || theme?.columnWidth;
    result.dataValue = user?.dataValue || theme?.dataValue;
    // FontStyle, FontColor, ColumnColor, ColumnOpacity are not included on theme defaults, so no fallback value
    result.fontStyle = user?.fontStyle;
    result.fontColor = user?.fontColor;
    result.columnColor = user?.columnColor;
    result.columnOpacity = user?.columnOpacity;
    return result;
  }

  onDeserialize() {
  }

}
