import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionWithProducts } from '../section/section-with-products';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { PriceUtils } from '../../../utils/price-utils';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class BandedMenu extends PrintMenu {

  getThemeClass(): string {
    return 'banded-theme';
  }

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'product-row-overlay' : '';
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.bebasNeueProBold,
      FONT.bebasNeueProRegular,
      FONT.myriadProBold,
      FONT.myriadProSemiBold,
      FONT.myriadProRegular
    ];
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    let decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    decimal = PriceUtils.removeTrailingZerosFromPriceString(decimal);
    return decimal;
  }

  getMakeHeaderSectionImageFillEntireSection(): boolean {
    return true;
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.Brand, 2],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(3),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(4),
      [SectionColumnProductInfoType.QuantityAndSize, 5],
      [SectionColumnProductInfoType.Size, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.VariantType, 8],
      [SectionColumnProductInfoType.Stock, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
      [SectionColumnProductInfoType.Badge, 12],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
