import { PaperSize } from '../../../../shared/paper-size';
import type { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { StiiizyCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-card-stack-content.component';
import { BusinessCardStack } from './business-card-stack';

export class StiiizyCardStack extends BusinessCardStack {

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return StiiizyCardStackContentComponent;
  }

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    // artificial white border around card, so background is included within content component
    return null;
  }

  getCardHeightInInches(): number {
    return 1.57;
  }

  getCardWidthInInches(): number {
    return 2.36;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 18;
  }

  nColumns(): number {
    return 3;
  }

  nRows(): number {
    return 6;
  }

  nonPerforatedBleedBorderInInches(): number {
    // artificial white border around card, so background is included within content component
    return 0;
  }

}
