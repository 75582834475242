import { ProductSection } from './product-section';
import { Menu } from '../menu';
import { Variant } from '../../product/dto/variant';
import { CopyableProductSection } from './copyable-product-section';
import { Product } from '../../product/dto/product';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import { exists } from '../../../functions/exists';

export class DoubleDutchProductSection extends ProductSection {

  public copyWithout(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection {
    const copy = window?.injector?.Deserialize?.instanceOf(DoubleDutchProductSection, this);
    variants.forEach(v => copy.removeVariant(v));
    copy.setUniqueIdentifier(menu, companyConfig, locationConfig);
    return copy;
  }

  public getCollapsedCopyWithMaxDuplicationOfVariant(
    product: Product,
    itemHeight: number,
    spaceLeft: number,
    firstOnPage: boolean,
    pageIndex: number
  ): [number, [number, CopyableProductSection]] {
    const getAnswer = super.getCollapsedCopyWithMaxDuplicationOfVariant;
    const answer = getAnswer(product, itemHeight, spaceLeft, firstOnPage, pageIndex);
    const [remainingSpace, [height, section]] = answer;
    let copiedSection = section;
    if (exists(copiedSection)) {
      copiedSection = window?.injector?.Deserialize?.instanceOf(DoubleDutchProductSection, section);
    }
    return [remainingSpace, [height, copiedSection]];
  }

  getBorderColor(menu: Menu): string {
    if (exists(this.metadata?.productsHeaderBackgroundColor)) {
      return this.metadata?.productsHeaderBackgroundColor;
    } else {
      return '#EBDCAF';
    }
  }

  getBorderRadius(menu: Menu): string {
    return (this.lastProductSectionOnPage) ? '0.7275rem 0.7275rem 0 0' : null;
  }

  getBottomBorderHeightInPx(): number {
    return 3.5;
  }

  getBottomBorder(menu: Menu): string {
    const border = `${this.getBottomBorderHeightInPx()}px solid ${this.metadata?.productsHeaderBackgroundColor}`;
    return !this.lastProductSectionOnPage ? border : null;
  }

}
