import { CardStack } from '../section/card-stacks/card-stack';
import { SectionType } from '../../enum/dto/section-type.enum';
import { Position } from '../../enum/shared/position.enum';
import { Section } from '../section/section';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { SortPrintCards } from '../../../utils/sort-print-cards';
import { EmptyCardData } from '../../print-cards/empty-card-data';
import { LaysOutCardsOnToPaperMenu } from './lays-out-cards-on-to-paper-menu';
import { SectionColumnConfigDataValue } from '../../enum/shared/section-column-config-data-value';
import type { CardData } from '../../print-cards/card-data';
import type { CardsThatLayoutOntoPaper } from '../section/cards-that-layout-onto-paper';
import type { SectionWithProducts } from '../section/section-with-products';

export abstract class PrintCardMenu extends LaysOutCardsOnToPaperMenu {

  public override template: PrintCardMenu;
  public override sections: CardStack[];
  public override templateSections: CardStack[];

  protected override deserializeSections(): void {
    this.sections?.forEach(section => this.setCardInfoDataOnSection(section));
    const sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.sections = sections?.filter((s: Section): s is CardStack => s instanceof CardStack);
  }

  protected override deserializeTemplateSections(): void {
    this.templateSections?.forEach(templateSection => this.setCardInfoDataOnSection(templateSection));
    const templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
    this.templateSections = templateSections?.filter((s: Section): s is CardStack => s instanceof CardStack);
  }

  protected forceSectionType(section: CardsThatLayoutOntoPaper) {
    section.sectionType = SectionType.CardStack;
  }

  getCardStack(): CardStack | null {
    return this.sections?.firstOrNull();
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSale(section: SectionWithProducts): boolean {
    return this.sections?.firstOrNull()?.priceType() === SectionColumnConfigDataValue.OriginalAndSalePrice;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Bottom;
  }

  /**
   * @param cards to be printed. They are already sorted by the criteria attached to the card stack object.
   * @param cardsPerPage is the number of cards should be printed on a single page. It can be 1 if singleCardPerPage
   * is true.
   * @param onPerforatedPaper
   * @returns a list of CardData[]. Each list of CardData represents the cards that will be printed on a single page.
   */
  cardGroupingsForEachPage(cards: CardData[], cardsPerPage: number, onPerforatedPaper: boolean): CardData[][] {
    return cards?.chunkedList(cardsPerPage) ?? [];
  }

  /**
   * This accounts for perforated and non-perforated paper.
   * On perforated paper, cards are grouped by strain type, and leave necessary blank space to avoid color bleeding.
   * On non-perforated paper, cards are sorted into strain type groups, and no blank space is left, because bleed
   * cutlines are used.
   */
  protected groupCardsForEachPageByStrainTypeGroupings(
    cards: CardData[],
    strainGroupings: StrainType[][],
    nCardsPerPage: number,
    onPerforatedPaper: boolean
  ): CardData[][] {
    // don't regroup if cards are all the same color - aka in this case productsInfoBackgroundColor would be set
    if (onPerforatedPaper && !cards?.firstOrNull()?.section?.metadata?.productsInfoBackgroundColor) {
      const chunkedData: CardData[][][] = [];
      const addStrainClassifiedChunk = (grouping: StrainType[]) => {
        const strainClassifiedCards = cards?.filter(card => grouping?.includes(card?.getStrainType()));
        chunkedData.push(strainClassifiedCards?.chunkedList(nCardsPerPage));
      };
      strainGroupings?.forEach(grouping => addStrainClassifiedChunk(grouping));
      const otherTypes = cards
        ?.filter(card => !strainGroupings?.some(group => group?.includes(card?.getStrainType())))
        ?.chunkedList(nCardsPerPage);
      chunkedData.push(otherTypes);
      return this.regroupCardGroupingToReduceBlankSpaceOnPerforatedPaper(chunkedData, nCardsPerPage);
    } else {
      return SortPrintCards.sortPrintCardsByStrainGroupings(cards, strainGroupings)?.chunkedList(nCardsPerPage) ?? [];
    }
  }

  protected regroupCardGroupingToReduceBlankSpaceOnPerforatedPaper(
    chunkedData: CardData[][][],
    nCardsPerPage: number
  ): CardData[][] {
    if (nCardsPerPage <= 1) return chunkedData?.flatMap(chunk => chunk);
    const cardStack = chunkedData?.[0]?.[0]?.[0]?.section;
    const nColumns = cardStack?.nColumns() ?? 0;
    let runningCardCountOnPage = 0;
    let pageNumber = 0;
    const updatedCardGroupingsPerPage: CardData[][] = [];
    const placeCardsOnPaper = (cards: CardData[]) => {
      if (!cards?.length) return;
      if (runningCardCountOnPage + (cards?.length ?? 0) <= nCardsPerPage) {
        updatedCardGroupingsPerPage[pageNumber] = [
          ...(updatedCardGroupingsPerPage[pageNumber] || []),
          ...(cards || [])
        ];
        runningCardCountOnPage += cards?.length ?? 0;
        if (runningCardCountOnPage >= nCardsPerPage) {
          pageNumber++;
          runningCardCountOnPage = 0;
        }
      } else {
        const nCardsCanFitOnPage = nCardsPerPage - runningCardCountOnPage;
        const [toAdd, remainingCards] = cards?.splitAt(nCardsCanFitOnPage) ?? [[], []];
        placeCardsOnPaper(toAdd);
        placeCardsOnPaper(remainingCards);
      }
    };
    const addEmptyCardsAfterwardsIfNeeded = (addEmptyCards: boolean) => {
      const currentPage = updatedCardGroupingsPerPage[pageNumber];
      if (runningCardCountOnPage > 0 && addEmptyCards) {
        const emptyCardsToAdded = this.getEmptyCards(currentPage, nCardsPerPage, nColumns);
        placeCardsOnPaper(emptyCardsToAdded);
      }
    };
    chunkedData?.forEach(chunk => {
      chunk?.forEach(cardGrouping => placeCardsOnPaper(cardGrouping));
      addEmptyCardsAfterwardsIfNeeded(true);
    });
    return updatedCardGroupingsPerPage;
  }

  protected getEmptyCards(currentPage: CardData[], nCardsPerPage: number, nColumns: number): EmptyCardData[] {
    const nMoreCardsCanFitOnPage = nCardsPerPage - currentPage?.length;
    const nEmptyCardsToAdd = nMoreCardsCanFitOnPage < nColumns
      ? nMoreCardsCanFitOnPage
      : nColumns;
    return [...Array(nEmptyCardsToAdd)].map(() => new EmptyCardData());
  }

  /** Columns start at 1. */
  protected getCurrentColumn(nthCardOnPage: number, nColumns: number): number {
    return ((nthCardOnPage - 1) % nColumns) + 1;
  }

  /** Rows start at 1. */
  protected getCurrentRow(nthCardOnPage: number, nColumns: number): number {
    return Math.floor((nthCardOnPage - 1) / nColumns) + 1;
  }

}
