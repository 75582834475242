import { SectionRowViewModel } from './SectionRowViewModel';
import { memoize } from '../../../../../../../../shared/decorators/memoize';

export class SectionRowViewModelBudSupply extends SectionRowViewModel {

  @memoize
  public getQuantityString(): string {
    return `${this.getQuantity()} pk`;
  }

}
