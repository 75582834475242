import { EnumUtils } from '../../../utils/enum-utils';
import { SectionColumnConfigCollectiveTerpeneKey } from './section-column-config-collective-terpene-key';

/**
 * Order matters here, since many places use Object.values(SectionColumnTerpeneType) to get terpenes.
 *
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export const SectionColumnTerpeneType = EnumUtils.createEnum([
  ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
  ...EnumUtils.getTerpenesPascalCased()
]);
