<div class="content-container">
  <app-label-inflator
    #labelCalculator
    hidden
    class="label-calculator label"
    [columnLabel]="false"
    [labelComponentInterface]="data"
    [reset]="false">
  </app-label-inflator>
  <div *ngIf="viewModel.showLeftSideLargeText$ | async" class="center-left-side-large-text">
    <div
      class="left-side-large-text"
      appScaleDownText
      [nPixelsPerStep]="8"
      [style.color]="viewModel.cardAccentColor$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ viewModel.leftSideLargeText$ | async }}
    </div>
  </div>
  <div class="left-side-large-text-spacer"></div>
  <div class="main-content" [style.gap.rem]="!(viewModel.isLineItemMode$ | async) ? 0.675 : null">
    <img
      *ngIf="data | getVariantAssetForCard | async as variantAsset"
      appImage
      class="card-image"
      [asset]="variantAsset"
      src=""
      alt="" />
    <div class="w100p">
      <div
        appPrintCardScaleDownText
        [maxHeightInPx]="64"
        [maxWidthInPx]="308"
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        class="product-name"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ viewModel.productName$ | async }}
      </div>
      <div
        *ngIf="viewModel.strainName$ | async as strainName"
        appPrintCardScaleDownText
        [maxHeightInPx]="16"
        [maxWidthInPx]="308"
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        class="strain-name"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ strainName }}
      </div>
    </div>
    <div class="size-divider">
      <div class="left-line" [style.background-color]="viewModel.cardAccentColor$ | async"></div>
      <div
        *ngIf="
          (viewModel.productSizeExists$ | async) &&
          !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
          (viewModel.isLineItemMode$ | async)
        "
        class="size"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) : false : false : false | async }}
      </div>
      <div class="right-line" [style.background-color]="viewModel.cardAccentColor$ | async"></div>
    </div>
    <div
      *ngIf="(viewModel.hasPropertiesBasedOnPools$ | async) || (viewModel.badgeOrLabelVisible$ | async)"
      class="content-attributes">
      <div
        #compounds
        *ngIf="viewModel.hasPropertiesBasedOnPools$ | async"
        class="cannabinoid-and-terpene-container with-border"
        [style.border-color]="viewModel.cardAccentColor$ | async">
        <div
          *rxFor="let data of viewModel.propertiesBasedOnPools$; strategy: 'immediate'"
          class="cannabinoid-or-terpene-container"
          [style.border-color]="viewModel.cardAccentColor$ | async">
          <div #header class="header" [style.background-color]="viewModel.cardAccentColor$ | async">
            {{ data?.title }}
          </div>
          <div
            class="compound-value-container"
            [style.max-width.px]="viewModel | popsCompoundMaxWidth: data | async | popsMaxCompoundWidth: header">
            <div
              appPrintCardScaleDownText
              [maxWidthInPx]="viewModel | popsCompoundMaxWidth: data | async | popsMaxCompoundWidth: header"
              [maxHeightInPx]="33"
              [adjustLineHeight]="false"
              [useFloatingPointPixels]="true"
              [decrementFontSizeByNPixelsPerStep]="0.25"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
              class="compound-value"
              [style.color]="viewModel.bodyTextColor$ | async">
              {{ data?.value }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="viewModel.badgeOrLabelVisible$ | async"
        #labelAndBadges
        class="badge-and-label-container"
        [style.flex-direction]="labelAndBadgesFlexDirection$ | async">
        <app-label-inflator
          *ngIf="labelCalculator.currentLabelText$ | async"
          class="label"
          [style.align-self]="labelSelfAlignment$ | async"
          [columnLabel]="false"
          [labelComponentInterface]="data"
          [reset]="false">
        </app-label-inflator>
        <div
          *ngIf="(data?.menu | printCardMenuBasicMethod: 'getNumberOfBadgesForVariant' : data) > 0"
          class="badge-container">
          <img
            *ngFor="let variantBadge of data | printCardBadges"
            appImage
            class="badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
      </div>
    </div>
    <app-pops-cannabis-regular-price
      *ngIf="viewModel.isLineItemMode$ | async; else multiVariantPricing"
      [colVm]="viewModel.spoofedColViewModel$ | async">
    </app-pops-cannabis-regular-price>
    <ng-template #multiVariantPricing>
      <div class="grid-container">
        <div
          *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let count = count; let i = index"
          class="size-container"
          [style.color]="viewModel.cardAccentColor$ | async">
          <ng-container *rxLet="viewModel | getExpandedGridSize: spoofedGridColVM : false : false : false; let size">
            <div *rxIf="count === 1 ? size : true" appLinkWidth class="header" [index]="i">
              {{ size }}
            </div>
          </ng-container>
          <div class="content">
            <app-pops-cannabis-regular-price [colVm]="spoofedGridColVM"> </app-pops-cannabis-regular-price>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
