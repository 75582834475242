import { PrintMenu } from '../print-menu';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnUtils } from '../../../utils/column-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionColumnType } from '../../enum/shared/section-column-type';

export class DougPrintMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getThemeClass(): string {
    return 'doug-print-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsRegular,
      FONT.poppinsSemiBold,
      FONT.poppinsBold,
    ];
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    return decimal.replace('.', '');
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Label, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantType, 10],
      [SectionColumnProductInfoType.Stock, 11],
      [SectionColumnProductInfoType.VariantPrice, 12],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 13],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
