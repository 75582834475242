/**
 * Order matters here, since many places use Object.values(SectionColumnCannabinoidType) to get cannabinoids.
 *
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export enum SectionColumnCannabinoidType {
  THC = 'THC',
  CBD = 'CBD',
  THCAndCBD = 'THCAndCBD',
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC',
}
