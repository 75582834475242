import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingComponent } from './loading/loading.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { BackgroundAssetComponent } from './background-asset/background-asset.component';
import { AssetComponent } from './asset/asset.component';
import { ScrollableItemDirective } from './directives/scrollable-item.directive';
import { ColumnPrimaryPriceComponent } from './components/column/column-primary-price/column-primary-price.component';
import { ColumnSecondaryPriceComponent } from './components/column/column-secondary-price/column-secondary-price.component';
import { ImageDirective } from './directives/image.directive';
import { VideoDirective } from './directives/video.directive';
import { AssetIsImagePipe } from './pipes/asset-is-image.pipe';
import { AssetIsVideoPipe } from './pipes/asset-is-video.pipe';
import { ColumnBackgroundColorPipe } from './pipes/column-background-color.pipe';
import { ForceColumnBoldStylingPipe } from './pipes/force-column-bold-styling.pipe';
import { ForceColumnBoldStylingIncludingSalePriceConfigurationPipe } from './pipes/force-column-bold-styling-including-sale-price-configuration.pipe';
import { ForceColumnItalicStylingPipe } from './pipes/force-column-italic-styling.pipe';
import { ForceColumnItalicStylingIncludingSalePriceConfigurationPipe } from './pipes/force-column-italic-styling-including-sale-price-configuration.pipe';
import { ForcedColumnTextColorPipe } from './pipes/forced-column-text-color.pipe';
import { ForcedColumnTextDecorationPipe } from './pipes/forced-column-text-decoration.pipe';
import { ForcedColumnZoomPipe } from './pipes/forced-column-zoom.pipe';
import { ForcedPriceColumnTextDecorationIncludingSaleConfigurationPipe } from './pipes/forced-price-column-text-decoration-including-sale-configuration.pipe';
import { ForcedPriceTextColorIncludingSaleConfigurationPipe } from './pipes/forced-price-text-color-including-sale-configuration.pipe';
import { GetOriginalPriceDecimalPipe } from './pipes/get-original-price-decimal.pipe';
import { GetOriginalPriceIntegerPipe } from './pipes/get-original-price-integer.pipe';
import { GetOriginalVariantPricingPipe } from './pipes/get-original-variant-pricing.pipe';
import { GetPriceDecimalPipe } from './pipes/get-price-decimal.pipe';
import { GetPriceIntegerPipe } from './pipes/get-price-integer.pipe';
import { GetSecondaryPriceDecimalPipe } from './pipes/get-secondary-price-decimal.pipe';
import { GetSecondaryPriceIntegerPipe } from './pipes/get-secondary-price-integer.pipe';
import { GetVariantAssetForCardPipe } from './pipes/get-variant-asset-for-card.pipe';
import { IsVariantPriceDiscountedPipe } from './pipes/is-variant-price-discounted.pipe';
import { ProductMenuBasicMethodPipe } from './pipes/product-menu-basic-method.pipe';
import { SectionRowViewModelBasicMethodPipe } from './pipes/section-row-view-model-basic-method.pipe';
import { PrimaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe } from './pipes/show-stacked-previous-price-if-on-sale.pipe';
import { SecondaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe } from './pipes/show-stacked-previous-secondary-price-if-on-sale.pipe';
import { LabelsModule } from '../labels/labels.module';
import { GetGridVariantThcPipe } from './pipes/get-grid-variant-thc.pipe';
import { GetGridVariantCbdPipe } from './pipes/get-grid-variant-cbd.pipe';
import { GetGridVariantQuantityPipe } from './pipes/get-grid-variant-quantity.pipe';
import { TrimSafelyPipe } from './pipes/trim-safely.pipe';
import { HasBackgroundAssetPipe } from './background-asset/has-background-asset.pipe';
import { ShouldShowDefaultImagePipe } from './background-asset/should-show-default-image.pipe';
import { ShouldShowDefaultVideoPipe } from './background-asset/should-show-default-video.pipe';
import { BackgroundAssetWrapperClassPipe } from './background-asset/background-asset-wrapper-class.pipe';
import { BackgroundBodyColorPipe } from './background-asset/background-body-color.pipe';
import { BackgroundColorOpacityPipe } from './background-asset/background-color-opacity.pipe';
import { BackgroundColorAssetBorderRadiusPipe } from './background-asset/background-color-asset-border-radius.pipe';
import { ShouldBlurBackgroundPipe } from './background-asset/should-blur-background.pipe';
import { ColumnQuantityAndSizeComponent } from './components/column/column-quantity-and-size/column-quantity-and-size.component';
import { ColumnQuantityComponent } from './components/column/column-quantity/column-quantity.component';
import { ColumnSizeComponent } from './components/column/column-size/column-size.component';
import { ColumnAssetComponent } from './components/column/column-asset/column-asset.component';
import { ColumnStrainTypeComponent } from './components/column/column-strain-type/column-strain-type.component';
import { ColumnProducerComponent } from './components/column/column-producer/column-producer.component';
import { ColumnBrandComponent } from './components/column/column-brand/column-brand.component';
import { ColumnTinySpacerComponent } from './components/column/column-tiny-spacer/column-tiny-spacer.component';
import { ColumnBadgeComponent } from './components/column/column-badge/column-badge.component';
import { ColumnLabelComponent } from './components/column/column-label/column-label.component';
import { ColumnWrapperComponent } from './components/column/column-wrapper/column-wrapper.component';
import { ShouldInflateColumnForRowPipe } from './components/column/column-wrapper/should-inflate-column-for-row.pipe';
import { ShouldHideColumnContentPipe } from './components/column/column-wrapper/should-hide-column-content.pipe';
import { LowAmountStylingPipe } from './pipes/low-amount-styling.pipe';
import { ColumnSpacerComponent } from './components/column/column-spacer/column-spacer.component';
import { AllVariantBadgesPipe } from './pipes/get-all-variant-badges.pipe';
import { ColumnNameComponent } from './components/column/column-name/column-name.component';
import { ColumnWidthPipe } from './components/column/column-wrapper/column-width.pipe';
import { HeaderColumnProductTitleComponent } from './components/header-column/header-column-product-title/header-column-product-title.component';
import { HeaderColumnVariantPriceComponent } from './components/header-column/header-column-variant-price/header-column-variant-price.component';
import { ScaleDownTextModule } from '../scale-down-text/scale-down-text.module';
import { IsMarketingMenuPipe } from './pipes/is-marketing-menu.pipe';
import { IsPrintMenuPipe } from './pipes/is-print-menu.pipe';
import { IsProductMenuPipe } from './pipes/is-product-menu.pipe';
import { IsProductMenuAndNotSpotlightPipe } from './pipes/is-product-menu-and-not-spotlight.pipe';
import { IsSpotlightMenuPipe } from './pipes/is-spotlight-menu.pipe';
import { IsInLineModePipe } from './pipes/is-in-line-mode.pipe';
import { HeaderColumnVariantSecondaryPriceComponent } from './components/header-column/header-column-variant-secondary-price/header-column-variant-secondary-price.component';
import { HeaderColumnQuantityAndSizeComponent } from './components/header-column/header-column-quantity-and-size/header-column-quantity-and-size.component';
import { HeaderColumnQuantityComponent } from './components/header-column/header-column-quantity/header-column-quantity.component';
import { HeaderColumnWrapperComponent } from './components/header-column/header-column-wrapper/header-column-wrapper.component';
import { GetShouldHideHeaderContentPipe } from './pipes/get-should-hide-header-content.pipe';
import { GetShouldInflateColumnHeaderForRowPipe } from './pipes/get-should-inflate-column-header-for-row.pipe';
import { HeaderColumnSizeComponent } from './components/header-column/header-column-size/header-column-size.component';
import { HeaderColumnStrainTypeComponent } from './components/header-column/header-column-strain-type/header-column-strain-type.component';
import { HeaderColumnAssetComponent } from './components/header-column/header-column-asset/header-column-asset.component';
import { HeaderColumnProducerComponent } from './components/header-column/header-column-producer/header-column-producer.component';
import { HeaderColumnBadgeComponent } from './components/header-column/header-column-badge/header-column-badge.component';
import { GetBadgeColumnTitlePipe } from './pipes/get-badge-column-title.pipe';
import { HeaderColumnBrandComponent } from './components/header-column/header-column-brand/header-column-brand.component';
import { HeaderColumnLabelComponent } from './components/header-column/header-column-label/header-column-label.component';
import { TextColorBasedOnSectionHeaderBackgroundColorPipe } from './pipes/text-color-based-on-section-header-background-color.pipe';
import { ColumnQuantityInStockComponent } from './components/column/column-quantity-in-stock/column-quantity-in-stock.component';
import { HeaderColumnQuantityInStockComponent } from './components/header-column/header-column-quantity-in-stock/header-column-quantity-in-stock.component';
import { LastProductSectionOnPagePipe } from './pipes/last-product-section-on-page.pipe';
import { ShimmerLoadingComponent } from './shimmer-loading/shimmer-loading.component';
import { IsDarkColorPipe } from './pipes/is-dark-color.pipe';
import { PrintMenuBasicMethodPipe } from './pipes/print-menu-basic-method.pipe';
import { DeprecatedMarketingMenuBasicMethodPipe } from './pipes/deprecated-marketing-menu-basic-method.pipe';
import { SectionBasicMethodPipe } from './pipes/section-basic-method.pipe';
import { InflatorModule } from '../inflator/inflator.module';
import { ContainsDashesPipe } from './pipes/contains-dashes.pipe';
import { GetExpandedGridSizePipe } from './pipes/get-expanded-grid-size.pipe';
import { RxLet } from '@rx-angular/template/let';
import { ModifyFinalPriceStringPipe } from './components/column/column-primary-price/modify-final-price-string.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { GetBadgesForPipe } from './pipes/get-badges-for.pipe';
import { IsLowAmountCannabinoidOrTACPipe } from './pipes/is-low-amount-cannabinoid.pipe';
import { HeaderColumnCannabinoidComponent } from './components/header-column/header-column-cannabinoid/header-column-cannabinoid.component';
import { IsLowAmountTerpeneOrTATPipe } from './pipes/is-low-amount-terpene.pipe';
import { HeaderColumnTerpeneComponent } from './components/header-column/header-column-terpene/header-column-terpene.component';
import { HideLabelColumnPipe } from './components/column/column-wrapper/hide-label-column.pipe';
import { GetVariantAssetForShelfTalkerPipe } from './pipes/get-variant-asset-for-shelf-talker.pipe';
import { ColumnVariantTypeComponent } from './components/column/column-variant-type/column-variant-type.component';
import { HeaderColumnVariantTypeComponent } from './components/header-column/header-column-variant-type/header-column-variant-type.component';

@NgModule({
  providers: [],
  declarations: [
    IsMarketingMenuPipe,
    IsPrintMenuPipe,
    IsProductMenuPipe,
    IsProductMenuAndNotSpotlightPipe,
    IsSpotlightMenuPipe,
    LoadingComponent,
    ProgressBarComponent,
    ProgressBarComponent,
    BackgroundAssetComponent,
    AssetComponent,
    ScrollableItemDirective,
    ColumnPrimaryPriceComponent,
    ColumnSecondaryPriceComponent,
    ImageDirective,
    VideoDirective,
    AssetIsImagePipe,
    AssetIsVideoPipe,
    ColumnBackgroundColorPipe,
    ForceColumnBoldStylingPipe,
    ForceColumnBoldStylingIncludingSalePriceConfigurationPipe,
    ForceColumnItalicStylingPipe,
    ForceColumnItalicStylingIncludingSalePriceConfigurationPipe,
    ForcedColumnTextColorPipe,
    ForcedColumnTextDecorationPipe,
    ForcedColumnZoomPipe,
    ForcedPriceColumnTextDecorationIncludingSaleConfigurationPipe,
    ForcedPriceTextColorIncludingSaleConfigurationPipe,
    GetOriginalPriceDecimalPipe,
    GetOriginalPriceIntegerPipe,
    GetOriginalVariantPricingPipe,
    GetPriceDecimalPipe,
    GetPriceIntegerPipe,
    GetSecondaryPriceDecimalPipe,
    GetSecondaryPriceIntegerPipe,
    GetVariantAssetForCardPipe,
    IsVariantPriceDiscountedPipe,
    ProductMenuBasicMethodPipe,
    PrintMenuBasicMethodPipe,
    SectionRowViewModelBasicMethodPipe,
    PrimaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe,
    SecondaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe,
    GetGridVariantThcPipe,
    GetGridVariantCbdPipe,
    GetGridVariantQuantityPipe,
    GetExpandedGridSizePipe,
    TrimSafelyPipe,
    HasBackgroundAssetPipe,
    ShouldShowDefaultImagePipe,
    ShouldShowDefaultVideoPipe,
    BackgroundAssetWrapperClassPipe,
    BackgroundBodyColorPipe,
    BackgroundColorOpacityPipe,
    BackgroundColorAssetBorderRadiusPipe,
    ShouldBlurBackgroundPipe,
    ColumnQuantityAndSizeComponent,
    ColumnQuantityComponent,
    ColumnSizeComponent,
    ColumnAssetComponent,
    ColumnStrainTypeComponent,
    ColumnProducerComponent,
    ColumnBrandComponent,
    ColumnTinySpacerComponent,
    ColumnBadgeComponent,
    ColumnLabelComponent,
    ColumnWrapperComponent,
    ShouldInflateColumnForRowPipe,
    ShouldHideColumnContentPipe,
    LowAmountStylingPipe,
    ColumnSpacerComponent,
    AllVariantBadgesPipe,
    ColumnNameComponent,
    ColumnWidthPipe,
    HeaderColumnProductTitleComponent,
    HeaderColumnVariantPriceComponent,
    IsInLineModePipe,
    HeaderColumnVariantSecondaryPriceComponent,
    HeaderColumnQuantityAndSizeComponent,
    HeaderColumnQuantityComponent,
    HeaderColumnWrapperComponent,
    GetShouldHideHeaderContentPipe,
    GetShouldInflateColumnHeaderForRowPipe,
    HeaderColumnSizeComponent,
    HeaderColumnStrainTypeComponent,
    HeaderColumnAssetComponent,
    HeaderColumnProducerComponent,
    HeaderColumnBadgeComponent,
    GetBadgeColumnTitlePipe,
    HeaderColumnBrandComponent,
    HeaderColumnLabelComponent,
    TextColorBasedOnSectionHeaderBackgroundColorPipe,
    ColumnQuantityInStockComponent,
    HeaderColumnQuantityInStockComponent,
    LastProductSectionOnPagePipe,
    ShimmerLoadingComponent,
    IsDarkColorPipe,
    DeprecatedMarketingMenuBasicMethodPipe,
    SectionBasicMethodPipe,
    ContainsDashesPipe,
    ModifyFinalPriceStringPipe,
    ReplacePipe,
    GetBadgesForPipe,
    ModifyFinalPriceStringPipe,
    HeaderColumnCannabinoidComponent,
    HeaderColumnTerpeneComponent,
    HideLabelColumnPipe,
    GetVariantAssetForShelfTalkerPipe,
    HeaderColumnVariantTypeComponent,
    ColumnVariantTypeComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    LabelsModule,
    ScaleDownTextModule,
    InflatorModule,
    RxLet,
    IsLowAmountCannabinoidOrTACPipe,
    IsLowAmountTerpeneOrTATPipe
  ],
  exports: [
    LabelsModule,
    IsMarketingMenuPipe,
    IsPrintMenuPipe,
    IsProductMenuPipe,
    IsProductMenuAndNotSpotlightPipe,
    IsSpotlightMenuPipe,
    LoadingComponent,
    ProgressBarComponent,
    BackgroundAssetComponent,
    AssetComponent,
    ScrollableItemDirective,
    ColumnPrimaryPriceComponent,
    ColumnSecondaryPriceComponent,
    ImageDirective,
    VideoDirective,
    AssetIsImagePipe,
    AssetIsVideoPipe,
    ColumnBackgroundColorPipe,
    ForceColumnBoldStylingPipe,
    ForceColumnBoldStylingIncludingSalePriceConfigurationPipe,
    ForceColumnItalicStylingPipe,
    ForceColumnItalicStylingIncludingSalePriceConfigurationPipe,
    ForcedColumnTextColorPipe,
    ForcedColumnTextDecorationPipe,
    ForcedColumnZoomPipe,
    ForcedPriceColumnTextDecorationIncludingSaleConfigurationPipe,
    ForcedPriceTextColorIncludingSaleConfigurationPipe,
    GetOriginalPriceDecimalPipe,
    GetOriginalPriceIntegerPipe,
    GetOriginalVariantPricingPipe,
    GetPriceDecimalPipe,
    GetPriceIntegerPipe,
    GetSecondaryPriceDecimalPipe,
    GetSecondaryPriceIntegerPipe,
    GetVariantAssetForCardPipe,
    IsVariantPriceDiscountedPipe,
    ProductMenuBasicMethodPipe,
    PrintMenuBasicMethodPipe,
    SectionRowViewModelBasicMethodPipe,
    PrimaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe,
    SecondaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe,
    GetGridVariantThcPipe,
    GetGridVariantCbdPipe,
    GetGridVariantQuantityPipe,
    TrimSafelyPipe,
    GetExpandedGridSizePipe,
    ColumnQuantityAndSizeComponent,
    ColumnQuantityComponent,
    ColumnSizeComponent,
    ColumnAssetComponent,
    ColumnStrainTypeComponent,
    ColumnWrapperComponent,
    ColumnProducerComponent,
    ColumnBrandComponent,
    ColumnSpacerComponent,
    ColumnTinySpacerComponent,
    ColumnBadgeComponent,
    ColumnLabelComponent,
    ShouldInflateColumnForRowPipe,
    AllVariantBadgesPipe,
    ColumnNameComponent,
    HeaderColumnProductTitleComponent,
    HeaderColumnVariantPriceComponent,
    HeaderColumnVariantSecondaryPriceComponent,
    HeaderColumnQuantityAndSizeComponent,
    HeaderColumnQuantityComponent,
    HeaderColumnWrapperComponent,
    HeaderColumnSizeComponent,
    HeaderColumnStrainTypeComponent,
    HeaderColumnAssetComponent,
    HeaderColumnProducerComponent,
    HeaderColumnBadgeComponent,
    HeaderColumnBrandComponent,
    HeaderColumnLabelComponent,
    ColumnQuantityInStockComponent,
    ColumnVariantTypeComponent,
    HeaderColumnQuantityInStockComponent,
    HeaderColumnVariantTypeComponent,
    IsInLineModePipe,
    GetShouldInflateColumnHeaderForRowPipe,
    GetShouldHideHeaderContentPipe,
    GetBadgeColumnTitlePipe,
    ShouldHideColumnContentPipe,
    ColumnWidthPipe,
    LowAmountStylingPipe,
    LastProductSectionOnPagePipe,
    IsDarkColorPipe,
    DeprecatedMarketingMenuBasicMethodPipe,
    SectionBasicMethodPipe,
    ReplacePipe,
    GetBadgesForPipe,
    SectionBasicMethodPipe,
    IsLowAmountCannabinoidOrTACPipe,
    IsLowAmountTerpeneOrTATPipe,
    HeaderColumnCannabinoidComponent,
    HeaderColumnTerpeneComponent,
    TextColorBasedOnSectionHeaderBackgroundColorPipe,
    GetVariantAssetForShelfTalkerPipe
  ],
})
export class SharedModule {
}
