@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/custom-cards-5x5/pops-cannabis-5-by-5-card-stack-content/pops-cannabis-price/pops-cannabis-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.content-container .center-left-side-large-text {
  position: absolute;
  left: calc(1rem * 1.2);
  top: 0;
  transform: rotate(-90deg) translateX(-105%);
  transform-origin: top left;
  display: block;
  min-width: 90%;
  max-width: 90%;
  min-height: calc((6rem - 1rem) * 1.2);
  max-height: calc((6rem - 1rem) * 1.2);
}
.content-container .center-left-side-large-text .left-side-large-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 95%;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
  line-height: 100%;
  font-family: rift-soft, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10rem;
  letter-spacing: 0.4rem;
  white-space: nowrap;
}
.content-container .left-side-large-text-spacer {
  width: calc(6rem * 1.2);
  height: 100%;
}
.content-container .main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 calc(1.5rem * 1.2);
  gap: calc(0.75rem * 1.2);
}
.content-container .main-content .card-image {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: calc(6rem * 1.2);
  align-self: center;
}
.content-container .main-content .product-name {
  width: 308px;
  max-height: 4rem;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: calc(1.5rem * 1.2);
  text-align: center;
  line-height: 110%;
  word-wrap: break-word;
  word-break: break-word;
}
.content-container .main-content .strain-name {
  width: 308px;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: calc(0.75rem * 1.2);
  text-align: center;
  line-height: 110%;
  word-wrap: break-word;
  word-break: break-word;
}
.content-container .main-content .size-divider {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.content-container .main-content .size-divider .left-line {
  height: 0.19rem;
  background-color: black;
  flex: 1;
}
.content-container .main-content .size-divider .size {
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.85rem * 1.2);
  letter-spacing: -0.0125rem;
  line-height: 90%;
  padding: 0 calc(0.5rem * 1.2);
}
.content-container .main-content .size-divider .right-line {
  height: 0.19rem;
  background-color: black;
  flex: 1;
}
.content-container .main-content .content-attributes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: calc(0.5rem * 1.2);
}
.content-container .main-content .content-attributes .cannabinoid-and-terpene-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  text-align: center;
}
.content-container .main-content .content-attributes .cannabinoid-and-terpene-container.with-border {
  border-radius: calc(0.25rem * 1.2);
  border: 3px solid black;
}
.content-container .main-content .content-attributes .cannabinoid-and-terpene-container .cannabinoid-or-terpene-container .header {
  color: white;
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.625rem * 1.2);
  line-height: 90%;
  letter-spacing: -0.0125rem;
  background-color: black;
  padding: calc(0.25rem * 1.2) calc(0.38rem * 1.2);
  background-color: black;
}
.content-container .main-content .content-attributes .cannabinoid-and-terpene-container .cannabinoid-or-terpene-container .compound-value-container {
  display: flex;
  align-items: center;
  height: 2rem;
  overflow: hidden;
}
.content-container .main-content .content-attributes .cannabinoid-and-terpene-container .cannabinoid-or-terpene-container .compound-value-container .compound-value {
  box-sizing: border-box;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: calc(1.125rem * 1.2);
  line-height: 100%;
  white-space: nowrap;
  padding: 0 0.3125rem;
}
.content-container .main-content .content-attributes .badge-and-label-container {
  display: flex;
  flex-direction: column;
  gap: calc(0.25rem * 1.2);
  align-items: center;
}
.content-container .main-content .content-attributes .badge-and-label-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
}
.content-container .main-content .content-attributes .badge-and-label-container .badge-container .badge {
  height: 2rem;
}
.content-container .main-content .grid-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(0.25rem * 1.2);
}
.content-container .main-content .grid-container .size-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
}
.content-container .main-content .grid-container .size-container.with-border {
  border-radius: calc(0.25rem * 1.2);
  border: 3px solid black;
}
.content-container .main-content .grid-container .size-container .header {
  color: white;
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.625rem * 1.2);
  line-height: 90%;
  letter-spacing: -0.0125rem;
  font-size: calc(0.725rem * 1.2);
  white-space: nowrap;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0.375rem;
}
.content-container .main-content .grid-container .size-container .content {
  flex: 1;
  align-self: center;
  padding: 0.25rem 0;
}
.content-container .label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
.content-container .label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: calc(0.625rem * 1.2);
  line-height: normal;
  border-radius: calc(0.125rem * 1.2);
  margin: 0;
  width: fit-content;
}
.content-container ::ng-deep .force-bold-style * {
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.content-container ::ng-deep .force-italic-style * {
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}
:host {
  display: contents;
}
.pricing-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.pricing-container .pre-tax {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: calc(0.65rem * 1.2);
}
.pricing-container .pre-tax .pre-tax-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.6625rem * 1.2);
  line-height: 90%;
  letter-spacing: -0.01125rem;
}
.pricing-container .tax-in {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: calc(0.65rem * 1.2);
}
.pricing-container .tax-in .tax-in-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.6625rem * 1.2);
  line-height: 90%;
  letter-spacing: -0.01125rem;
}
.pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-evenly;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: calc(1.25rem * 1.2);
  line-height: 100%;
  overflow: visible;
  width: unset;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
.pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: calc(0.1rem * 1.2);
}
.pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  text-decoration: unset;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: calc(0.75rem * 1.2);
  line-height: 116%;
}
.pricing-container ::ng-deep .product-variant-price .leading-text-current-price,
.pricing-container ::ng-deep .product-variant-price .leading-text-original-price-with-sale {
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: calc(0.7rem * 1.2);
  line-height: 90%;
  letter-spacing: -0.01125rem;
  align-self: center;
  margin-right: calc(0.25rem * 1.2);
}
.pricing-container ::ng-deep .product-variant-sale-price .product-variant-current-price {
  color: #ed1f24;
}
/*# sourceMappingURL=pops-cannabis-price.component.css.map */
