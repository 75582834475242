import { IndexCardStack } from './index-card-stack';
import type { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { FikaIndexCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/index-cards-3x5/fika-index-card-stack-background/fika-index-card-stack-background.component';
import { FikaIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-index-card-stack-content.component';

export class FikaIndexCardStack extends IndexCardStack {

  getCardHeightInInches(): number {
    return 3.08;
  }

  getCardWidthInInches(): number {
    return 5.12;
  }

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> {
    return FikaIndexCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return FikaIndexCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
