import { Injectable } from '@angular/core';
import { type CannabinoidOrTerpeneFromPool, PrintCardContentViewModel, type PropertyPoolSorter } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { StringUtils } from '../../../../../../../utils/string-utils';
import { Observable } from 'rxjs';
import { FikaBeverageCardData } from '../../../../../../../models/print-cards/fika-beverage-card-data';
import { SortUtils } from '../../../../../../../utils/sort-utils';

@Injectable()
export class FikaBeverageAddressCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<FikaBeverageCardData>;

  public override virtualLabelText$ = this._virtualLabelText.pipe(
    map(virtualLabelText => {
      const compare = StringUtils.removeWhiteSpace(virtualLabelText?.toLowerCase());
      if (compare?.includes('bigdeal') || compare?.includes('lastchance')) return virtualLabelText;
      return 'NOW';
    })
  );

  /**
   * Don't prioritize any properties for FIKA beverage cards.
   */
  override getPrimaryPoolOfProductProperties(): CannabinoidOrTerpeneFromPool[] {
    return [];
  }

  /**
   * Sort final property list from highest to lowest value, then by title.
   */
  override finalSortOnProductPropertiesInPoolLambda(): PropertyPoolSorter | null {
    return (a: CannabinoidOrTerpeneFromPool, b: CannabinoidOrTerpeneFromPool): number => {
      return SortUtils.parseFloatFromStringDescending(a?.value, b?.value)
          || SortUtils.stringAscending(a?.title, b?.title);
    };
  }

}
