import { CardData } from './card-data';
import { ProductType } from '../enum/dto/product-type.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { exists } from '../../functions/exists';
import { memoize } from '../../modules/shared/decorators/memoize';

export class AgentOrangeCardData extends CardData {

  @memoize
  public override getQuantityAndSizeString(): string {
    return super.getQuantityAndSizeString()?.toUpperCase();
  }

  public override getSize(): string {
    const v = this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return null;
      }
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
        switch (true) {
          case hasQty && hasSize:  return `${formattedUnitSize} x ${quantity}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case v?.productType === ProductType.Flower: {
        const quantity = v?.packagedQuantity;
        const qtyIsGreaterThanOne = quantity > 1;
        const formattedUnitSize = v?.getFormattedUnitSize();
        return qtyIsGreaterThanOne ? `${formattedUnitSize} x ${quantity}` : `${formattedUnitSize}`;
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        return quantity === 1 ? `${formattedUnitSize}` : `${formattedUnitSize} x ${quantity}`;
      }
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        return `${v?.packagedQuantity} pc`;
      }
      default: {
        return v?.getFormattedUnitSize();
      }
    }
  }

}
