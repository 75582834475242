import { BusinessCardStack } from './business-card-stack';
import type { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { HoneyHazeBusinessCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/business-cards-2x3/honey-haze-business-card-stack-content/honey-haze-business-card-stack-content.component';

export class HoneyHazeBusinessCardStack extends BusinessCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return null;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
   return HoneyHazeBusinessCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
