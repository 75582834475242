import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../../models/base/render-context-component';
import { GroupedByBrandShelfTalkerCardData } from '../../../../../../../../../models/shelf-talkers/grouped-by-brand-shelf-talker-card-data';
import type { VariantsGroupedByBrand } from '../../../../../../../models/variants-grouped-by-brand';
import type { ShelfTalkerCardSize } from '../../../../../../../../../models/enum/dto/shelf-talker-card-size';

@Component({
  selector: 'app-doug-shelf-talker-item',
  templateUrl: './doug-shelf-talker-item.component.html',
  styleUrls: ['./doug-shelf-talker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougShelfTalkerItemComponent extends RenderContextComponent {

  @Input() shelfTalkerCardData: GroupedByBrandShelfTalkerCardData;
  @Input() variantsGroupedByBrand: VariantsGroupedByBrand;
  @Input() cardSize: ShelfTalkerCardSize;
  @Input() viewingInLiveView: boolean;

  setupViews() {}
  setupBindings() {}

}
