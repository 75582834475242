<div class="doug-shelf-talker-item" [class.larger]="cardSize === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7">
  <div class="brand-logo-and-badges">
    <img
      *rxIf="
        shelfTalkerCardData | getVariantAssetForShelfTalker: variantsGroupedByBrand | push as asset;
        else noImage;
        strategy: 'immediate'
      "
      appImage
      class="brand-logo"
      [asset]="asset"
      alt="brand"
      src="" />
    <ng-template #noImage>
      <img class="brand-logo" [src]="variantsGroupedByBrand | dougBrandFallback" alt="fallback" />
    </ng-template>
    <ng-container *rxLet="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getBadges'; let variantBadges">
      <div *ngIf="variantBadges?.length > 0" class="badges">
        <img
          *ngFor="let variantBadge of variantBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
    </ng-container>
  </div>

  <div class="text-content" [style.color]="shelfTalkerCardData.getBodyTextColor()">
    <div
      *rxIf="!(shelfTalkerCardData | getVariantAssetForShelfTalker: variantsGroupedByBrand | push)"
      appPrintCardScaleDownText
      [maxHeightInPx]="23"
      [maxWidthInPx]="cardSize === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? 120 : 97"
      [transparentWhileScaling]="viewingInLiveView"
      class="brand-name">
      {{ variantsGroupedByBrand | variantGroupedByBrandMethod: 'getBrandName' }}
    </div>
    <div
      *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getAdditionalInfo' as additionalInfo"
      class="additional-info">
      {{ additionalInfo }}
    </div>
    <div *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getSizing' as sizing" class="sizing-info">
      {{ sizing }}
    </div>
    <div
      *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getPriceRange' : shelfTalkerCardData as pricing"
      class="price-info">
      {{ pricing }}
    </div>
    <app-label-inflator
      class="shelf-talker-label"
      [columnLabel]="false"
      [labelComponentInterface]="variantsGroupedByBrand"
      [reset]="false">
    </app-label-inflator>
  </div>
</div>
