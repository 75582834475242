// @dynamic
export class NumberUtils {

  private static floatComparisonToleranceValue = 0.000001; // not using epsilon because it is not recommended

  static isEven(i: number): boolean {
    return (i % 2) === 0;
  }

  static isOdd(i: number): boolean {
    return (i % 2) !== 0;
  }

  static secondsToMilli(n: number): number {
    return n * 1000;
  }

  static getIntegerSum(total: number, num: number) {
    return total + Math.round(num);
  }

  /** returns reduced version of input numerator and denominator: [reducedNumerator, reducedDenominator] */
  static reduceFraction(numerator, denominator) {
    const gcd = function greatestCommonDivisor(n, d): number {
      return d ? greatestCommonDivisor(d, n % d) : n;
    };
    const newDenominator = gcd(numerator, denominator);
    const reducedNumerator = numerator / newDenominator;
    const reducedDenominator = denominator / newDenominator;
    return [reducedNumerator, reducedDenominator];
  }

  static floatNumbersEqual(a: number, b: number) {
    return Math.abs(a - b) < NumberUtils.floatComparisonToleranceValue;
  }

  static floatFirstGreaterThanSecond(a: number, b: number) {
    return a - b - NumberUtils.floatComparisonToleranceValue > NumberUtils.floatComparisonToleranceValue;
  }

  static getDecimalSigFigs(n: number): number {
    if (Math.floor(n) === n) return 0;
    return n?.toString()?.split('.')?.[1]?.length || 0;
  }

  static formatToSigFigDecimals(n: number): string {
    const decSigFigs = NumberUtils.getDecimalSigFigs(n);
    return n.toFixed(decSigFigs);
  }

  static formatToMinSigFigDecimals(n: number, min: number): string {
    return n.toFixed(
      Math.max(1, (n.toString().split('.')[min] || []).length)
    );
  }

  /**
   * Rounds to two decimal places.
   *
   * JavaScript uses floating-point arithmetic, which can sometimes lead to small precision errors.
   * Adding Number.EPSILON ensures correct rounding behavior in edge cases.
   *
   * Example of a precision issue:
   * console.log(Math.round(1.005 * 100) / 100); // 1 instead of 1.01
   * console.log(Math.round((1.005 + Number.EPSILON) * 100) / 100); // 1.01 ✅
   */
  static roundToTwoDecimalPlaces(n: number): number {
    if (!Number.isFinite(n)) return n;
    return Math.round((n + Number.EPSILON) * 100) / 100;
  }

  static unique(list: number[]): number[] {
    return list?.filter((item, index) => list.indexOf(item) === index) || [];
  }

  /**
   * @returns a random integer between min (inclusive) and max (inclusive).
   */
  static randomIntegerWithin(min: number, max: number): number {
    return Math.floor(Math.random() * ((max - min) + 1)) + min;
  }

}
