import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import type { CardData } from '../../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-print-card-description-container',
  templateUrl: './print-card-description-container.component.html',
  styleUrls: ['./print-card-description-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintCardDescriptionContainerComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() cardData: CardData;
  @Input() bodyTextColor: string;

  setupBindings() {
  }

  setupViews() {
  }

}
