import { VariantTypeUtils } from './variant-type-utils';
import { ProductType } from '../models/enum/dto/product-type.enum';
import { VariantType } from '../models/enum/dto/variant-type.enum';
import type { VariantAsset } from '../models/image/dto/variant-asset';
import type { JaneDoeCardData } from '../models/print-cards/jane-doe-card-data';
import type { SessionsCardData } from '../models/print-cards/sessions-card-data';
import type { CardData } from '../models/print-cards/card-data';
import type { LabelData } from '../models/print-labels/label-data';

// @dynamic
export class AssetUtils {

  static readonly productTypeUrls = {
    [ProductType.Accessories]: 'assets/images/product-category/accessories.png',
    [ProductType.Beverage]: 'assets/images/product-category/beverage.png',
    [ProductType.Concentrate]: 'assets/images/product-category/concentrate.png',
    [ProductType.Edible]: 'assets/images/product-category/edible.png',
    [ProductType.Flower]: 'assets/images/product-category/flower.png',
    [ProductType.Oil]: 'assets/images/product-category/oil.png',
    [ProductType.Other]: 'assets/images/product-category/other.png',
    [ProductType.Seed]: 'assets/images/product-category/seed.png',
    [ProductType.Vape]: 'assets/images/product-category/vape.png',
    [ProductType.Wellness]: 'assets/images/product-category/wellness.png'
  };

  static budSenseProductTypeUrlsFromCardData(cardData: CardData): string | null {
    const variantType = cardData?.rowVariantType();
    const productType = cardData?.rowProductType();
    return AssetUtils.budSenseProductTypeUrls(productType, variantType);
  }

  static budSenseProductTypeUrls(productType: ProductType, variantType: VariantType): string | null {
    switch (productType) {
      case ProductType.Accessories:
        return AssetUtils.budSenseAccessoriesVariantTypeUrls(variantType);
      case ProductType.Beverage:
        return AssetUtils.budSenseBeverageVariantTypeUrls(variantType);
      case ProductType.Concentrate:
        return AssetUtils.budSenseConcentrateVariantTypeUrls(variantType);
      case ProductType.Edible:
        return AssetUtils.budSenseEdibleVariantTypeUrls(variantType);
      case ProductType.Flower:
        return AssetUtils.budSenseFlowerVariantTypeUrls(variantType);
      case ProductType.Oil:
        return AssetUtils.budSenseOilVariantTypeUrls(variantType);
      case ProductType.Other:
        return AssetUtils.budSenseOtherVariantTypeUrls(variantType);
      case ProductType.Seed:
        return AssetUtils.budSenseSeedVariantTypeUrls(variantType);
      case ProductType.Vape:
        return AssetUtils.budSenseVapeVariantTypeUrls(variantType);
      case ProductType.Wellness:
        return AssetUtils.budSenseWellnessVariantTypeUrls(variantType);
      default:
        return 'assets/images/defaultHemp.svg';
    }
  }

  private static budSenseAccessoriesVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Battery:
        return 'assets/images/budsense/battery.png';
      case VariantType.Bong:
        return 'assets/images/budsense/bong.png';
      case VariantType.Book:
        return 'assets/images/budsense/book.png';
      case VariantType.Cleaning:
        return 'assets/images/budsense/cleaning.png';
      case VariantType.Grinder:
        return 'assets/images/budsense/grinder.png';
      case VariantType.Kitchenware:
        return 'assets/images/budsense/kitchenware.png';
      case VariantType.Lighter:
        return 'assets/images/budsense/lighter.png';
      case VariantType.Pipe:
        return 'assets/images/budsense/pipe.png';
      case VariantType.Storage:
        return 'assets/images/budsense/storage.png';
      case VariantType.Tray:
        return 'assets/images/budsense/tray.png';
      default:
        return 'assets/images/budsense/accessories.png';
    }
  }

  private static budSenseBeverageVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Dissolvable:
        return 'assets/images/budsense/dissolvable.png';
      case VariantType.Distilled:
        return 'assets/images/budsense/distilled.png';
      case VariantType.Tea:
        return 'assets/images/budsense/tea.png';
      default:
        return 'assets/images/budsense/drinks.png';
    }
  }

  private static budSenseConcentrateVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/images/budsense/concentrates.png';
    }
  }

  private static budSenseEdibleVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.HardCandy:
        return 'assets/images/budsense/candy.png';
      default:
        return 'assets/images/budsense/edibles.png';
    }
  }

  private static budSenseFlowerVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.BulkFlower:
      case VariantType.InfusedBulkFlower:
        return 'assets/images/budsense/big-bags.png';
      case VariantType.PreRoll:
      case VariantType.InfusedPreRoll:
      case VariantType.InfusedBlunt:
      case VariantType.Blunt:
        return 'assets/images/budsense/pre-rolls.png';
      default:
        return 'assets/images/budsense/flower.png';
    }
  }

  private static budSenseOilVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Capsule:
        return 'assets/images/budsense/capsules.png';
      default:
        return 'assets/images/budsense/oil.png';
    }
  }

  private static budSenseOtherVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Apparel:
        return 'assets/images/budsense/apparel.png';
      case VariantType.Bundle:
        return 'assets/images/budsense/bundle.png';
      case VariantType.GiftCard:
        return 'assets/images/budsense/gift-card.png';
      case VariantType.Merch:
        return 'assets/images/budsense/merch.png';
      case VariantType.Pet:
        return 'assets/images/budsense/pet.png';
      case VariantType.Sample:
        return 'assets/images/budsense/sample.png';
      default:
        return 'assets/images/budsense/other.png';
    }
  }

  private static budSenseSeedVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/images/budsense/seed.png';
    }
  }

  private static budSenseVapeVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/images/budsense/vapes.png';
    }
  }

  private static budSenseWellnessVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Bath:
        return 'assets/images/budsense/bath.png';
      case VariantType.CreamAndLotion:
        return 'assets/images/budsense/cream.png';
      case VariantType.Lubricant:
        return 'assets/images/budsense/lubricant.png';
      case VariantType.RollOn:
        return 'assets/images/budsense/roll-on.png';
      case VariantType.SkinCare:
        return 'assets/images/budsense/skincare.png';
      case VariantType.Suppository:
        return 'assets/images/budsense/suppository.png';
      case VariantType.Transdermal:
        return 'assets/images/budsense/transdermal.png';
      case VariantType.Hemp:
        return 'assets/images/budsense/hemp.png';
      default:
        return 'assets/images/budsense/wellness.png';
    }
  }

  static BaseInventoryLabelProductTypeUrls(labelData: LabelData): string | null {
    return AssetUtils.budSenseProductTypeUrlsFromCardData(labelData);
  }

  static janeDoeProductTypeUrls(cardData: JaneDoeCardData): string | null {
    return AssetUtils.budSenseProductTypeUrlsFromCardData(cardData);
  }

  static sessionsProductTypeUrls(cardData: SessionsCardData): string {
    const variantType = cardData?.rowVariantType();
    const productType = cardData?.rowProductType();
    switch (true) {
      case VariantTypeUtils.isCapsuleType(variantType):
        return 'assets/images/sessions/capsules.png';
      case VariantTypeUtils.isPreRollType(variantType):
        return 'assets/images/sessions/pre-roll.png';
      case VariantTypeUtils.isFlowerByGramType(variantType):
        return 'assets/images/sessions/flower.png';
      case productType === ProductType.Accessories:
        return 'assets/images/sessions/accessories.png';
      case productType === ProductType.Beverage:
        return 'assets/images/sessions/beverage.png';
      case productType === ProductType.Concentrate:
        return 'assets/images/sessions/concentrates.png';
      case productType === ProductType.Edible:
        return 'assets/images/sessions/edibles.png';
      case productType === ProductType.Seed:
        return 'assets/images/sessions/seeds.png';
      case productType === ProductType.Vape:
        return 'assets/images/sessions/vape.png';
      default:
        return 'assets/images/sessions/sessions-icon.png';
    }
  }

  static batchVariantIds = (variantIds: string[]): string[][] => {
    const maxBatchSize = 50; // Current maximum batch size
    const batchedVariantIds: string[][] = [];
    if (variantIds.length > maxBatchSize) {
      for (let i = 0; i < variantIds.length; i += maxBatchSize) {
        batchedVariantIds.push(variantIds.slice(i, i + maxBatchSize));
      }
    } else {
      batchedVariantIds.push(variantIds);
    }
    return batchedVariantIds;
  };

  static flattenVariantMaps = (assetMaps: Map<string, VariantAsset[]>[]) => {
    const flattenedMap = new Map<string, VariantAsset[]>();
    assetMaps?.forEach(assetMap => {
      [...(assetMap?.entries() || [])]?.forEach(([key, value]) => flattenedMap.set(key, value));
    });
    return flattenedMap;
  };

}
