import { VariantAssetService } from '../../modules/services/variant-asset-service';
import type { ProductMenu } from '../menu/product-menu';
import type { SectionWithProducts } from '../menu/section/section-with-products';
import type { SectionRowViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { LocationConfiguration } from '../company/dto/location-configuration';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of, switchMap, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { RowBackgroundColorSetBy } from '../enum/shared/row-backgound-color-set-by.enum';
import { ColorUtils } from '../../utils/color-utils';
import { BandedRowColorContrast } from '../enum/shared/banded-row-color-contrast.enum';
import type { VariantAsset } from '../image/dto/variant-asset';
import { SortUtils } from '../../utils/sort-utils';
import { DistinctUtils } from '../../utils/distinct.utils';
import { AssetUtils } from '../../utils/asset-utils';
import { BaseViewModel } from '../base/base-view-model';
import { exists } from '../../functions/exists';
import type { LocationPriceStream } from '../enum/shared/location-price-stream';
import { SectionUtils } from '../../utils/section-utils';
import { Injectable } from '@angular/core';
import { SectionColumnConfigFontStyle } from '../enum/shared/section-column-config-font-style';
import { SectionColumnProductInfoType } from '../enum/shared/section-column-product-info-type';
import type { SectionColumnViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Injectable()
export class ProductStylingViewModel extends BaseViewModel {

  constructor(
    protected variantAssetService: VariantAssetService
  ) {
    super();
  }

  protected static isPriceColumnAndDiscounted(
    menu: ProductMenu,
    section: SectionWithProducts,
    sectionRowVm: SectionRowViewModel,
    sectionColVm: SectionColumnViewModel,
    locationConfig: LocationConfiguration,
    overridePriceStream?: LocationPriceStream
  ): boolean {
    const priceFormat = overridePriceStream || locationConfig?.priceFormat;
    const locationId = locationConfig?.locationId;
    const isPrimaryPrice = sectionColVm?.columnType === SectionColumnProductInfoType.VariantPrice;
    const isSecondaryPrice = sectionColVm?.columnType === SectionColumnProductInfoType.VariantSecondaryPrice;
    const isPrice = (isPrimaryPrice || isSecondaryPrice);
    return isPrice && menu?.isVariantPriceDiscounted(priceFormat, locationId, section, sectionRowVm, sectionColVm);
  }

  protected readonly _calculationMode = new BehaviorSubject<boolean>(true);
  public readonly calculationMode$ = this._calculationMode as Observable<boolean>;
  connectToCalculationMode(calculationMode: boolean): void { this._calculationMode.next(calculationMode); }

  protected readonly _reset = new BehaviorSubject<boolean>(false);
  public readonly reset$ = this._reset as Observable<boolean>;
  connectToReset(reset: boolean): void { this._reset.next(reset); }

  protected _menu = new BehaviorSubject<ProductMenu>(null);
  public menu$ = this._menu as Observable<ProductMenu>;
  connectToMenu = (menu: ProductMenu) => this._menu.next(menu);

  protected _section = new BehaviorSubject<SectionWithProducts>(null);
  public section$ = this._section as Observable<SectionWithProducts>;
  connectToSection = (section: SectionWithProducts) => this._section.next(section);

  protected _sectionRowViewModels = new BehaviorSubject<SectionRowViewModel[]>(null);
  protected sectionRowViewModels$ = this._sectionRowViewModels as Observable<SectionRowViewModel[]>;
  connectToSectionRowViewModels = (vms: SectionRowViewModel[]) => this._sectionRowViewModels.next(vms);

  protected _rowViewModel = new BehaviorSubject<SectionRowViewModel>(null);
  public rowViewModel$ = this._rowViewModel as Observable<SectionRowViewModel>;
  connectToRowViewModel = (vm: SectionRowViewModel) => this._rowViewModel.next(vm);

  protected _labelText = new BehaviorSubject<string>(null);
  public labelText$ = this._labelText as Observable<string>;
  connectToLabelText = (text: string) => this._labelText.next(text);

  protected _virtualLabelText = new BehaviorSubject<string>(null);
  public virtualLabelText$ = this._virtualLabelText as Observable<string>;
  connectToVirtualLabelText = (text: string) => this._virtualLabelText.next(text);

  protected _odd = new BehaviorSubject<boolean>(false);
  public odd$ = this._odd as Observable<boolean>;
  connectToOdd = (odd: boolean) => this._odd.next(odd);

  public readonly checkForPriceChange$ = combineLatest([
    this.calculationMode$,
    this.reset$,
  ]).pipe(
    switchMap(([calculationMode, reset]) => {
      const minute = 1000 * 60;
      const checkForPriceChange$ = defer(() => timer(0, minute).pipe(map(i => i % 2 === 0)));
      return iif(() => reset || calculationMode, of(true), checkForPriceChange$);
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public lowAmountStyling = (
    columnViewModel: SectionColumnViewModel,
    lowAmount$: Observable<boolean>
  ): Observable<any> => {
    return combineLatest([
      (lowAmount$ || of(false)),
      this.menu$,
      this.section$,
      this.rowViewModel$
    ]).pipe(
      map(([isLowAmount, menu, section, rowViewModel]) => {
        return isLowAmount ? menu?.getLowAmountStyling(section, rowViewModel, columnViewModel) : null;
      })
    );
  };

  public columnViewModels$ = combineLatest([
    this.menu$,
    this.rowViewModel$,
    this.sectionRowViewModels$
  ]).pipe(
    map(([menu, rowViewModel, sectionRowViewModels]) => {
      return menu?.getThemeSpecifiedColumnViewModels(sectionRowViewModels, rowViewModel);
    })
  );

  public productWrappingStyle$ = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$,
    this.odd$
  ]).pipe(
    map(([menu, section, rowViewModel, odd]) => {
      return menu?.getProductWrapperStyling(section, rowViewModel, odd);
    })
  );

  /* **************************** Font Styling **************************** */

  public forcedRowBoldStyling$ = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$
  ]).pipe(
    map(([menu, section, rowViewModel]) => section?.forceRowBoldStyling(menu, rowViewModel, null)),
    distinctUntilChanged()
  );

  public forcedRowItalicStyling$ = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$
  ]).pipe(
    map(([menu, section, rowViewModel]) => section?.forceRowItalicStyling(menu, rowViewModel, null)),
    distinctUntilChanged()
  );

  public forceColumnBoldStyling = (
    columnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration
  ): Observable<boolean> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
    ]).pipe(
      map(([menu, section, rowViewModel]) => section?.forceColumnBoldStyling(menu, rowViewModel, columnViewModel)),
      distinctUntilChanged()
    );
  };

  /**
   * Only use this on price columns.
   */
  public forceColumnBoldStylingIncludingSalePriceConfiguration = (
    colVM: SectionColumnViewModel,
    locationConfig: LocationConfiguration,
    overridePriceStream?: LocationPriceStream
  ): Observable<boolean> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
      this.checkForPriceChange$
    ]).pipe(
      map(([menu, section, rowVM]) => {
        const discounted = ProductStylingViewModel.isPriceColumnAndDiscounted;
        const isDiscountedPriceColumn = discounted(menu, section, rowVM, colVM, locationConfig, overridePriceStream);
        const isActiveSalePriceFontStyle = locationConfig?.salePriceFontStyle !== SectionColumnConfigFontStyle.Normal;
        return (isDiscountedPriceColumn && isActiveSalePriceFontStyle)
          ? locationConfig?.salePriceFontStyle === SectionColumnConfigFontStyle.Bold
          : section?.forceColumnBoldStyling(menu, rowVM, colVM);
      }),
      distinctUntilChanged()
    );
  };

  public forceColumnItalicStyling = (
    columnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration
  ): Observable<boolean> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
    ]).pipe(
      map(([menu, section, rowViewModel]) => section?.forceColumnItalicStyling(menu, rowViewModel, columnViewModel)),
      distinctUntilChanged()
    );
  };

  /**
   * Only use this on price columns.
   */
  public forceColumnItalicStylingIncludingSalePriceConfiguration = (
    colVM: SectionColumnViewModel,
    locationConfig: LocationConfiguration,
    overridePriceStream?: LocationPriceStream
  ): Observable<boolean> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
      this.checkForPriceChange$
    ]).pipe(
      map(([menu, section, rowVM]) => {
        const discounted = ProductStylingViewModel.isPriceColumnAndDiscounted;
        const isDiscountedPriceColumn = discounted(menu, section, rowVM, colVM, locationConfig, overridePriceStream);
        const isActiveSalePriceFontStyle = locationConfig?.salePriceFontStyle !== SectionColumnConfigFontStyle.Normal;
        return (isDiscountedPriceColumn && isActiveSalePriceFontStyle)
          ? locationConfig?.salePriceFontStyle === SectionColumnConfigFontStyle.Italics
          : section?.forceColumnItalicStyling(menu, rowVM, colVM);
      }),
      distinctUntilChanged()
    );
  };

  public forcedColumnTextDecoration = (
    columnVM: SectionColumnViewModel,
    locConfig: LocationConfiguration
  ): Observable<string> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
    ]).pipe(
      map(([menu, sec, rowVM]) => sec?.forcedColumnTextDecoration(menu, rowVM, columnVM))
    );
  };

  /**
   * Only use this on price columns.
   */
  public forcedPriceColumnTextDecorationIncludingSaleConfiguration = (
    columnVM: SectionColumnViewModel,
    locConfig: LocationConfiguration,
    overridePriceStream?: LocationPriceStream
  ): Observable<string> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
      this.checkForPriceChange$
    ]).pipe(
      map(([menu, sec, rowVM]) => {
        // ignore styling if price is empty
        const priceStream = overridePriceStream || locConfig?.priceFormat;
        const locId = locConfig?.locationId;
        const priceInt = () => menu?.getPriceInteger(priceStream, locId, sec, rowVM, columnVM);
        const secondaryPriceInt = () => menu?.getSecondaryPriceInteger(priceStream, locId, sec, rowVM, columnVM);
        const calculatePriceInt = {
          [SectionColumnProductInfoType.VariantPrice]: priceInt,
          [SectionColumnProductInfoType.VariantSecondaryPrice]: secondaryPriceInt
        };
        const ignoreStyling = calculatePriceInt?.[columnVM?.columnType]?.() === '-';
        const discounted = ProductStylingViewModel.isPriceColumnAndDiscounted;
        const isDiscounted = !ignoreStyling && discounted(menu, sec, rowVM, columnVM, locConfig, overridePriceStream);
        const forcedColumTextDecoration = ignoreStyling ? null : sec?.forcedColumnTextDecoration(menu, rowVM, columnVM);
        const salePriceFontStyleIsSet = locConfig?.salePriceFontStyle !== SectionColumnConfigFontStyle.Normal;
        return isDiscounted && salePriceFontStyleIsSet
          ? SectionUtils.getColumnTextDecoration(locConfig?.salePriceFontStyle)
          : forcedColumTextDecoration;
      })
    );
  };

  /* **************************** Font Colors **************************** */

  /**
   * explicitly set row text color with body text color to override any scss styling set within theme
   */
  public forcedSectionRowTextColor$ = combineLatest([
    this.menu$,
    this.section$
  ]).pipe(
    map(([menu, section]) => menu?.getSectionBodyTextColor(section)),
    distinctUntilChanged()
  );

  public forcedRowTextColor$ = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$
  ]).pipe(
    map(([menu, section, rowViewModel]) => section?.forcedRowTextColor(menu, rowViewModel)),
    distinctUntilChanged()
  );

  public forcedRowTextColorToDisplay$ = combineLatest([
    this.forcedSectionRowTextColor$,
    this.forcedRowTextColor$,
  ]).pipe(
    map(([sectionTextColor, forcedRowTextColor]) => forcedRowTextColor || sectionTextColor),
    distinctUntilChanged()
  );

  public forcedColumnTextColor = (
    columnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration
  ): Observable<string> => {
    return combineLatest([
      this.forcedRowTextColor$,
      this.menu$,
      this.section$,
      this.rowViewModel$,
    ]).pipe(
      map(([forcedRowTextColor, menu, section, rowViewModel]) => {
        return !forcedRowTextColor
          ? section?.forcedColumnTextColor(menu, rowViewModel, columnViewModel)
          : null;
      }),
      distinctUntilChanged()
    );
  };

  /**
   * Only use this on price columns.
   */
  public forcedPriceTextColorIncludingSaleConfiguration = (
    colVM: SectionColumnViewModel,
    locationConfig: LocationConfiguration,
    overridePriceStream?: LocationPriceStream
  ): Observable<string> => {
    return combineLatest([
      this.forcedRowTextColor$,
      this.menu$,
      this.section$,
      this.rowViewModel$,
      this.checkForPriceChange$
    ]).pipe(
      map(([forcedRowTextColor, menu, section, rowVM]) => {
        const discounted = ProductStylingViewModel.isPriceColumnAndDiscounted;
        const isDiscounted = discounted(menu, section, rowVM, colVM, locationConfig, overridePriceStream);
        switch (true) {
          case isDiscounted && exists(locationConfig?.salePriceFontColor):
            return locationConfig?.salePriceFontColor;
          case !forcedRowTextColor:
            return section?.forcedColumnTextColor(menu, rowVM, colVM);
          default:
            return null;
        }
      }),
      distinctUntilChanged()
    );
  };

  /* **************************** Background Colors **************************** */

  protected getBandedRowColor(menu: ProductMenu, rowViewModel: SectionRowViewModel, odd: boolean): string | null {
    return menu?.bandedRowColor(rowViewModel, odd);
  }

  public sectionBorderColor$ = combineLatest([
    this.menu$,
    this.section$,
  ]).pipe(
    map(([menu, section]) => section?.getBorderColor(menu)),
    distinctUntilChanged()
  );

  public rowClassificationColor$ = combineLatest([
    this.menu$,
    this.rowViewModel$,
    this.odd$
  ]).pipe(
    map(([menu, rowViewModel, odd]) => menu?.getRowColorFromStrainType(odd, rowViewModel))
  );

  public rowBandedColor$ = combineLatest([
    this.menu$,
    this.rowViewModel$,
    this.odd$
  ]).pipe(
    map(([menu, rowViewModel, odd]) => this.getBandedRowColor(menu, rowViewModel, odd))
  );

  public rowBackgroundColor$: Observable<[RowBackgroundColorSetBy, string]> = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$,
    this.rowClassificationColor$,
    this.rowBandedColor$,
    this.odd$
  ]).pipe(
    map(([menu, section, rowViewModel, classificationColor, bandedColor, odd]) => {
      const forcedColor = section?.forceRowBackgroundColor(menu, rowViewModel);
      const rowColor = forcedColor || bandedColor || classificationColor;
      const interceptedRowColor = menu?.interceptLineItemRowBackgroundColor(rowColor, odd, rowViewModel);
      switch (true) {
        case exists(forcedColor):
          return [RowBackgroundColorSetBy.Forced, interceptedRowColor] as [RowBackgroundColorSetBy, string];
        case exists(bandedColor):
          return [RowBackgroundColorSetBy.Banded, interceptedRowColor] as [RowBackgroundColorSetBy, string];
        case exists(classificationColor):
          return [RowBackgroundColorSetBy.Classification, interceptedRowColor] as [RowBackgroundColorSetBy, string];
        default:
          return [null, null] as [RowBackgroundColorSetBy, string];
      }
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public columnBackgroundColor = (columnViewModel: SectionColumnViewModel): Observable<string> => {
    return combineLatest([
      this.rowBackgroundColor$,
      this.menu$,
      this.section$,
      this.rowViewModel$,
      combineLatest([this.rowClassificationColor$, this.rowBandedColor$]),
      this.odd$
    ]).pipe(
      map(([[rowSetBy, rowColor], menu, section, rowViewModel, [classificationColor, bandedColor], odd]) => {
        let columnColor = null;
        const rowColorIsClassificationColor = rowSetBy === RowBackgroundColorSetBy.Classification;
        const rowColorIsBandedColor = rowSetBy === RowBackgroundColorSetBy.Banded;
        if (!rowColor || rowColorIsClassificationColor || rowColorIsBandedColor) {
          const rowIsBanded = menu.currentRowIsBanded(odd);
          columnColor = this.forcedColumnBackgroundColor(menu, section, rowViewModel, columnViewModel, rowIsBanded);
          if (exists(classificationColor)) {
            const rowAlpha = ColorUtils.rgbaGetAlphaOrNull(rowColor);
            columnColor = ColorUtils.replaceAlphaInRGBA(columnColor, rowAlpha);
          }
          if (exists(bandedColor)) {
            const productsContainerBgColor = rowViewModel?.section?.metadata?.productsContainerBackgroundColor;
            const bgColor = productsContainerBgColor || menu?.menuOptions?.bodyBackgroundColor;
            const isUsingDefaultBanding = menu?.bandedRowsDefaultEnabled() && !bgColor;
            // If bgColor is white and bandedRowColorContrast == Lighten, we skip the contrast
            // else is bgColor black and bandedRowColorContrast == Darken, we skip the contrast
            const lightenContrast = menu?.bandedRowColorContrast() === BandedRowColorContrast.Lighten;
            const darkenContrast = menu?.bandedRowColorContrast() === BandedRowColorContrast.Darken;
            const isLighteningWhite = ColorUtils.isWhite(bgColor) && lightenContrast;
            const isDarkeningBlack = ColorUtils.isBlack(bgColor) && darkenContrast;
            const noBgNoBanding = !bgColor && !menu.bandedRowsDefaultEnabled();
            const skipContrast = isLighteningWhite || isDarkeningBlack || noBgNoBanding;
            if (!skipContrast) {
              // If default banding is used, we always want to darken, since default banding is grey applied
              // on a white background
              const shouldDarken = darkenContrast || isUsingDefaultBanding;
              const contrastBy = menu?.bandedRowColorContrastAmount();
              columnColor = shouldDarken
                ? ColorUtils.darken(columnColor, contrastBy)
                : ColorUtils.lighten(columnColor, contrastBy);
            }
          }
        }
        return columnColor;
      }),
      distinctUntilChanged()
    );
  };

  protected forcedColumnBackgroundColor(
    menu: ProductMenu,
    section: SectionWithProducts,
    rowViewModel: SectionRowViewModel,
    columnViewModel: SectionColumnViewModel,
    bandingEnabled: boolean
  ): string | null {
    const forcedColumnColor = section?.forceColumnBackgroundColor(menu, rowViewModel, columnViewModel);
    const interceptedColumnColor = menu?.interceptLineItemColumnBackgroundColor(forcedColumnColor, bandingEnabled);
    const currentAlpha = ColorUtils.rgbaGetAlphaOrNull(interceptedColumnColor);
    let alpha = section?.columnConfigOpacity(menu, rowViewModel, columnViewModel) || currentAlpha;
    if (!alpha) {
      // Column opacity of 0 is invalid and not allowed to be set on the dashboard. Default to 1.
      alpha = 1;
    }
    if (exists(interceptedColumnColor)) {
      if (interceptedColumnColor.includes('rgba')) {
        alpha = ColorUtils.rgbaGetAlphaOrNull(interceptedColumnColor) || alpha;
        return ColorUtils.replaceAlphaInRGBA(interceptedColumnColor, alpha);
      }
      // If hex column background color is present, convert to RGB and apply the alpha.
      return ColorUtils.replaceAlphaInRGBA(ColorUtils.hexToRGBAString(interceptedColumnColor), alpha);
    }
    return null;
  }

  /* **************************** Container Properties **************************** */

  public forcedRowZoom$ = combineLatest([
    this.menu$,
    this.section$,
    this.rowViewModel$
  ]).pipe(
    map(([menu, section, rowViewModel]) => section?.forcedRowZoom(menu, rowViewModel))
  );

  public forcedColumnZoom = (columnViewModel: SectionColumnViewModel): Observable<number> => {
    return combineLatest([
      this.menu$,
      this.section$,
      this.rowViewModel$,
    ]).pipe(
      map(([menu, section, rowViewModel]) => section?.forcedColumnZoom(menu, rowViewModel, columnViewModel)),
      distinctUntilChanged()
    );
  };

  // /* ******************************* Get Variant Assets ***************************** */

  public sortedVariantAsset$ = combineLatest([
    this.rowViewModel$,
    this.variantAssetService?.variantAssetMap$.notNull()
  ]).pipe(
    map(([rowViewModel, variantAssetMap]) => {
      const variantIds = rowViewModel?.rowVariants?.map(variant => variant?.id)?.filterNulls() || [];
      const variantAssets = variantIds.map(id => variantAssetMap?.get(id)).filterNulls().flatten<VariantAsset[]>();
      const sortOrder = rowViewModel?.menu?.variantAssetTypeSortOrder();
      const byAssetOrder = (a: VariantAsset, b: VariantAsset) => SortUtils.variantAssetLibrarySort(a, b, sortOrder);
      const sortedAssets = variantAssets.sort(byAssetOrder);
      return sortedAssets?.firstOrNull();
    }),
    distinctUntilChanged(DistinctUtils.distinctAsset)
  );

  public emptyAssetSrcUrl$ = this.rowViewModel$.pipe(
    map(rowViewModel => rowViewModel?.rowVariants?.firstOrNull()),
    map(variant => variant?.productType),
    map(productType => AssetUtils.productTypeUrls?.[productType] || 'assets/placeholder/no-image.svg')
  );

}
