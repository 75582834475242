/**
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export enum SectionColumnProductInfoType {
  Brand = 'Brand',
  Producer = 'Producer',
  Size = 'Size',
  Quantity = 'Quantity',
  Stock = 'Stock',
  VariantType = 'VariantType',
  VariantPrice = 'VariantPrice',
  VariantSecondaryPrice = 'VariantSecondaryPrice',
  Spacer = 'Spacer',
  TinySpacer = 'TinySpacer',
  Badge = 'Badge',
  ProductTitle = 'ProductTitle',
  StrainType = 'StrainType',
  Label = 'Label',
  Asset = 'Asset',
  QuantityAndSize = 'QuantityAndSize',
}
