// @dynamic
import { exists } from '../functions/exists';

export class StyleUtils {

  static getConsolidatedStyles(styles: any[]): any {
    const styleRes = {};
    styles?.forEach(s => {
      if (exists(s)) {
        for (const [k, v] of Object.entries(s)) {
          if (exists(v)) {
            styleRes[k] = v;
          }
        }
      }
    });
    return styleRes;
  }

}

