@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/address-cards-1x3/pennywise-address-card-stack-content/pennywise-address-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  padding: 0.375rem;
  box-sizing: border-box;
  overflow: hidden;
}
:host .main-content {
  display: flex;
  flex-direction: row;
  gap: 0.4375rem;
  height: 100%;
}
:host .main-content .left-container {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #6992eb;
  color: #ffffff;
  padding: 0 0.25rem;
  border-radius: 0.125rem;
  height: 1rem;
  width: 4rem;
  transform: rotate(-90deg) translateX(-100%) translateY(0%);
  transform-origin: top left;
}
:host .main-content .left-container .product-card-strain-type {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.625rem;
  text-align: right;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .main-content .right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  margin-left: 1.5rem;
}
:host .main-content .right-container .top-content .product-card-name {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
}
:host .main-content .right-container .top-content .product-card-brand {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  opacity: 0.4;
}
:host .main-content .right-container .bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
}
:host .main-content .right-container .bottom-content .bottom-left-content {
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  gap: 0.1875rem;
  align-items: flex-end;
}
:host .main-content .right-container .bottom-content .bottom-left-content .product-card-badge {
  height: 1rem;
  width: 1rem;
}
:host .main-content .right-container .bottom-content .bottom-left-content .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .right-container .bottom-content .bottom-left-content .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.5rem;
  display: -webkit-box;
  border-radius: 0.125rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: unset;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
:host .main-content .right-container .bottom-content .bottom-right-content {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: flex-end;
  min-width: max-content;
}
:host .main-content .right-container .bottom-content .bottom-right-content .product-card-size {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5625rem;
  opacity: 0.4;
  margin-bottom: 0.0625rem;
}
:host .main-content .right-container .bottom-content .bottom-right-content .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
:host .main-content .right-container .bottom-content .bottom-right-content .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 0.75rem;
  opacity: 0.4;
  line-height: 1rem;
}
:host .main-content .right-container .bottom-content .bottom-right-content .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 0.75rem;
}
:host .main-content .right-container .bottom-content .bottom-right-content .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.125rem;
  line-height: 1rem;
}
:host .main-content .right-container .bottom-content .bottom-right-content .pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.125rem;
  line-height: 1rem;
}
:host .main-content ::ng-deep .force-italic-style * {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 600;
}
:host .main-content ::ng-deep .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 700;
}
/*# sourceMappingURL=pennywise-address-card-stack-content.component.css.map */
