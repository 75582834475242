import { Pipe, PipeTransform } from '@angular/core';
import type { Observable } from 'rxjs';
import type { PopsCannabis5By5CardStackContentViewModel } from './pops-cannabis-5-by-5-card-stack-content-view-model';
import type { CannabinoidOrTerpeneFromPool } from '../../shared/print-card-content-view-model';

@Pipe({
  name: 'popsCompoundMaxWidth'
})
export class PopsCompoundMaxWidthPipe implements PipeTransform {

  transform(
    viewModel: PopsCannabis5By5CardStackContentViewModel,
    compound: CannabinoidOrTerpeneFromPool
  ): Observable<number> {
    return viewModel.maxCompoundContainerWidth$(compound);
  }

}
