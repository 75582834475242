import { Component, Input } from '@angular/core';
import { MenuHeaderComponent } from '../../../menu/building-blocks/menu-header/menu-header.component';
import type { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';

@Component({
  selector: 'app-marketing-header',
  templateUrl: './marketing-header.component.html',
  providers: [{ provide: MenuHeaderComponent, useExisting: MarketingHeaderComponent }]
})
export class MarketingHeaderComponent extends MenuHeaderComponent {

  @Input() menu: MarketingMenu;

}
