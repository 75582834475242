import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { map } from 'rxjs/operators';
import type { CardBackground } from '../../../../../../models/protocols/card-background';

@Injectable()
export class PrintCardBackgroundImageWithColorOverlayAndOpacityViewModel extends BaseViewModel {

  private readonly _cardBackground = new BehaviorSubject<CardBackground>(null);
  public readonly cardBackground$ = this._cardBackground as Observable<CardBackground>;
  connectToCardBackground = (x: CardBackground) => this._cardBackground.next(x);

  public readonly backgroundImage$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundImage())
  );

  public readonly backgroundImageAlignment$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundImageAlignment())
  );

  public readonly backgroundImageObjectFit$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundImageObjectFit())
  );

  public readonly backgroundImageWidthAsPercentage$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundImageWidthAsPercentage())
  );

  public readonly backgroundAssetWrapperClass$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundAssetWrapperClass())
  );

  public readonly backgroundOpacity$ = this.cardBackground$.pipe(
    map(cb => cb?.getBackgroundOpacity())
  );

  public readonly getColorBackgroundAssetBorderRadius$ = this.cardBackground$.pipe(
    map(cb => cb?.getColorBackgroundAssetBorderRadius())
  );

  public readonly cardColor$ = this.cardBackground$.pipe(
    map(cb => cb?.getCardColor())
  );

}
