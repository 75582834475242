import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../../models/base/render-context-component';
import { PrintCardBackgroundImageWithColorOverlayAndOpacityViewModel } from './print-card-background-image-with-color-overlay-and-opacity-view-model';
import { exists } from '../../../../../../functions/exists';
import type { CardBackground } from '../../../../../../models/protocols/card-background';

@Component({
  selector: 'app-print-card-background-image-with-color-overlay-and-opacity',
  templateUrl: './print-card-background-image-with-color-overlay-and-opacity.component.html',
  styleUrls: ['./print-card-background-image-with-color-overlay-and-opacity.component.scss'],
  providers: [PrintCardBackgroundImageWithColorOverlayAndOpacityViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardBackgroundImageWithColorOverlayAndOpacityComponent
  extends RenderContextComponent implements AfterViewInit, OnChanges {

  constructor(
    public viewModel: PrintCardBackgroundImageWithColorOverlayAndOpacityViewModel,
  ) {
    super();
  }

  @Input() cardBackground: CardBackground;

  setupViews() {
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardBackground) this.viewModel.connectToCardBackground(this.cardBackground);
  }

  imageRenderFailed() {
    // if failed, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  imageRenderSucceeded() {
    this.rendered.next(true);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.rendered.next(!exists(this.cardBackground?.getBackgroundImage()));
  }

}
