import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import { PriceUtils } from './price-utils';
import { PrintThemeId, ProductThemeId } from '../models/enum/dto/theme.enum';

export {};
declare global {
  export interface Number {
    applyPriceStreamRounding(
      themeId: string,
      companyId: number,
      locationId: number,
      stream: LocationPriceStream
    ): number | null;
  }
}

/**
 * Apply specific rounding rules to a price based on the theme, company, location, and price stream.
 * NOTE: the default case does NOT round the price to 2 decimal places. This method is only for specific
 * theme, company, location, and price streams that require special rounding rules.
 */
Number.prototype.applyPriceStreamRounding = function(
  themeId: string,
  companyId: number,
  locationId: number,
  stream: LocationPriceStream
): number | null {
  switch (true) {
    case themeId === ProductThemeId.IngramAndSons:
    case themeId === PrintThemeId.IngramAndSonsSingleFold:
      return PriceUtils.ingramAndSonsEighthPriceRounding(this);
    case stream === LocationPriceStream.TaxesInRounded:
      return PriceUtils.roundToNearest5CentsOrNull(this);
    default:
      return this;
  }
};
