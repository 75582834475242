// noinspection JSUnusedLocalSymbols

import { AfterViewInit, Directive, OnChanges, SecurityContext } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AssetDirective } from './asset.directive';
import { exists } from '../../../functions/exists';

@Directive({
  selector: '[appImage]'
})
export class ImageDirective extends AssetDirective implements AfterViewInit, OnChanges {

  protected setupAssetUrlBinding(): void {
    const htmlImage = this.el.nativeElement as HTMLImageElement;
    htmlImage.onload = this.renderSucceeded.bind(this);
    htmlImage.onerror = this.renderFailed.bind(this);
    this.subject.pipe(distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe(url => {
      const image = this.el.nativeElement as HTMLImageElement;
      image.src = exists(url) ? this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url) : '';
    });
  }

}
