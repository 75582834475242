import { PrintCardMenu } from './print-card-menu';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class PennywisePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsSemiBold,
      FONT.poppinsBold
    ];
  }

}
