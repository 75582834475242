export class BarcodeUtils {

  /**
   * Checks if a string is a valid GTIN-14 code
   * @param s The string to validate
   * @returns boolean indicating if the string is a valid GTIN
   */
  public static isValidGTIN14(s: string): boolean {
    // Only allow GTIN-8, GTIN-12, GTIN-13, GTIN-14 to be standardized
    // If s is fewer than 8 characters, it is not a valid GTIN
    if (s.length < 8) {
      return false;
    }
    const cleanedGTIN = BarcodeUtils.standardizeGTIN14(s);
    return cleanedGTIN.length === 14;
  }

  /**
   * Standardizes a GTIN to GTIN-14 format
   * @param val The GTIN string to standardize
   * @returns A standardized GTIN-14 string or empty string if invalid
   */
  public static standardizeGTIN14(val: string): string {
    const cleanedGTIN = BarcodeUtils.cleanGTIN14(val);
    if (cleanedGTIN === '') {
      return cleanedGTIN;
    }
    const paddedGTIN = BarcodeUtils.padGTIN(cleanedGTIN, 14);
    // Validate check digit
    if (BarcodeUtils.validateGTIN14CheckDigit(paddedGTIN)) {
      return paddedGTIN;
    } else {
      return '';
    }
  }

  /**
   * Validates the check digit of a GTIN-14
   * @param val The GTIN-14 string to validate
   * @returns boolean indicating if the check digit is valid
   */
  public static validateGTIN14CheckDigit(val: string): boolean {
    if (val.length !== 14) {
      return false;
    }
    // Remove check digit (14th position)
    const checkDigitCharacter = val.substring(13);
    const calculationCharacters = val.substring(0, 13);
    // Parse to numeric array
    const calculationDigits: number[] = [];
    for (const d of calculationCharacters) {
      calculationDigits.push(parseInt(d, 10));
    }
    // Iterate over calculationDigits and multiply by 3 or 1 (even position by 3, odd position by 1)
    let sum = 0;
    for (let i = 0; i < calculationDigits.length; i++) {
      if (i % 2 === 0) {
        // Even
        sum += calculationDigits[i] * 3;
      } else {
        // Odd
        sum += calculationDigits[i];
      }
    }
    // Subtract sum by the nearest equal or higher multiple of 10 to get check digit
    let validCheckDigit: number;
    const remainder = sum % 10;
    if (remainder === 0) {
      // Divisible by 10, so check digit is 0
      validCheckDigit = 0;
    } else {
      validCheckDigit = 10 - remainder;
    }
    const checkDigit = parseInt(checkDigitCharacter, 10);
    return checkDigit === validCheckDigit;
  }

  /**
   * Parses a composite barcode into its components
   * @param val The composite barcode string
   * @returns A tuple of [GTIN14, expiry, lot/batch]
   */
  public static parseCompositeBarcode(val: string): [string, string, string] {
    // Returns GTIN14, expiry, lot/batch
    const cleanedComposite = BarcodeUtils.cleanCompositeBarcode(val);
    if (cleanedComposite.length > 20) {
      // Parse GTIN-14
      const GTIN14 = cleanedComposite.substring(0, 14);
      // Parse 6 dig Exp
      const expiry = cleanedComposite.substring(14, 20);
      // Parse Batch/Lot up to 20
      const batchLot = cleanedComposite.substring(20);
      return [GTIN14, expiry, batchLot];
    } else {
      return ['', '', ''];
    }
  }

  /**
   * Pads a GTIN string to the specified size
   * @param val The GTIN string to pad
   * @param size The target length
   * @returns A padded GTIN string
   */
  public static padGTIN(val: string, size: number): string {
    // Parse to numeric
    const numericGTIN = parseInt(val, 10);
    if (isNaN(numericGTIN)) {
      return '';
    }
    switch (size) {
      case 8:
        return numericGTIN.toString().padStart(8, '0');
      case 12:
        return numericGTIN.toString().padStart(12, '0');
      case 13:
        return numericGTIN.toString().padStart(13, '0');
      case 14:
        return numericGTIN.toString().padStart(14, '0');
      default:
        return val;
    }
  }

  /**
   * Cleans a GTIN-14 string
   * @param val The string to clean
   * @returns A cleaned GTIN-14 string
   */
  public static cleanGTIN14(val: string): string {
    if (val.length > 20) {
      // Parse composite -- GTIN-14 + 6 dig Exp + Batch/Lot up to 20
      const [gtinNumber, , ] = BarcodeUtils.parseCompositeBarcode(val);
      return gtinNumber;
    } else {
      // strip any non-numeric values
      const numberRegex = /[^0-9]+/g;
      return val.replace(numberRegex, '');
    }
  }

  /**
   * Cleans a composite barcode string
   * @param val The composite barcode string to clean
   * @returns A cleaned composite barcode string
   */
  public static cleanCompositeBarcode(val: string): string {
    // strip any non-alphanumeric values
    const alphaNumericRegex = /[^a-zA-Z0-9-]+/g;
    return val.replace(alphaNumericRegex, '');
  }

}
