import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { UnitOfMeasure } from '../../enum/dto/unit-of-measure.enum';
import { NumberUtils } from '../../../utils/number.utils';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';

export const PRICING_TIER_UPPER_LIMIT = 9999;

export class VariantPricingTier implements Deserializable, UniquelyIdentifiable {

  public name: string;
  public startWeight: number;   // Lower bounded weight for pricing tier
  public startQuantity: number; // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public endWeight: number;     // Upper bounded weight for pricing tier
  public endQuantity: number;   // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public price: number;         // Indicates price per unit/uom
  public taxesInPrice: number;
  public isDiscountable: boolean;

  onDeserialize() {
  }

  /**
   * This functionality directly matches what is implemented on the API.
   * The selected values are validated on the API, so it is important that
   * this is only changed in conjunction with the API
   */
  getGridColumnName(useWeight: boolean, uom: UnitOfMeasure): string {
    if (useWeight && (this.startWeight > 0 || this.endWeight > 0)) {
      // Return column name using Weight
      if (this.endWeight === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startWeight)}${uom}+`;
      } else {
        const startWeight = NumberUtils.formatToSigFigDecimals(this.startWeight);
        const endWeight = NumberUtils.formatToSigFigDecimals(this.endWeight);
        return `${startWeight}-${endWeight}${uom}`;
      }
    } else {
      // Default to using quantity
      if (this.endQuantity === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startQuantity)}+`;
      } else {
        const startQty = NumberUtils.formatToSigFigDecimals(this.startQuantity);
        const endQty = NumberUtils.formatToSigFigDecimals(this.endQuantity);
        return `${startQty}-${endQty}`;
      }
    }
  }

  capturesClassicSize1g(): boolean {
    return this.startWeight <= 1 && this.endWeight > 1;
  }

  capturesClassicSize3point5g(): boolean {
    return this.startWeight <= 3.5 && this.endWeight > 3.5;
  }

  capturesClassicSize7g(): boolean {
    return this.startWeight <= 7 && this.endWeight > 7;
  }

  capturesClassicSize14g(): boolean {
    return this.startWeight <= 14 && this.endWeight > 14;
  }

  capturesClassicSize28g(): boolean {
    return this.startWeight <= 28 && this.endWeight > 28;
  }

  /**
   * Support flower sizes: 1g, 3.5g (1/8 oz), 7g (1/4 oz), 14g (1/2 oz), and 28g (1 oz).
   */
  getFlowerGridOunceColumnName(useWeight: boolean): string[] {
    if (!useWeight) {
      return null;
    }
    const columnNames: string[] = [];
    if (this.capturesClassicSize1g()) {
      columnNames.push('1 g');
    }
    if (this.capturesClassicSize3point5g()) {
      columnNames.push('3.5 g');
    }
    if (this.capturesClassicSize7g()) {
      columnNames.push('7 g');
    }
    if (this.capturesClassicSize14g()) {
      columnNames.push('14 g');
    }
    if (this.capturesClassicSize28g()) {
      columnNames.push('28 g');
    }
    return columnNames;
  }

  getRegularPrice(): number {
    return this.price;
  }

  getTaxesInPrice(): number {
    return this.taxesInPrice;
  }

  classicFlowerPricing(columnTitle: string, priceStream: LocationPriceStream): number | null {
    if (!this.price) return null;
    const calculateOuncesPrice = (grams: number) => {
      switch (priceStream) {
        case LocationPriceStream.TaxesIn:
        case LocationPriceStream.TaxesInRounded:
          return this.taxesInPrice * grams;
        default:
          return this.price * grams;
      }
    };
    switch (true) {
      case (columnTitle === '1 g') && this.capturesClassicSize1g():         return calculateOuncesPrice(1);
      case (columnTitle === '3.5 g') && this.capturesClassicSize3point5g(): return calculateOuncesPrice(3.5);
      case (columnTitle === '7 g') && this.capturesClassicSize7g():         return calculateOuncesPrice(7);
      case (columnTitle === '14 g') && this.capturesClassicSize14g():       return calculateOuncesPrice(14);
      case (columnTitle === '28 g') && this.capturesClassicSize28g():       return calculateOuncesPrice(28);
      default:                                                              return null;
    }
  }

  getUniqueIdentifier(): string {
    return `
      -${this.name}
      -${this.startWeight}
      -${this.startQuantity}
      -${this.endWeight}
      -${this.endQuantity}
      -${this.price}
      -${this.taxesInPrice}
      -${this.isDiscountable}
    `;
  }

}
