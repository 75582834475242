import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import type { LocationPriceStream } from '../../../../../models/enum/shared/location-price-stream';
import { ColumnComponent } from '../column.component';
import { SpecificPriceWithoutDiscounts } from '../../../../../models/enum/shared/specific-price-without-discounts.enum';

@Component({
  selector: 'app-column-primary-price',
  templateUrl: './column-primary-price.component.html',
  styleUrls: ['./column-primary-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnPrimaryPriceComponent extends ColumnComponent {

  @Input() forceNgContentToBeShown: boolean = false;
  @Input() specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[] | null = null;
  @Input() salePriceOnly: boolean = false;
  @Input() hidePreviousPrice: boolean = false;
  @Input() leadingTextOriginalPriceWithSale: string;
  @Input() includeLineThroughOnLeadingTextOriginalPriceWithSale: boolean = true;
  @Input() leadingTextOriginalPriceWithoutSale: string;
  @Input() leadingTextSalePrice: string;
  @Input() overridePriceStream: LocationPriceStream;
  @Input() showNgContentContainerIfNoDiscount: boolean = false;
  @Input() ignoreStylingForSaleText: boolean = false;
  @Input() finalPriceStringModifier: (price: string) => string;
  @Input() checkForPriceChange: boolean;

}
