{
  "name": "budsense-client-display",
  "version": "2.1.0-23",
  "author": {
    "name": "Kevin Baker",
    "url": "https://mybudsense.com",
    "github": "https://github.com/mobilefirstdev"
  },
  "contributors": [
    {
      "name": "Kevin Baker",
      "url": "https://github.com/kevinpbaker"
    }
  ],
  "engines": {
    "npm": "use yarn instead of npm",
    "yarn": ">= 1.20.0"
  },
  "resolutions": {
    "autoprefixer": "10.4.20",
    "webpack": "^5.73.0"
  },
  "lint-staged": {
    "*.{js,ts}": [
      "eslint --fix"
    ],
    "*.{scss,css,html}": [
      "prettier --write --ignore-unknown"
    ]
  },
  "scripts": {
    "ng": "ng",
    "precommit": "lint-staged",
    "prepare": "husky install",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "fix": "yarn cache clean && rm -rf .angular && rm -rf node_modules && yarn install",
    "start": "ng serve --configuration development --port 4201",
    "startStaging": "ng serve --configuration staging --port 4201",
    "startFastStaging": "ng serve --configuration fastStaging --port 4201",
    "startFastRC": "ng serve --configuration fastRC --port 4201",
    "startRC": "ng serve --configuration rc --port 4201",
    "devBuild": "ng build --configuration development --base-href ./",
    "devDeleteNonAssetsS3": "aws s3 rm s3://dev.display.mybudsense.com --recursive --exclude \"assets/*\" --profile m1dev",
    "devSyncCodeToS3": "aws s3 sync dist/display-app/. s3://dev.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev",
    "devSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://dev.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete --profile m1dev",
    "devInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E2QSO4E7V44DT7 --paths '/*' --profile m1dev > > /dev/null",
    "devDeploy": "yarn install && yarn devBuild && yarn devDeleteNonAssetsS3 && yarn devSyncCodeToS3 && yarn devSyncAssetsToS3 && yarn devInvalidateCloudFront",
    "devGithubDeleteNonAssetsS3": "aws s3 rm s3://dev.display.mybudsense.com --recursive --exclude \"assets/*\"",
    "devGithubSyncCodeToS3": "aws s3 sync dist/display-app/. s3://dev.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers",
    "devGithubSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://dev.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete",
    "devGithubInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E2QSO4E7V44DT7 --paths '/*' > /dev/null",
    "devGithubActionDeploy": "yarn devBuild && yarn devGithubDeleteNonAssetsS3 && yarn devGithubSyncCodeToS3 && yarn devGithubSyncAssetsToS3 && yarn devGithubInvalidateCloudFront",
    "stagingBuild": "ng build --configuration staging --base-href ./",
    "stagingDeleteNonAssetsS3": "aws s3 rm s3://staging.display.mybudsense.com --recursive --exclude \"assets/*\" --profile m1dev",
    "stagingSyncCodeToS3": "aws s3 sync dist/display-app/. s3://staging.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev",
    "stagingSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://staging.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete --profile m1dev",
    "stagingInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E37J9SMUQ3CJYE --paths '/*' --profile m1dev > /dev/null",
    "stagingDeploy": "yarn install && yarn stagingBuild && yarn stagingDeleteNonAssetsS3 && yarn stagingSyncCodeToS3 && yarn stagingSyncAssetsToS3 && yarn stagingInvalidateCloudFront",
    "stagingGithubDeleteNonAssetsS3": "aws s3 rm s3://staging.display.mybudsense.com --recursive --exclude \"assets/*\"",
    "stagingGithubSyncCodeToS3": "aws s3 sync dist/display-app/. s3://staging.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers",
    "stagingGithubSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://staging.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete",
    "stagingGithubInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E37J9SMUQ3CJYE --paths '/*' > /dev/null",
    "stagingGithubActionDeploy": "yarn stagingBuild && yarn stagingGithubDeleteNonAssetsS3 && yarn stagingGithubSyncCodeToS3 && yarn stagingGithubSyncAssetsToS3 && yarn stagingGithubInvalidateCloudFront",
    "rcBuild": "ng build --configuration rc --base-href ./",
    "rcDeleteNonAssetsS3": "aws s3 rm s3://rc.display.mybudsense.com --recursive --exclude \"assets/*\" --profile m1dev",
    "rcSyncCodeToS3": "aws s3 sync dist/display-app/. s3://rc.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev",
    "rcSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://rc.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete --profile m1dev",
    "rcInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E12VB6VP9FKO8X --paths '/*' --profile m1dev > /dev/null",
    "rcDeploy": "yarn install && yarn rcBuild && yarn rcDeleteNonAssetsS3 && yarn rcSyncCodeToS3 && yarn rcSyncAssetsToS3 && yarn rcInvalidateCloudFront",
    "rcGithubDeleteNonAssetsS3": "aws s3 rm s3://rc.display.mybudsense.com --recursive --exclude \"assets/*\"",
    "rcGithubSyncCodeToS3": "aws s3 sync dist/display-app/. s3://rc.display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers",
    "rcGithubSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://rc.display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete",
    "rcGithubInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E12VB6VP9FKO8X --paths '/*' > /dev/null",
    "rcGithubActionDeploy": "yarn rcBuild && yarn rcGithubDeleteNonAssetsS3 && yarn rcGithubSyncCodeToS3 && yarn rcGithubSyncAssetsToS3 && yarn rcGithubInvalidateCloudFront",
    "prodBuild": "ng build --configuration production --base-href ./",
    "prodDeleteNonAssetsS3": "aws s3 rm s3://display.mybudsense.com --recursive --exclude \"assets/*\" --profile m1dev",
    "prodSyncCodeToS3": "aws s3 sync dist/display-app/. s3://display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev",
    "prodSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete --profile m1dev",
    "prodInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E1ZGXUHYHBLMGL --paths '/*' --profile m1dev > /dev/null",
    "prodDeploy": "yarn install && yarn prodBuild && yarn prodDeleteNonAssetsS3 && yarn prodSyncCodeToS3 && yarn prodSyncAssetsToS3 && yarn prodInvalidateCloudFront",
    "prodGithubDeleteNonAssetsS3": "aws s3 rm s3://display.mybudsense.com --recursive --exclude \"assets/*\"",
    "prodGithubSyncCodeToS3": "aws s3 sync dist/display-app/. s3://display.mybudsense.com --exclude \"assets/*\" --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers",
    "prodGithubSyncAssetsToS3": "aws s3 sync dist/display-app/assets/ s3://display.mybudsense.com/assets/ --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --size-only --delete",
    "prodGithubInvalidateCloudFront": "aws cloudfront create-invalidation --distribution-id E1ZGXUHYHBLMGL --paths '/*' > /dev/null",
    "prodGithubActionDeploy": "yarn prodBuild && yarn prodGithubDeleteNonAssetsS3 && yarn prodGithubSyncCodeToS3 && yarn prodGithubSyncAssetsToS3 && yarn prodGithubInvalidateCloudFront"
  },
  "madge": {
    "excludeRegExp": [
      "^index.ts$"
    ],
    "detectiveOptions": {
      "ts": {
        "skipTypeImports": true
      }
    }
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.12",
    "@angular/cdk": "17.3.10",
    "@angular/common": "17.3.12",
    "@angular/compiler": "17.3.12",
    "@angular/core": "17.3.12",
    "@angular/forms": "17.3.12",
    "@angular/platform-browser": "17.3.12",
    "@angular/platform-browser-dynamic": "17.3.12",
    "@angular/router": "17.3.12",
    "@juggle/resize-observer": "^3.4.0",
    "@ngbracket/ngx-layout": "17.0.1",
    "@rx-angular/cdk": "^17.1.0",
    "@rx-angular/template": "^17.3.1",
    "autoprefixer": "10.4.20",
    "fastdom": "^1.0.12",
    "firebase": "^9.9.0",
    "jackson-js": "^1.1.0",
    "jsbarcode": "^3.11.6",
    "lzutf8": "^0.6.3",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ng-morph": "^3.0.0",
    "ngx-barcode6": "1.0.24",
    "ngx-bootstrap": "^5.6.2",
    "ngx-pipes": "^3.2.2",
    "ngx-spinner": "16.0.2",
    "ngx-toastr": "^16.2.0",
    "p5": "^1.4.1",
    "postcss": "^8.4.33",
    "rxjs": "~7.8.1",
    "tslib": "2.5.3",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.3.11",
    "@angular-devkit/core": "17.3.11",
    "@angular-devkit/schematics": "17.3.11",
    "@angular-eslint/builder": "17.5.3",
    "@angular-eslint/schematics": "^17.5.3",
    "@angular-eslint/template-parser": "^17.5.3",
    "@angular/cli": "17.3.11",
    "@angular/compiler-cli": "17.3.12",
    "@angular/language-service": "17.3.12",
    "@commitlint/cli": "^17.6.7",
    "@commitlint/config-conventional": "^17.6.7",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/node": "^20.1.0",
    "@typescript-eslint/eslint-plugin": "^6.21.0",
    "@typescript-eslint/parser": "6.21.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "latest",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-kevs-plugin": "file:eslint",
    "eslint-plugin-prefer-arrow": "latest",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-unused-imports": "^3.2.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "lint-staged": "^15.2.4",
    "madge": "^8.0.0",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "typescript": "5.4.5"
  }
}
