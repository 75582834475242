import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { JaneDoeAddressCardStackBackgroundViewModel } from './jane-doe-address-card-stack-background-view-model';
import type { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'lib-jane-doe-address-card-stack-background',
  templateUrl: './jane-doe-address-card-stack-background.component.html',
  styleUrls: ['./jane-doe-address-card-stack-background.component.scss'],
  providers: [JaneDoeAddressCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JaneDoeAddressCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: JaneDoeAddressCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: CardData;

}
