@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-index-card-content/fika-index-card-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host .main-content {
  flex: 1;
  text-align: center;
  color: #0c2340;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .label-container {
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
:host .main-content .label-container .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .label-container .print-card-label ::ng-deep .product-label {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  padding: 0.25rem 0.5rem;
  font-size: calc(1.125rem * 1);
  line-height: 120%;
  border-radius: calc(0.125rem * 1);
  margin: 0;
  width: fit-content;
}
:host .main-content .product-card-brand {
  position: absolute;
  bottom: 24.75rem;
  left: 1.5rem;
  right: 1.5rem;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8125rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  padding: 0 0.5rem;
}
:host .main-content .name-badges-desc {
  position: absolute;
  top: 7.25rem;
  bottom: 9.5rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
}
:host .main-content .name-badges-desc .product-card-name {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 700;
  font-style: normal;
  height: 2.9rem;
  font-size: calc(1.25rem * 1);
  line-height: 118%;
  letter-spacing: 0.0475rem;
  padding: 0 0.5rem;
}
:host .main-content .name-badges-desc .badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 0.65rem;
  row-gap: 0.25rem;
  padding: 0.625rem 0 0 0;
}
:host .main-content .name-badges-desc .badges .product-card-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
}
:host .main-content .name-badges-desc .badges .product-card-badge-container .product-card-badge {
  width: calc(2rem * 1);
}
:host .main-content .name-badges-desc ::ng-deep .description-container {
  padding: 0 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
:host .main-content .name-badges-desc ::ng-deep .description-container .description {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: calc(0.75rem * 1);
  margin-top: 3rem;
}
:host .main-content .name-badges-desc ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
:host .main-content .name-badges-desc ::ng-deep .description-container .rich-text {
  max-height: 6.25rem;
  overflow-y: hidden;
}
:host .main-content .type-and-cannabinoids-container {
  position: absolute;
  bottom: 6.75rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.75rem * 1);
  gap: 0.25rem;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .type-and-strain {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  gap: 0.1875rem;
  align-items: center;
  justify-content: center;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .type-and-strain .type-and-strain-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .type-and-properties-separator {
  width: 100%;
  height: 2px;
  background-color: #0c2340;
  border: 0;
  margin: 0;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties .property-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.15rem;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties .property-row .property {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.15rem;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties .property-row .property-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties .property-row .property-title {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: calc(0.8125rem * 1);
}
:host .main-content .type-and-cannabinoids-container .type-and-cannabinoids .properties .property-row .property-value {
  font-size: calc(0.8125rem * 1);
}
:host .main-content .fika-size-and-price {
  position: absolute;
  top: 27rem;
  left: 1.5rem;
  right: 1.5rem;
}
:host .main-content ::ng-deep .force-bold-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 700;
  font-style: normal;
}
:host .main-content ::ng-deep .force-italic-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: italic;
}
/*# sourceMappingURL=fika-index-card-content.component.css.map */
