import { Deserializable } from '../../protocols/deserializable';
import { MenuStyleObject } from '../../enum/dto/menu-style-object.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import type { Section } from '../section/section';

export class MenuStyle implements Deserializable, UniquelyIdentifiable {

  public configurationId: string;
  public id: string;
  public sectionId: string;
  public objectId: string;
  public objectType: MenuStyleObject;
  public fontSize: number;
  public fontColor: string;
  public backgroundColor: string;
  public bold: boolean;
  public italics: boolean;

  public static findProductStyle(styles: MenuStyle[], prodId: string, secId: string): MenuStyle | null {
    return styles
      ?.find(s => s?.objectType === 'product' && s?.objectId === prodId && secId?.includes(s?.sectionId)) || null;
  }

  public static findVariantStyle(styles: MenuStyle[], varId: string, secId: string): MenuStyle | null {
    return styles
      ?.find(s => s?.objectType === 'variant' && s?.objectId === varId && secId?.includes(s?.sectionId)) || null;
  }

  public onDeserialize() {

  }

  public templateStyleTargetsWhichTemplatedSectionId(templatedSections: Section[]): string {
    return templatedSections
      ?.find(s => s?.templateSectionId === this.sectionId)
      ?.id;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.id}
      -${this.sectionId}
      -${this.objectId}
      -${this.objectType}
      -${this.fontSize}
      -${this.fontColor}
      -${this.backgroundColor}
      -${this.bold}
      -${this.italics}
    `;
  }

}
