import { Deserializable } from '../../protocols/deserializable';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { Cachable } from '../../protocols/cachable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { DateUtils } from '../../../utils/date-utils';
import { SaleLabelFormat } from '../../enum/shared/sale-label-format.enum';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { SectionColumnConfigFontStyle } from '../../enum/shared/section-column-config-font-style';

export class LocationConfiguration implements Deserializable, Cachable, UniquelyIdentifiable {

  public companyId: number;
  public locationId: number;
  public lastSmartFilterSync: number;
  public smartFilterIgnoredVariants: string[]; // Variant IDs;
  public priceFormat: LocationPriceStream;
  public salePriceFontStyle: SectionColumnConfigFontStyle;
  public salePriceFontColor: string;
  public dateCreated: number;
  public lastUpdated: number;
  public saleLabelFormat: SaleLabelFormat;
  public labelStyle: LabelStyle;
  public timezone: string;
  public secondaryPriceGroupId: string;
  // not from api
  public cachedTime: number;

  static buildCacheKey(id: string): string {
    return `LocationConfiguration-${id}`;
  }

  onDeserialize() {
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return LocationConfiguration.buildCacheKey(this.locationId.toString());
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `
     -${this.companyId}
     -${this.locationId}
     -${this.lastSmartFilterSync}
     -${this.smartFilterIgnoredVariants?.sort()?.join(',')}
     -${this.priceFormat}
     -${this.dateCreated}
     -${this.lastUpdated}
     -${this.secondaryPriceGroupId}
   `;
  }

}
