<div class="inventory-label">
  <div class="left-side">
    <div class="top-left-side">
      <div
        *ngIf="viewModel.labelData$ | async | sectionRowViewModelBasicMethod: 'getRowTitle' as name"
        class="product-name">
        {{ name }}
      </div>
      <div
        *ngIf="viewModel.labelData$ | async | sectionRowViewModelBasicMethod: 'getBrand' as brand"
        class="product-brand">
        {{ brand }}
      </div>
    </div>
    <div
      *ngIf="viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) | async as size"
      class="product-size">
      {{ size }}
    </div>
  </div>
  <div class="right-side">
    <div class="top-right-side">
      <div class="classification-letter" [style.background-color]="viewModel.strainTypeColor$ | async">
        {{ viewModel.strainTypeSymbol$ | async }}
      </div>
      <img
        *ngIf="viewModel.labelAssetUrl$ | async as labelAssetUrl"
        class="product-image"
        [src]="labelAssetUrl"
        alt="product image" />
    </div>
    <div class="product-sku">{{ viewModel.sku$ | async }}</div>
    <div style="text-align: center; height: 3.75rem; min-width: 8.75rem">
      <div class="barcode-container">
        <ngx-barcode6
          *ngIf="viewModel.barcodeToDisplay$ | async as barCode"
          [bc-margin]="0"
          [bc-margin-bottom]="0"
          [bc-margin-right]="0"
          [bc-margin-top]="0"
          [bc-margin-left]="0"
          [bc-width]="1.05"
          [bc-element-type]="'svg'"
          [bc-height]="34"
          [bc-format]="'ITF14'"
          [bc-value]="barCode"
          class="barcode-element">
        </ngx-barcode6>
        <div *ngIf="!(viewModel.barcodeIsValid$ | async)" class="invalid-barcode-overlay">INVALID</div>
      </div>
      <div class="bar-code-value">{{ viewModel.barCode$ | async }}</div>
    </div>
  </div>
</div>
