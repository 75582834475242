import { ProductMenu } from '../product-menu';
import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class PurLifeMenu extends ProductMenu {

  getThemeClass(): string {
    return this.isLandscape() ? 'pur-life-theme-landscape' : 'pur-life-theme';
  }

  getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  centerMenuTitleWhenLogoIsHidden(): boolean {
    return true;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.ProductTitle, 2],
      [SectionColumnProductInfoType.Brand, 3],
      [SectionColumnProductInfoType.Badge, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantType, 10],
      [SectionColumnProductInfoType.Stock, 11],
      [SectionColumnProductInfoType.VariantPrice, 12],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 13],
    ]);
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const classification = col?.columnType === SectionColumnProductInfoType.StrainType;
    return !classification;
  }

  getProductWrapperClass(odd: boolean): string {
    if (odd) {
      return 'product-odd';
    } else {
      return 'product-even';
    }
  }

  getUniqueClassificationList(): StrainType[] {
    return super.getUniqueClassificationList()?.sort(SortVariantUtils.genericStrainTypeSort);
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    return super.getPriceColumnTitle(col)?.trim();
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.robotoBlack,
      FONT.robotoBold,
      FONT.robotoMediumExtra,
      FONT.robotoRegularItalic,
      FONT.robotoBlackExtra,
      FONT.robotoRegularItalic
    ];
  }

  getShowFooter(): boolean {
    return false;
  }

  getSectionWrapperClass(...args: any[]): string {
    if (this.isPortrait() && this.columnCount > 1) {
      return 'portrait-multi-column';
    } else {
      return null;
    }
  }

  getHeaderLogoMargin(): string {
    if (this.isLandscape() || (this.isPortrait() && this.columnCount > 1)) {
      return `0 1rem 0 0`;
    } else {
      return null;
    }
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowColor(rowViewModel: SectionRowViewModel, odd: boolean): string {
    return super.bandedRowColor(rowViewModel, odd, 0.25);
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Darken;
  }

}
