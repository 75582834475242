import { type CannabinoidOrTerpeneFromPool, PrintCardContentViewModel, type PropertyPoolSorter } from '../../shared/print-card-content-view-model';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import type { PopsCannabisCardData } from '../../../../../../../models/print-cards/pops-cannabis-card-data';
import { ProductType } from '../../../../../../../models/enum/dto/product-type.enum';
import { Move, SortUtils } from '../../../../../../../utils/sort-utils';

@Injectable()
export class PopsCannabis5By5CardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<PopsCannabisCardData>;

  public readonly priceStreamOverride$ = this.cardData$.pipe(
    map(cardData => cardData?.getOverridePriceStreamForLabelComponent())
  );

  public readonly leftSideLargeText$ = this.cardData$.pipe(
    map(cardData => cardData?.getLeftLargeText())
  );

  public readonly showLeftSideLargeText$ = combineLatest([
    this.preview$,
    this.cardData$
  ]).pipe(
    map(([previewMode, cardData]) => {
      const productType = cardData?.rowProductType();
      const hasSticker = productType === ProductType.Flower
        || productType === ProductType.Vape
        || productType === ProductType.Concentrate;
      return previewMode || !hasSticker;
    })
  );

  public readonly totalCompoundCharacters$ = this.propertiesBasedOnPools$.pipe(
    map(properties => {
      return properties?.reduce((acc, prop) => acc + prop?.value?.length, 0) || 0;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  maxCompoundContainerWidth$(compound: CannabinoidOrTerpeneFromPool): Observable<number> {
    return this.totalCompoundCharacters$.pipe(
      map(totalCompoundCharacters => {
        const maxWidth = 301.22;
        const allocatedPercentage = (compound?.value?.length ?? 0) / totalCompoundCharacters;
        return Math.floor(allocatedPercentage * maxWidth);
      }),
      distinctUntilChanged()
    );
  }

  /**
   * Sort final property list from highest to lowest value, then by title.
   * Force "terps" to end of list if present.
   */
  override finalSortOnProductPropertiesInPoolLambda(): PropertyPoolSorter | null {
    return (a: CannabinoidOrTerpeneFromPool, b: CannabinoidOrTerpeneFromPool): number => {
      const terpsToEnd = (aTitle: string, bTitle: string): Move => {
        if (aTitle?.toLowerCase() === 'terps') return Move.ARight;
        if (bTitle?.toLowerCase() === 'terps') return Move.BRight;
        return 0;
      };
      return terpsToEnd(a?.title, b?.title)
          || SortUtils.parseFloatFromStringDescending(a?.value, b?.value)
          || SortUtils.stringAscending(a?.title, b?.title);
    };
  }

}
