import { Component, ElementRef, ViewChild } from '@angular/core';
import { LabelComponent } from '../label-component';
import { FlagLabelViewModel } from './flag-label-view-model';
import { ResizeObserver } from '@juggle/resize-observer';
import { HtmlUtils } from '../../../../utils/html-utils';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-flag-label',
  templateUrl: './flag-label.component.html',
  styleUrls: ['./flag-label.component.scss'],
  providers: [FlagLabelViewModel]
})
export class FlagLabelComponent extends LabelComponent {

  constructor(
    public viewModel: FlagLabelViewModel
  ) {
    super(viewModel);
  }

  @ViewChild('flagBody') flagBody: ElementRef;
  private resizeObserver: ResizeObserver;

  setupViews() {
    super.setupViews();
  }

  setupBindings() {
    this.observeFlagBody();
  }

  private observeFlagBody() {
    this.resizeObserver?.disconnect();
    if (exists(this.flagBody?.nativeElement)) {
      this.resizeObserver = new ResizeObserver((entries, _) => {
        HtmlUtils.getElementHeightWithoutMarginsAsync(this.flagBody?.nativeElement)
          .then(h => this.viewModel.connectToFlagHeight(h));
      });
      this.resizeObserver.observe(this.flagBody?.nativeElement);
    }
  }

  destroy() {
    super.destroy();
    this.resizeObserver?.disconnect();
  }

}
