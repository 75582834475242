@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/address-cards-1x3/harlequin-address-card-stack-content/harlequin-address-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.4375rem;
  box-sizing: border-box;
  overflow: hidden;
}
:host .main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
:host .main-content .top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  max-height: 2.25rem;
  flex: 1;
}
:host .main-content .top-container .left-content {
  display: flex;
  flex-direction: column;
}
:host .main-content .top-container .left-content .product-card-name {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  width: 13.25rem;
  line-height: 1.25rem;
  white-space: nowrap;
}
:host .main-content .top-container .left-content .brand-and-label-container {
  display: flex;
  flex-direction: row;
  width: 13.25rem;
}
:host .main-content .top-container .left-content .brand-and-label-container .product-brand-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}
:host .main-content .top-container .left-content .brand-and-label-container .product-brand-wrapper .product-card-brand {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.625rem;
  min-height: 0.625rem;
  opacity: 0.4;
  text-align: center;
}
:host .main-content .top-container .left-content .brand-and-label-container .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .top-container .left-content .brand-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.5rem;
  display: -webkit-box;
  border-radius: 0.125rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: unset;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  margin-left: 0.3125rem;
  padding: 0.0625rem 0.3125rem;
}
:host .main-content .top-container .right-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
:host .main-content .top-container .right-content .product-card-strain-type {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.625rem;
  background-color: #6992eb;
  color: #ffffff;
  border-radius: 0.125rem;
  padding: 0.0625rem 0.25rem;
}
:host .main-content .top-container .right-content .product-card-size {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5625rem;
  opacity: 0.4;
  margin-bottom: 0.0625rem;
}
:host .main-content .bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.25rem;
  overflow: hidden;
}
:host .main-content .bottom-container .cannabinoids-and-terpenes-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  gap: 0.25rem;
  flex: 1 1 0;
}
:host .main-content .bottom-container .cannabinoids-and-terpenes-container .property-column {
  display: flex;
  flex-direction: column;
  gap: 0;
  min-width: 0;
  padding-inline-end: 0.1rem;
}
:host .main-content .bottom-container .cannabinoids-and-terpenes-container .property-column .property-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  line-height: 0.9rem;
}
:host .main-content .bottom-container .cannabinoids-and-terpenes-container .property-column .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5625rem;
  opacity: 0.4;
  width: 1.875rem;
}
:host .main-content .bottom-container .cannabinoids-and-terpenes-container .property-column .property-container .value {
  overflow-x: clip;
  min-width: 0;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
}
:host .main-content .bottom-container .badge-size-and-price-container {
  display: flex;
  flex-direction: row;
  min-width: max-content;
  gap: 0.5rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .badge-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.25rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .badge-container .product-card-badge {
  height: 1.375rem;
  width: 1.375rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .product-card-size {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5625rem;
  opacity: 0.4;
  margin-bottom: 0.0625rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 0.625rem;
  opacity: 0.4;
  line-height: 1rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 0.625rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 0.8125rem;
  line-height: 0.9rem;
}
:host .main-content .bottom-container .badge-size-and-price-container .size-and-price-container .pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 0.8125rem;
  line-height: 0.9rem;
}
:host .main-content ::ng-deep .force-italic-style * {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 600;
}
:host .main-content ::ng-deep .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 700;
}
/*# sourceMappingURL=harlequin-address-card-stack-content.component.css.map */
