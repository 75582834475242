import { Deserializable } from './deserializable';
import { SectionType } from '../enum/dto/section-type.enum';
import { Section } from '../menu/section/section';
import { TitleSection } from '../menu/section/title-section';
import { ProductSection } from '../menu/section/product-section';
import { AssetSection } from '../menu/section/asset-section';
import { PageBreakSection } from '../menu/section/page-break-section';
import { SpotlightSection } from '../menu/section/spotlight-section';
import { FeaturedCategorySection } from '../menu/section/featured-category/featured-category-section';
import { DoubleDutchProductSection } from '../menu/section/double-dutch-product-section';
import { CardType } from '../../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';
import { HeaderImageCarouselSection } from '../menu/section/featured-category/header-image-carousel-section';
import { ProductDealCarouselSection } from '../menu/section/featured-category/product-deal-carousel-section';
import { Type } from '@angular/core';
import { LoopingContentSection } from '../menu/section/looping-content/looping-content-section';
import { PrintCardSize } from '../enum/dto/print-card-size.enum';
import { PrintCardThemeId, PrintLabelThemeId, ShelfTalkerThemeId } from '../enum/dto/theme.enum';
import { PopsCannabis5By5CardStack } from '../menu/section/card-stacks/custom-cards-5x5/pops-cannabis-5-by-5-card-stack';
import { DougBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/doug-business-card-stack';
import { DougIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/doug-index-card-stack';
import { DougPostCardStack } from '../menu/section/card-stacks/post-cards-4x6/doug-post-card-stack';
import { PlainJaneIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/plain-jane-index-card-stack';
import { PlainJaneBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/plain-jane-business-card-stack';
import { PlainJanePostCardStack } from '../menu/section/card-stacks/post-cards-4x6/plain-jane-post-card-stack';
import { OGKushBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/og-kush-business-card-stack';
import { OGKushIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/og-kush-index-card-stack';
import { OGKushPostCardStack } from '../menu/section/card-stacks/post-cards-4x6/og-kush-post-card-stack';
import { ReportNewProductsSection } from '../menu/section/report/report-new-products-section';
import { ReportRestockedProductsSection } from '../menu/section/report/report-restocked-products-section';
import { MissJonesPostCardStack } from '../menu/section/card-stacks/post-cards-4x6/miss-jones-post-card-stack';
import { AgentOrangeIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/agent-orange-index-card-stack';
import { AgentOrangePostCardStack } from '../menu/section/card-stacks/post-cards-4x6/agent-orange-post-card-stack';
import { FikaIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/fika-index-card-stack';
import { JaneDoeAddressCardStack } from '../menu/section/card-stacks/address-cards-1x3/jane-doe-address-card-stack';
import { JaneDoeBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/jane-doe-business-card-stack';
import { JaneDoePostCardStack } from '../menu/section/card-stacks/post-cards-4x6/jane-doe-post-card-stack';
import { TrueNorthPortraitCardStack } from '../menu/section/card-stacks/post-cards-4x6/true-north-portrait-card-stack';
import { TrueNorthLandscapeCardStack } from '../menu/section/card-stacks/post-cards-4x6/true-north-landscape-card-stack';
import { SessionsPostCardStack } from '../menu/section/card-stacks/post-cards-4x6/sessions-post-card-stack';
import { FikaBeverageAddressCardStack } from '../menu/section/card-stacks/address-cards-1x3/fika-beverage-address-card-stack';
import { HoneyHazeBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/honey-haze-business-card-stack';
import { PlantlifeProductSection } from '../menu/section/plantlife-product-section';
import { FireAndFlowerCardStack } from '../menu/section/card-stacks/custom-cards-2x2/fire-and-flower-card-stack';
import { PlantlifeBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/plantlife-business-card-stack';
import { FikaAccessoriesIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/fika-accessories-index-card-stack';
import { FireAndFlowerDefaultCardStack } from '../menu/section/card-stacks/custom-cards-2x2/fire-and-flower-default-card-stack';
import { FireAndFlowerSaleCardStack } from '../menu/section/card-stacks/custom-cards-2x2/fire-and-flower-sale-card-stack';
import { FireAndFlowerMemberCardStack } from '../menu/section/card-stacks/custom-cards-2x2/fire-and-flower-member-card-stack';
import { BaseInventoryLabelStack } from '../menu/section/label-stacks/custom-cards-2x4/base-inventory-label-stack';
import { GenericPostCardStack } from '../menu/section/card-stacks/post-cards-4x6/generic-post-card-stack';
import { DougArBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/doug-ar-business-card-stack';
import { NycdBusinessCardStack } from '../menu/section/card-stacks/business-cards-2x3/nycd-business-card-stack';
import { NycdIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/nycd-index-card-stack';
import { StiiizyCardStack } from '../menu/section/card-stacks/business-cards-2x3/stiiizy-card-stack';
import { GanjikaBeverageAddressCardStack } from '../menu/section/card-stacks/address-cards-1x3/ganjika-beverage-address-card-stack';
import { FikaEdiblesIndexCardStack } from '../menu/section/card-stacks/index-cards-3x5/fika-edibles-index-card-stack';
import { ShelfTalkerCardSize } from '../enum/dto/shelf-talker-card-size';
import { FireAndFlowerPostCardShelfTalker } from '../menu/section/shelf-talker-card/post-card-4x6/fire-and-flower-post-card-shelf-talker';
import { FireAndFlower5By7ShelfTalkerCard } from '../menu/section/shelf-talker-card/custom-5x7/fire-and-flower-5-by-7-shelf-talker-card';
import { HarlequinAddressCardStack } from '../menu/section/card-stacks/address-cards-1x3/harlequin-address-card-stack';
import { PennywiseAddressCardStack } from '../menu/section/card-stacks/address-cards-1x3/pennywise-address-card-stack';
import { DougPostCardShelfTalker } from '../menu/section/shelf-talker-card/post-card-4x6/doug-post-card-shelf-talker';
import { Doug5By7ShelfTalkerCard } from '../menu/section/shelf-talker-card/custom-5x7/doug-5-by-7-shelf-talker-card';

/**
 * This allows for polymorphic deserialization of sections.
 * As new sections are created, they need to be added in here
 */
export class DeserializeSectionHelper {

  static getPolymorphicSectionObject<T extends Deserializable>(section: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), section);
    };
    switch (section?.sectionType) {
      case SectionType.Asset: {
        return createPolymorphicObject(AssetSection);
      }
      case SectionType.CardStack: {
        return DeserializeSectionHelper.getCardStackObject(section, createPolymorphicObject);
      }
      case SectionType.CategoryCard: {
        return DeserializeSectionHelper.getCategoryCardObject(section, createPolymorphicObject);
      }
      case SectionType.DoubleDutchProduct: {
        return createPolymorphicObject(DoubleDutchProductSection);
      }
      case SectionType.LabelStack: {
        return DeserializeSectionHelper.getLabelStackObject(section, createPolymorphicObject);
      }
      case SectionType.LoopingContentSection: {
        return createPolymorphicObject(LoopingContentSection);
      }
      case SectionType.PageBreak: {
        return createPolymorphicObject(PageBreakSection);
      }
      case SectionType.PlantlifeProduct: {
        return createPolymorphicObject(PlantlifeProductSection);
      }
      case SectionType.PrintReportNewProducts: {
        return DeserializeSectionHelper.getPrintReportNewProductsObject(section, createPolymorphicObject);
      }
      case SectionType.PrintReportRestockedProducts: {
        return DeserializeSectionHelper.getPrintReportRestockedProductsObject(section, createPolymorphicObject);
      }
      case SectionType.Product: {
        return createPolymorphicObject(ProductSection);
      }
      case SectionType.ShelfTalkerCard: {
        return DeserializeSectionHelper.getShelfTalkerCardObject(section, createPolymorphicObject);
      }
      case SectionType.Spotlight: {
        return createPolymorphicObject(SpotlightSection);
      }
      case SectionType.Title: {
        return createPolymorphicObject(TitleSection);
      }
      default: {
        return createPolymorphicObject(Section);
      }
    }
  }

  /* ************************************** Reports ************************************** */

  static getPrintReportNewProductsObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    return createPolymorphicObject(ReportNewProductsSection);
  }

  static getPrintReportRestockedProductsObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    return createPolymorphicObject(ReportRestockedProductsSection);
  }

  /* ************************************ Card Stacks ************************************ */

  static getCardStackObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const cardStackBuilder = (): { [key: string]: () => T } => {
      const builder: { [key: string]: () => T } = {};
      switch (section?.themeId as PrintCardThemeId) {
        case PrintCardThemeId.AgentOrange:
          const arCannabisCardStackObject = DeserializeSectionHelper.getAgentOrangeCardObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(arCannabisCardStackObject);
          break;
        case PrintCardThemeId.Doug:
          const dougCardStackObject = DeserializeSectionHelper.getDougCardStackObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(dougCardStackObject);
          break;
        case PrintCardThemeId.DougAr:
          builder[PrintCardSize.DefaultSize_BusinessCard_2x3] = () => createPolymorphicObject(DougArBusinessCardStack);
          break;
        case PrintCardThemeId.Fika:
          builder[PrintCardSize.DefaultSize_IndexCard_3x5] = () => createPolymorphicObject(FikaIndexCardStack);
          break;
        case PrintCardThemeId.FikaEdibles:
          builder[PrintCardSize.DefaultSize_IndexCard_3x5] = () => createPolymorphicObject(FikaEdiblesIndexCardStack);
          break;
        case PrintCardThemeId.FikaBeverage:
          builder[PrintCardSize.DefaultSize_AddressCard_1x3] = () => {
            return createPolymorphicObject(FikaBeverageAddressCardStack);
          };
          break;
        case PrintCardThemeId.FikaAccessories:
          builder[PrintCardSize.DefaultSize_IndexCard_3x5] = () => {
            return createPolymorphicObject(FikaAccessoriesIndexCardStack);
          };
          break;
        case PrintCardThemeId.FireAndFlower:
          builder[PrintCardSize.DefaultSize_Custom_2x2] = () => {
            return createPolymorphicObject(FireAndFlowerCardStack);
          };
          break;
        case PrintCardThemeId.FireAndFlowerDefault:
          builder[PrintCardSize.DefaultSize_Custom_2x2] = () => {
            return createPolymorphicObject(FireAndFlowerDefaultCardStack);
          };
          break;
        case PrintCardThemeId.FireAndFlowerSale:
          builder[PrintCardSize.DefaultSize_Custom_2x2] = () => {
            return createPolymorphicObject(FireAndFlowerSaleCardStack);
          };
          break;
        case PrintCardThemeId.FireAndFlowerMember:
          builder[PrintCardSize.DefaultSize_Custom_2x2] = () => {
            return createPolymorphicObject(FireAndFlowerMemberCardStack);
          };
          break;
        case PrintCardThemeId.GanjikaHouseBeverage:
          builder[PrintCardSize.DefaultSize_AddressCard_1x3] = () => {
            return createPolymorphicObject(GanjikaBeverageAddressCardStack);
          };
          break;
        case PrintCardThemeId.Generic:
          builder[PrintCardSize.DefaultSize_PostCard_4x6] = () => createPolymorphicObject(GenericPostCardStack);
          break;
        case PrintCardThemeId.Harlequin:
          builder[PrintCardSize.DefaultSize_AddressCard_1x3] = () => createPolymorphicObject(HarlequinAddressCardStack);
          break;
        case PrintCardThemeId.HoneyHaze:
          builder[PrintCardSize.DefaultSize_BusinessCard_2x3] = () => {
            return createPolymorphicObject(HoneyHazeBusinessCardStack);
          };
          break;
        case PrintCardThemeId.JaneDoe:
          const janeDoeCardStackObject = DeserializeSectionHelper.getJaneDoeCardStackObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(janeDoeCardStackObject);
          break;
        case PrintCardThemeId.MissJones:
          builder[PrintCardSize.DefaultSize_PostCard_4x6] = () => createPolymorphicObject(MissJonesPostCardStack);
          break;
        case PrintCardThemeId.NYCD:
          const nycdCardStackObject = DeserializeSectionHelper.getNYCDCardStackObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(nycdCardStackObject);
          break;
        case PrintCardThemeId.OGKush:
          const ogKushCardStackObject = DeserializeSectionHelper.getOGKushCardStackObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(ogKushCardStackObject);
          break;
        case PrintCardThemeId.Pennywise:
          builder[PrintCardSize.DefaultSize_AddressCard_1x3] = () => createPolymorphicObject(PennywiseAddressCardStack);
          break;
        case PrintCardThemeId.PlainJane:
          const plainJaneCardStackObject = DeserializeSectionHelper.getPlainJaneCardStackObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(plainJaneCardStackObject);
          break;
        case PrintCardThemeId.Plantlife:
          builder[PrintCardSize.DefaultSize_BusinessCard_2x3] = () => {
            return createPolymorphicObject(PlantlifeBusinessCardStack);
          };
          break;
        case PrintCardThemeId.PopsCannabis:
          builder[PrintCardSize.DefaultSize_Custom_5x5] = () => createPolymorphicObject(PopsCannabis5By5CardStack);
          break;
        case PrintCardThemeId.Sessions:
          builder[PrintCardSize.DefaultSize_PostCard_4x6] = () => createPolymorphicObject(SessionsPostCardStack);
          break;
        case PrintCardThemeId.Stiiizy:
          builder[PrintCardSize.DefaultSize_BusinessCard_2x3] = () => createPolymorphicObject(StiiizyCardStack);
          break;
        case PrintCardThemeId.TrueNorthLandscape:
          builder[PrintCardSize.DefaultSize_PostCard_4x6] = () => createPolymorphicObject(TrueNorthLandscapeCardStack);
          break;
        case PrintCardThemeId.TrueNorthPortrait:
          builder[PrintCardSize.DefaultSize_PostCard_4x6] = () => createPolymorphicObject(TrueNorthPortraitCardStack);
          break;
      }
      return builder;
    };
    return DeserializeSectionHelper.getStackFromThemeBuilder(section, cardStackBuilder());
  }

  private static getStackFromThemeBuilder<T extends Deserializable>(
    section: any,
    sectionBuilder: { [key: string]: () => T }
  ): T | null {
    switch (section?.cardSize) {
      case PrintCardSize.DefaultSize_AddressCard_1x3:
        return sectionBuilder?.[PrintCardSize.DefaultSize_AddressCard_1x3]?.() || null;
      case PrintCardSize.DefaultSize_Custom_2x2:
        return sectionBuilder?.[PrintCardSize.DefaultSize_Custom_2x2]?.() || null;
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return sectionBuilder?.[PrintCardSize.DefaultSize_BusinessCard_2x3]?.() || null;
      case PrintCardSize.DefaultSize_CustomLabel_2x4:
        return sectionBuilder?.[PrintCardSize.DefaultSize_CustomLabel_2x4]?.() || null;
      case PrintCardSize.DefaultSize_Custom_5x5:
        return sectionBuilder?.[PrintCardSize.DefaultSize_Custom_5x5]?.() || null;
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return sectionBuilder?.[PrintCardSize.DefaultSize_IndexCard_3x5]?.() || null;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return sectionBuilder?.[PrintCardSize.DefaultSize_PostCard_4x6]?.() || null;
      default:
        return null;
    }
  }

  private static getAgentOrangeCardObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return AgentOrangeIndexCardStack;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return AgentOrangePostCardStack;
    }
  }

  private static getDougCardStackObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return DougBusinessCardStack;
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return DougIndexCardStack;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return DougPostCardStack;
    }
  }

  private static getJaneDoeCardStackObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_AddressCard_1x3:
        return JaneDoeAddressCardStack;
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return JaneDoeBusinessCardStack;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return JaneDoePostCardStack;
    }
  }

  private static getNYCDCardStackObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return NycdBusinessCardStack;
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return NycdIndexCardStack;
    }
  }

  private static getOGKushCardStackObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return OGKushBusinessCardStack;
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return OGKushIndexCardStack;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return OGKushPostCardStack;
    }
  }

  private static getPlainJaneCardStackObject(cardSize: PrintCardSize): any {
    switch (cardSize) {
      case PrintCardSize.DefaultSize_BusinessCard_2x3:
        return PlainJaneBusinessCardStack;
      case PrintCardSize.DefaultSize_IndexCard_3x5:
        return PlainJaneIndexCardStack;
      case PrintCardSize.DefaultSize_PostCard_4x6:
        return PlainJanePostCardStack;
    }
  }

  static getCategoryCardObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T {
    switch (section?.cardType) {
      case CardType.HeaderImageCarousel:
        return createPolymorphicObject(HeaderImageCarouselSection);
      case CardType.ProductDealCarousel:
        return createPolymorphicObject(ProductDealCarouselSection);
      default:
        return createPolymorphicObject(FeaturedCategorySection);
    }
  }

  /* ************************************ Shelf Talkers ************************************ */

  static getShelfTalkerCardObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T {
    const shelfTalkerThemeBuilder = (): { [key: string]: () => T } => {
      const builder: { [key: string]: () => T } = {};
      switch (section?.themeId as ShelfTalkerThemeId) {
        case ShelfTalkerThemeId.Doug:
          const dougObjectType = DeserializeSectionHelper.getDougShelfTalkerObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(dougObjectType);
          break;
        case ShelfTalkerThemeId.FireAndFlower:
          const fireAndFlowerObjectType = DeserializeSectionHelper.getFireAndFlowerShelfTalkerObject(section?.cardSize);
          builder[section?.cardSize] = () => createPolymorphicObject(fireAndFlowerObjectType);
          break;
      }
      return builder;
    };
    return DeserializeSectionHelper.getShelfTalkerFromThemeBuilder(section, shelfTalkerThemeBuilder());
  }

  private static getShelfTalkerFromThemeBuilder<T extends Deserializable>(
    section: any,
    sectionBuilder: { [key: string]: () => T }
  ): T {
    switch (section?.cardSize as ShelfTalkerCardSize) {
      case ShelfTalkerCardSize.ShelfTalkerSize_PostCard:
        return sectionBuilder?.[ShelfTalkerCardSize.ShelfTalkerSize_PostCard]?.() || null;
      case ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7:
        return sectionBuilder?.[ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7]?.() || null;
      default:
        return null;
    }
  }

  private static getDougShelfTalkerObject(cardSize: ShelfTalkerCardSize): any {
    switch (cardSize) {
      case ShelfTalkerCardSize.ShelfTalkerSize_PostCard:
        return DougPostCardShelfTalker;
      case ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7:
        return Doug5By7ShelfTalkerCard;
    }
  }

  private static getFireAndFlowerShelfTalkerObject(cardSize: ShelfTalkerCardSize): any {
    switch (cardSize) {
      case ShelfTalkerCardSize.ShelfTalkerSize_PostCard:
        return FireAndFlowerPostCardShelfTalker;
      case ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7:
        return FireAndFlower5By7ShelfTalkerCard;
    }
  }

  /* ************************************ Label Stacks ************************************ */

  static getLabelStackObject<T extends Deserializable>(
    section: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const labelStackBuilder = (): { [key: string]: () => T } => {
      const builder: { [key: string]: () => T } = {};
      switch (section?.themeId as PrintLabelThemeId) {
        case PrintLabelThemeId.BaseInventoryLabel:
          builder[PrintCardSize.DefaultSize_CustomLabel_2x4] = () => createPolymorphicObject(BaseInventoryLabelStack);
          break;
      }
      return builder;
    };
    return DeserializeSectionHelper.getStackFromThemeBuilder(section, labelStackBuilder());
  }

}
