import { ProductSection } from './product-section';
import { PaperSize } from '../../shared/paper-size';
import { CutLineMode } from '../../enum/shared/cut-line-mode.enum';
import { Orientation } from '../../enum/dto/orientation.enum';
import { Type } from '@angular/core';
import type { ShelfTalkerCardData } from '../../shelf-talkers/shelf-talker-card-data';
import type { CardContentComponent } from '../../../modules/print-cards/components/cards/card-content/shared/card-content.component';
import type { CardData } from '../../print-cards/card-data';
import type { CardSize } from '../../enum/dto/card-size';
import type { DefaultPaperSize } from '../../enum/shared/default-paper-size.enum';
import type { CardBackgroundComponent } from '../../../modules/print-cards/components/cards/backgrounds/card-background.component';

export abstract class CardsThatLayoutOntoPaper extends ProductSection {

  static readonly PIXELS_PER_INCH = 96;
  public singleCardPerPage: boolean; // not from API - set by menu on deserialize
  public paperSize: DefaultPaperSize; // not from API - set by menu on deserialize
  public cardSize: CardSize; // not from API - set by menu on deserialize
  public cutLineMode: CutLineMode; // not from API - set within onDeserialize
  public orientation: Orientation; // not from API - set by menu on deserialize
  public themeId: string; // not from API - set by menu on deserialize

  /** IMPORTANT: It was decided that card width is always specified as if the card is in the landscape orientation. */
  abstract getCardWidthInInches(): number;
  /** IMPORTANT: It was decided that card height is always specified as if the card is in the landscape orientation. */
  abstract getCardHeightInInches(): number;
  abstract nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number;
  abstract nColumns(): number;
  abstract nRows(): number;
  abstract cardOrientationOnPortraitPage(): Orientation;
  abstract getCardBackgroundComponent(data: CardData | ShelfTalkerCardData): Type<CardBackgroundComponent> | null;
  abstract getCardContentComponent(data: CardData | ShelfTalkerCardData): Type<CardContentComponent> | null;

  onDeserialize() {
    super.onDeserialize();
    this.cutLineMode = CutLineMode.Extended;
  }

  public customTopPaperMarginInInches(): number | null {
    return null;
  }

  public getCardWidthInPixels(): number {
    const widthInInches = this.cardOrientationOnPortraitPage() === Orientation.Portrait
      ? this.getCardHeightInInches()
      : this.getCardWidthInInches();
    return widthInInches * CardsThatLayoutOntoPaper.PIXELS_PER_INCH;
  }

  public getCardHeightInPixels(): number {
    const heightInInches = this.cardOrientationOnPortraitPage() === Orientation.Portrait
      ? this.getCardWidthInInches()
      : this.getCardHeightInInches();
    return heightInInches * CardsThatLayoutOntoPaper.PIXELS_PER_INCH;
  }

  public isOnRegularPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('custom');
  }

  public isOnPerforatedPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('perforated');
  }

  public isOnLaserLabelPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('label');
  }

  public nonPerforatedBleedBorderInInches(): number {
    return 1 / 16;
  }

  public getNumberOfVisibleRowsOnPage(nCardsOnPage: number): number {
    return this.singleCardPerPage ? 1 : this.nRows();
  }

  public getNumberOfVisibleColumnsOnPage(nCardsOnPage: number): number {
    return this.singleCardPerPage ? 1 : this.nColumns();
  }

  public getLeftCutLineText(cardData: CardData): string | null {
    return null;
  }

  public extendCutLineIntoContentAreaByNPx(): number {
    return 32;
  }

  public cardPreviewHeightInPx(): number {
    const height = this.getCardHeightInPixels() || 0;
    const width = this.getCardWidthInPixels() || 0;
    return this.orientation === Orientation.Portrait
      ? Math.max(height, width)
      : Math.min(height, width);
  }

  public cardPreviewWidthInPx(): number {
    const height = this.getCardHeightInPixels() || 0;
    const width = this.getCardWidthInPixels() || 0;
    return this.orientation === Orientation.Portrait
      ? Math.min(height, width)
      : Math.max(height, width);
  }

  withContentBleed(preview: boolean, multiplier: number = 1): number {
    if (preview) return 0;
    if (this.isOnRegularPaper()) {
      const bleedInInches = this.nonPerforatedBleedBorderInInches();
      return bleedInInches * CardsThatLayoutOntoPaper.PIXELS_PER_INCH * multiplier;
    }
    return 0;
  }

}
