import { Deserializable } from '../../protocols/deserializable';
import { ReplaySubject, Subject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../utils/date-utils';
import { MediaType } from '../../enum/dto/media-type.enum';
import { AssetSize } from '../../enum/dto/asset-size.enum';
import { BsError } from '../../shared/bs-error';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { exists } from '../../../functions/exists';

export class AssetUrl implements Deserializable {

  public size: AssetSize;
  public url: string;

  // not from API
  public name: string;
  public assetId: string;
  public md5Hash: string;
  public mediaType: MediaType;
  public srcUrl: ReplaySubject<[AssetSize, string | SafeResourceUrl]>;
  public failedToDownload: Subject<void>;
  public loading: ReplaySubject<boolean>;
  public timeUrlArrivedFromApi: number = -1;
  public downloadStartTime: number;
  public downloadEndTime: number;
  public downloadSize: number;

  // Cache
  public urlAccessDate: number;

  constructor(size?: AssetSize, url?: string) {
    this.size = size;
    this.url = url;
  }

  public buildCacheKey(): string {
    return `Image-${this.assetId}-${this.size}-${this.md5Hash}`;
  }

  public onDeserialize() {
    this.srcUrl = new ReplaySubject<[AssetSize, string | SafeResourceUrl]>(1);
    this.failedToDownload = new Subject<void>();
    this.loading = new ReplaySubject<boolean>(1);
    const timeArrived = this.timeUrlArrivedFromApi;
    if ((timeArrived < 0) || (timeArrived === undefined) || (timeArrived === null)) {
      this.timeUrlArrivedFromApi = DateUtils.nowInUnixSeconds();
    }
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/)?.length > 0;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/)?.length > 0;
  }

  public isPDF(): boolean {
    return this.mediaType.match(/pdf\/*/)?.length > 0;
  }

  public updateDataFrom(updated: AssetUrl): AssetUrl {
    this.size = updated.size;
    this.url = updated.url;
    this.name = updated.name;
    this.assetId = updated.assetId;
    this.md5Hash = updated.md5Hash;
    this.mediaType = updated.mediaType;
    this.timeUrlArrivedFromApi = updated.timeUrlArrivedFromApi;
    return this;
  }

  urlExpired(): boolean {
    const expiresAt = this.timeUrlArrivedFromApi + this.urlExpiresAfterNSeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  urlExpiresAfterNSeconds(): number {
    return DateUtils.unixOneMinute() * 5;
  }

  forceUrlToExpire() {
    this.timeUrlArrivedFromApi = 0;
  }

  loadAssetIntoSrcUrlSubject(cachePolicy: CachePolicy, cacheForNSeconds: number) {
    if (this.url?.includes('/assets/images')) {
      this.setUrlSubject(window.injector.sanitizer.bypassSecurityTrustResourceUrl(this.url));
    } else if (!this.getCachedAsset(cachePolicy, cacheForNSeconds)) {
      const maxRetryCount = this.isVideo() ? 3 : 1;
      if (!window?.injector?.duplicateAssetService?.isDownloading(this)) {
        this.downloadAndCacheBlob(cachePolicy, cacheForNSeconds, 0, maxRetryCount);
      } else {
        this.waitForDownload();
      }
    }
  }

  private waitForDownload() {
    window?.injector?.duplicateAssetService.addToDuplicateQueue(this);
  }

  protected downloadAndCacheBlob(
    cachePolicy: CachePolicy = CachePolicy.Service,
    cacheForNSeconds: number,
    retryCount: number = 0,
    maxRetryCount: number = 3
  ) {
    this.loading.next(true);
    if (this.handleSafariWebmUrl()) return;
    window?.injector?.imageApi?.GetBlobFromUrl(this)?.subscribe({
      next: (blob) => this.readBlobDataIntoUrl(blob, cachePolicy),
      error: (err: BsError) => this.downloadFailed(cachePolicy, cacheForNSeconds, retryCount, maxRetryCount)
    });
  }

  /**
   * Safari is unable to render base64 encoded WEBM videos.
   * Therefore, bypass caching operation and use the direct URL instead (these urls have expiration dates).
   *
   * @returns true if this is webm content and the browser is desktop safari
   */
  protected handleSafariWebmUrl(): boolean {
    const bypass = this.isSafariAndWebm();
    if (bypass) {
      this.srcUrl.next([this.size, this.url]);
      this.loading.next(false);
    }
    return bypass;
  }

  protected readBlobDataIntoUrl(event: HttpEvent<Blob>, cachePolicy: CachePolicy) {
    if (!(event instanceof HttpResponse)) return;
    const blob: Blob = event?.body;
    // Cache the blob
    this.url = '';
    if (blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        const cachedBlob = window?.injector?.cache?.cacheBlob(
          this.buildCacheKey(),
          base64data,
          blob,
          this.mediaType,
          cachePolicy
        );
        // Pass the Blob Url to the srcUrl
        if (exists(cachedBlob)) {
          this.setUrlSubject(cachedBlob.safeUrl);
          window?.injector?.duplicateAssetService?.finishedDownloading(this, cachedBlob.safeUrl);
          this.loading.next(false);
        }
      };
      reader.onerror = () => this.loading.next(false);
      reader.readAsDataURL(blob);
    }
  }

  protected downloadFailed(
    cachePolicy: CachePolicy,
    cacheForNSeconds: number,
    retryCount: number,
    maxRetryCount: number
  ) {
    this.forceUrlToExpire();
    if (retryCount > -1 && retryCount < maxRetryCount) {
      // Retry mechanism for when asset is not available at url yet
      // ie: presigned url is valid, but image-sync is still processing
      const delayTime = (retryCount) * 4000;
      const retry = () => this.downloadAndCacheBlob(cachePolicy, cacheForNSeconds, retryCount + 1, maxRetryCount);
      setTimeout(retry, delayTime);
    } else {
      this.imageDownloadFailed();
      this.loading.next(false);
    }
  }

  private imageDownloadFailed(): void {
    this.failedToDownload.next();
  }

  private getCachedAsset(cachePolicy: CachePolicy, cacheForNSeconds: number): boolean {
    const key = this.buildCacheKey();
    return this.loadCachedBlob(key, this.mediaType, cachePolicy, cacheForNSeconds);
  }

  public loadCachedBlob(
    key: string,
    mediaType: MediaType,
    cachePolicy: CachePolicy,
    cacheForNSeconds: number
  ): boolean {
    const blobSafeUrl = window?.injector?.cache?.getCachedBlob(key, cachePolicy, cacheForNSeconds);
    if (exists(blobSafeUrl)) {
      this.setUrlSubject(blobSafeUrl);
      return true;
    } else {
      return false;
    }
  }

  public duplicateEquals(assetUrl: AssetUrl) {
    return (this.size === assetUrl.size) && (this.assetId === assetUrl.assetId) && (this.md5Hash === assetUrl.md5Hash);
  }

  public setUrlSubjectFromDuplicate(url: string | SafeResourceUrl) {
    this.setUrlSubject(url);
  }

  private setUrlSubject(url: string | SafeResourceUrl) {
    this.srcUrl.next([this.size, url]);
    this.urlAccessDate = DateUtils.nowInUnixSeconds();
    this.loading.next(false);
  }

  /**
   * Different from dashboard app. Handles display app when inside iFrame (within live view).
   */
  public isSafariAndWebm(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && this.mediaType?.includes('webm');
  }

}
