import { OrientationError } from '../models/enum/dto/orientation-error.enum';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { OpacityLocation } from '../models/shared/opacity-location.enum';
import { CannabinoidDisplayType } from '../models/enum/shared/cannabinoid-display-type.enum';
import { MenuType } from '../models/enum/dto/menu-type.enum';
import { SectionType } from '../models/enum/dto/section-type.enum';
import { ProductType } from '../models/enum/dto/product-type.enum';
import { TextSizeLink } from '../models/enum/dto/text-size-link.enum';
import { ColorUtils } from './color-utils';
import { StrainType } from '../models/enum/dto/strain-classification.enum';
import { StyleUtils } from './style-utils';
import { PrintFooterLayoutType } from '../models/enum/shared/print-footer-layout-type.enum';
import { StrainTypeUtils } from './strain-type-utils';
import { BandedRowMode } from '../models/enum/shared/banded-row-mode.enum';
import { Position } from '../models/enum/shared/position.enum';
import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import { SpecificPriceWithoutDiscounts } from '../models/enum/shared/specific-price-without-discounts.enum';
import { ShelfTalkerCardSize } from '../models/enum/dto/shelf-talker-card-size';
import { SectionColumnConfigDataValue } from '../models/enum/shared/section-column-config-data-value';
import { SectionColumnCannabinoidType } from '../models/enum/shared/section-column-cannabinoid-type';
import { SectionColumnProductInfoType } from '../models/enum/shared/section-column-product-info-type';
import { SectionColumnTerpeneType } from '../models/enum/shared/section-column-terpene-type';

// @dynamic
export class TypeUtils {

  static BandedRowMode: typeof BandedRowMode = BandedRowMode;
  static CannabinoidDisplayType: typeof CannabinoidDisplayType = CannabinoidDisplayType;
  static ColorUtils: typeof ColorUtils = ColorUtils;
  static ImageSize: typeof AssetSize = AssetSize;
  static LocationPriceStream: typeof LocationPriceStream = LocationPriceStream;
  static MenuType: typeof MenuType = MenuType;
  static OpacityLocation: typeof OpacityLocation = OpacityLocation;
  static OrientationError: typeof OrientationError = OrientationError;
  static Position: typeof Position = Position;
  static PrintFooterLayoutType: typeof PrintFooterLayoutType = PrintFooterLayoutType;
  static ProductType: typeof ProductType = ProductType;
  static SectionColumnCannabinoidType: typeof SectionColumnCannabinoidType = SectionColumnCannabinoidType;
  static SectionColumnProductInfoType: typeof SectionColumnProductInfoType = SectionColumnProductInfoType;
  static SectionColumnTerpeneType: typeof SectionColumnTerpeneType = SectionColumnTerpeneType;
  static SectionConfigDataValue: typeof SectionColumnConfigDataValue = SectionColumnConfigDataValue;
  static SectionType: typeof SectionType = SectionType;
  static ShelfTalkerCardSize: typeof ShelfTalkerCardSize = ShelfTalkerCardSize;
  static SpecificPriceWithoutDiscounts: typeof SpecificPriceWithoutDiscounts = SpecificPriceWithoutDiscounts;
  static StrainType: typeof StrainType = StrainType;
  static StrainTypeUtils: typeof StrainTypeUtils = StrainTypeUtils;
  static StyleUtils: typeof StyleUtils = StyleUtils;
  static TextSizeLink: typeof TextSizeLink = TextSizeLink;

}
