import { FiveBySevenShelfTalker } from './five-by-seven-shelf-talker';
import { Type } from '@angular/core';
import { DougFiveBySevenBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/custom-cards-5x7/doug-five-by-seven-background/doug-five-by-seven-background.component';
import { Doug5By7ShelfTalkerContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/custom-cards-5x7/doug-5-by-7-shelf-talker-content/doug-5-by-7-shelf-talker-content.component';
import type { GroupedByBrandShelfTalkerCardData } from '../../../../shelf-talkers/grouped-by-brand-shelf-talker-card-data';
import type { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import type { ShelfTalkerCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/shelf-talker-card-content.component';

export class Doug5By7ShelfTalkerCard extends FiveBySevenShelfTalker {

  getCardBackgroundComponent(data: GroupedByBrandShelfTalkerCardData): Type<CardBackgroundComponent> {
    return DougFiveBySevenBackgroundComponent;
  }

  getCardContentComponent(data: GroupedByBrandShelfTalkerCardData): Type<ShelfTalkerCardContentComponent> {
    return Doug5By7ShelfTalkerContentComponent;
  }

  public nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
