import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ScaleDownTextDirective } from '../scale-down-text/scale-down-text.directive';
import { ScaleDownService } from '../scale-down-text/scale-down.service';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import fastdom from 'fastdom';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { exists } from '../../functions/exists';

@Directive({
  selector: '[appScaleDownTextAndAdjustLines]'
})
export class ScaleDownTextAndAdjustLinesDirective extends ScaleDownTextDirective implements OnChanges {

  constructor(
    renderer2: Renderer2,
    elementRef: ElementRef,
    service: ScaleDownService
  ) {
    super(renderer2, elementRef, service);
  }

  @Input() maxFontSizeInPx: number;

  protected _maxFontSizeInPx = new BehaviorSubject<number>(0);
  public maxFontSizeInPx$ = this._maxFontSizeInPx as Observable<number>;
  public minFontSizeInPx$ = this.maxFontSizeInPx$.pipe(
    map(maxFontSize => Math.floor(maxFontSize * 0.7))
  );

  private readonly SIZE_INCREMENT = 1;

  override observeResize() {
    const windowChange$ = this.service.windowChange$.pipe(tap(() => this.removeWhiteSpaceAndFontSizeAttribute()));
    const link$ = this.service.connectToScaleDownMap().pipe(
      map(m => m.get(this.scaleDownLinkedKey)),
      debounceTime(1),
      distinctUntilChanged()
    );
    const s = combineLatest([
      this.minFontSizeInPx$,
      this.enabled,
      this.entry.notNull(),
      link$,
      windowChange$
    ]).pipe(debounceTime(1)).subscribe(([minFontSize, enabled, entry, linked, ]) => {
      if (enabled) {
        fastdom.measure(() => {
          const fontSize = (window.getComputedStyle(entry.target).fontSize);
          const stripUnits = fontSize.replace('px', '');
          let sizeInt = parseInt(stripUnits, 10);
          const cr = entry.contentRect;
          const sw = Math.floor(entry.target.scrollWidth);
          const w = Math.floor(cr.width);
          const swBuffer = 2;
          // If linked value is smaller than my value, then set myself to linked
          if (linked && (linked < sizeInt)) {
            this.temporarilyMakeTextTransparent();
            fastdom.mutate(() => {
              this.renderer2.setStyle(this.elementRef.nativeElement, 'font-size', `${linked}px`);
              this.renderer2.setStyle(this.elementRef.nativeElement, 'white-space', 'pre');
            });
          }
          if ((sw - swBuffer - w) > 0) {
            sizeInt -= this.SIZE_INCREMENT;
            if (!linked || (sizeInt < linked) || (sizeInt >= minFontSize)) {
              if (sizeInt >= minFontSize) {
                this.temporarilyMakeTextTransparent();
                fastdom.mutate(() => {
                  this.renderer2.setStyle(this.elementRef.nativeElement, 'font-size', `${sizeInt}px`);
                  this.renderer2.setStyle(this.elementRef.nativeElement, 'white-space', 'pre');
                });
                if (exists(this.scaleDownLinkedKey)) {
                  this.service.setValueInMap(this.scaleDownLinkedKey, sizeInt);
                }
              } else {
                fastdom.mutate(() => {
                  this.renderer2.setStyle(this.elementRef.nativeElement, 'white-space', 'pre-line');
                });
              }
            }
          }
        });
      } else {
        this.removeWhiteSpaceAndFontSizeAttribute();
      }
    });
    this.pushSub(s);
  }

  protected removeWhiteSpaceAndFontSizeAttribute() {
    fastdom.mutate(() => {
      this.renderer2.removeStyle(this.elementRef.nativeElement, 'font-size');
      this.renderer2.removeStyle(this.elementRef.nativeElement, 'white-space');
    });
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.maxFontSizeInPx) this._maxFontSizeInPx.next(this.maxFontSizeInPx);
  }

}
