import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import type { CardData } from '../../../../../models/print-cards/card-data';
import { map } from 'rxjs/operators';
import { SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { UnitOfMeasure } from '../../../../../models/enum/dto/unit-of-measure.enum';
import { ProductType } from '../../../../../models/enum/dto/product-type.enum';
import { VariantTypeUtils } from '../../../../../utils/variant-type-utils';

@Pipe({
  name: 'cardHeaderUnitSize'
})
export class CardHeaderUnitSizePipe implements PipeTransform {

  transform(
    isLineItemMode$: Observable<boolean>,
    cardData$: Observable<CardData>,
    gridColViewModels$: Observable<SectionColumnViewModel[]>,
    hasQtyColumn: boolean
  ): Observable<string|null> {
    return combineLatest([
      isLineItemMode$,
      cardData$,
      gridColViewModels$
    ]).pipe(
      map(([isLineItemMode, cardData, gridColVMs]) => {
        return (isLineItemMode && !hasQtyColumn)
          ? this.getHeaderSizeForLineItemModeWithNoQtyColumn(cardData)
          : this.getSizeIfInGridMode(isLineItemMode, cardData, gridColVMs);
      })
    );
  }

  private getHeaderSizeForLineItemModeWithNoQtyColumn(cardData: CardData): string|null {
    const productType = cardData?.rowProductType();
    const isNonCannabinoidProduct = cardData?.isNonCannabinoidVariant();
    if (isNonCannabinoidProduct) {
      return null;
    } else if (productType === ProductType.Seed) {
      const qty = cardData?.getQuantity();
      return qty > 0 ? `${qty}pk` : null;
    } else if (productType === ProductType.Beverage || productType === ProductType.Edible) {
      const size = cardData?.getListItemQty();
      return size === '-' ? null : size;
    } else {
      const variantType = cardData?.rowVariantType();
      const size = cardData?.getSize();
      if (VariantTypeUtils.isCapsuleType(variantType)) {
        return size === '-' ? null : size.replace('cap', 'Capsule');
      }
      return size === '-' ? null : size;
    }
  }

  private getSizeIfInGridMode(
    isLineItemMode: boolean,
    cardData: CardData,
    gridColVMs: SectionColumnViewModel[]
  ): string|null {
    const productType = cardData?.rowProductType();
    const variantType = cardData?.rowVariantType();
    const isNonCannabinoidProduct = cardData?.isNonCannabinoidOtherVariant();
    const units = Object.values(UnitOfMeasure);
    const colTitles = gridColVMs?.map(c => c?.columnTitle);
    const colTitleIsUnitSize = colTitles?.some(title => units?.some(unit => title?.includes(unit)));
    const returnNull = isLineItemMode
       || isNonCannabinoidProduct
       || productType === ProductType.Seed
       || VariantTypeUtils.isCapsuleType(variantType)
       || colTitleIsUnitSize;
    if (returnNull) return null;
    return cardData?.allVariantsHaveTheSameUnitSizeOrNull();
  }

}
