import { PostCardShelfTalker } from './post-card-shelf-talker';
import { Type } from '@angular/core';
import { DougShelfTalkerPostCardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/doug-shelf-talker-post-card-background/doug-shelf-talker-post-card-background.component';
import { DougShelfTalkerPostCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/doug-shelf-talker-post-card-content/doug-shelf-talker-post-card-content.component';
import type { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import type { ShelfTalkerCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/shelf-talker-card-content.component';
import type { GroupedByBrandShelfTalkerCardData } from '../../../../shelf-talkers/grouped-by-brand-shelf-talker-card-data';

export class DougPostCardShelfTalker extends PostCardShelfTalker {

  getCardBackgroundComponent(data: GroupedByBrandShelfTalkerCardData): Type<CardBackgroundComponent> {
    return DougShelfTalkerPostCardBackgroundComponent;
  }

  getCardContentComponent(data: GroupedByBrandShelfTalkerCardData): Type<ShelfTalkerCardContentComponent> {
    return DougShelfTalkerPostCardContentComponent;
  }

  public nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
