<app-card-jig
  *ngIf="viewModel.cardGrouping$ | async as data"
  [style.width.px]="viewModel.cardsThatLayoutOntoPaper$ | async | cardPreviewWidthInPx"
  [style.height.px]="viewModel.cardsThatLayoutOntoPaper$ | async | cardPreviewHeightInPx"
  [preview]="true"
  [data]="data">
</app-card-jig>
<div
  *ngIf="viewModel.showWhiteForegroundForShelfTalkerLoading$ | async"
  class="blank-white-card-for-initial-load"></div>
