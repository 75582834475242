import { Injectable } from '@angular/core';
import type { CardData } from '../../../../../../models/print-cards/card-data';
import { map, shareReplay } from 'rxjs/operators';
import { SectionLayoutType } from '../../../../../../models/enum/dto/section-layout-type.enum';
import { ActivatedRoute } from '@angular/router';
import { exists } from '../../../../../../functions/exists';
import { ProductStylingViewModel } from '../../../../../../models/shared/product-styling-view-model';
import { VariantAssetService } from '../../../../../services/variant-asset-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { LabelStyle } from '../../../../../../models/enum/shared/label-style.enum';
import { PrintCardColumnConfig } from '../../../../../../models/menu/section/print-card-column-config';
import { Variant } from '../../../../../../models/product/dto/variant';
import { PrintCardColumnViewModel } from '../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import { Cannabinoid } from '../../../../../../models/enum/shared/cannabinoid';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { Terpene } from '../../../../../../models/enum/shared/terpene';
import { SectionColumnConfigDataValue } from '../../../../../../models/enum/shared/section-column-config-data-value';
import { SectionColumnConfigState } from '../../../../../../models/enum/shared/section-column-config-state';
import { SectionColumnConfigCollectiveTerpeneKey } from '../../../../../../models/enum/shared/section-column-config-collective-terpene-key';
import { SectionColumnProductInfoType } from '../../../../../../models/enum/shared/section-column-product-info-type';
import { EnumUtils } from '../../../../../../utils/enum-utils';

export type CannabinoidOrTerpeneFromPool = { title: string, value: string };
export type PropertyPoolSorter = (a: CannabinoidOrTerpeneFromPool, b: CannabinoidOrTerpeneFromPool) => number;

@Injectable()
export abstract class PrintCardContentViewModel extends ProductStylingViewModel {

  constructor(
    protected activatedRoute: ActivatedRoute,
    variantAssetService: VariantAssetService
  ) {
    super(variantAssetService);
  }

  connectToData = (data: CardData) => {
    this.connectToMenu(data?.menu || null);
    this.connectToSection(data?.section || null);
    this.connectToRowViewModel(data || null);
  };

  private readonly _preview = new BehaviorSubject<boolean>(false);
  public readonly preview$ = this._preview as Observable<boolean>;
  connectToPreview = (preview: boolean) => this._preview.next(preview);

  public cardStackMenu$ = this.menu$;
  public cardStack$ = this.section$;
  public cardData$ = this.rowViewModel$.pipe(
    map(cardData => cardData as CardData ?? null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationConfig$ = this.cardData$.pipe(map(cardData => cardData?.locationConfig ?? null));
  public companyConfig$ = this.cardData$.pipe(map(cardData => cardData?.companyConfig ?? null));
  public locationId$ = this.locationConfig$.pipe(map(config => config?.locationId ?? null));
  public headerTextColor$ = this.cardData$.pipe(map(cardData => cardData?.getHeaderTextColor() ?? null));
  public bodyTextColor$ = this.cardData$.pipe(map(cardData => cardData?.getBodyTextColor() ?? null));
  public cardAccentColor$ = this.cardData$.pipe(map(cardData => cardData?.getAccentColor() ?? null));
  public cardColor$ = this.cardData$.pipe(map(cardData => cardData?.getCardColor() ?? null));
  public isAccessory$ = this.cardData$.pipe(map(cardData => cardData?.isAccessory() ?? false));
  public brand$ = this.cardData$.pipe(map(cardData => cardData?.getBrand()));
  public isNonCannabinoidOtherVariant$ = this.cardData$.pipe(map(cd => cd?.isNonCannabinoidOtherVariant() ?? false));
  public productName$ = this.cardData$.pipe(map(cardData => cardData?.getRowTitle() ?? null));
  public productSize$ = this.cardData$.pipe(map(cardData => cardData?.getSize() ?? null));
  public productSizeExists$ = this.productSize$.pipe(map(size => exists(size) && size !== '-' && size !== '0'));
  public productTHC$ = this.cardData$.pipe(map(cardData => cardData?.getCannabinoid('THC') ?? null));
  public productCBD$ = this.cardData$.pipe(map(cardData => cardData?.getCannabinoid('CBD') ?? null));
  public hasBadges$ = this.cardData$.pipe(map(cardData => cardData?.hasBadges() ?? false));
  public hasLabel$ = this.labelText$.pipe(map(cardData => exists(cardData)));
  public strainText$ = this.cardData$.pipe(map(cardData => cardData?.getReadableStrainType() ?? null));
  public strainName$ = this.cardData$.pipe(map(cardData => cardData?.getStrainNameOrNull() ?? null));
  public variantId$ = this.cardData$.pipe(map(cardData => cardData?.rowVariants?.firstOrNull()?.id ?? null));
  public isNonCannabinoidVariant$ = this.cardData$.pipe(map(cd => cd?.isNonCannabinoidVariant() ?? false));
  public isCannabinoidVariant$ = this.isNonCannabinoidVariant$.pipe(map(isNonCannabinoid => !isNonCannabinoid));

  public badgeOrLabelVisible$ = combineLatest([
    this.hasBadges$,
    this.labelText$
  ]).pipe(
    map(([hasBadges, labelText]) => hasBadges || exists(labelText))
  );

  public labelIsFlagStyle$ = combineLatest([
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    map(([locationConfig, companyConfig]) => {
      return locationConfig?.labelStyle === LabelStyle.FLAG || companyConfig?.labelStyle === LabelStyle.FLAG;
    }),
  );

  public isLineItemMode$ = this.cardData$.pipe(
    map(cardData => cardData?.variantLineItemMode)
  );

  public viewingInLiveView$ = this.activatedRoute.url.pipe(
    map(segments => exists(segments?.find(segment => segment?.path === 'live-view')))
  );

  public spoofedColViewModel$ = this.cardData$.pipe(
    map(cardData => {
      const variant = cardData?.rowVariants?.firstOrNull();
      const layoutType = cardData?.section?.layoutType;
      return this.getSpoofedColumnVM(cardData, variant, layoutType);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public spoofedGridColViewModels$ = this.cardData$.pipe(
    map(cardData => this.getSpoofedGridColumnVMs(cardData)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public atLeastOneGridVariantOnSale$ = combineLatest([
    this.locationConfig$,
    this.cardData$,
    this.cardStackMenu$,
    this.cardStack$,
    this.spoofedGridColViewModels$
  ]).pipe(
    map(([locationConfig, cardData, menu, cardStack, gridColVms]) => {
      return gridColVms?.some(sectionColViewModel => {
        const priceStream = locationConfig?.priceFormat;
        const locationId = locationConfig?.locationId;
        return menu?.isVariantPriceDiscounted(priceStream, locationId, cardStack, cardData, sectionColViewModel);
      });
    })
  );

  protected getSpoofedColumnVM(
    cardData: CardData,
    variant: Variant,
    sectionLayoutType = SectionLayoutType.List
  ): PrintCardColumnViewModel {
    const spoofedColumnConfig = new PrintCardColumnConfig();
    const priceType = cardData?.section?.priceType() || SectionColumnConfigDataValue.OriginalAndSalePrice;
    spoofedColumnConfig.dataValue = priceType;
    const spoofed = new PrintCardColumnViewModel(sectionLayoutType, spoofedColumnConfig);
    spoofed.state = SectionColumnConfigState.On;
    spoofed.columnType = SectionColumnProductInfoType.VariantPrice;
    spoofed.secondaryPriceMode = priceType;
    spoofed.data = priceType;
    spoofed.variant = variant;
    return spoofed;
  }

  protected getSpoofedGridColumnVMs(cardData: CardData): PrintCardColumnViewModel[] {
    return cardData?.rowVariants?.map(variant => {
      const spoofed = this.getSpoofedColumnVM(cardData, variant, SectionLayoutType.Grid);
      const layoutType = SectionLayoutType.Grid;
      const locationId = cardData?.locationConfig?.locationId;
      spoofed.columnTitle = variant?.getGridNames(layoutType, locationId)?.firstOrNull();
      return spoofed;
    });
  }

  public getGridSize$(columnVM: PrintCardColumnViewModel): Observable<string> {
    return this.cardData$.pipe(
      map(cardData => {
        const variant = cardData?.getVariantFromGridColumn(columnVM);
        const locationId = cardData?.locationConfig?.locationId;
        return variant?.getGridNames(SectionLayoutType.Grid, locationId)?.firstOrNull()?.replace(/\s/g, '');
      })
    );
  }

  public getGridVariantId$(columnVM: PrintCardColumnViewModel): Observable<string> {
    return this.cardData$.pipe(
      map(cardData => {
        const variant = cardData?.getVariantFromGridColumn(columnVM);
        return variant?.id;
      })
    );
  }

  public getExpandedGridSize$(
    columnVM: PrintCardColumnViewModel,
    removeSpacing: boolean = true,
    upperCaseUnits: boolean = true
  ): Observable<string> {
    return this.cardData$.pipe(
      map(cardData => {
        const variant = columnVM?.variant;
        const size = cardData?.getGridQuantityAndSizeString(variant);
        const split = size?.split(' ');
        const lastIndex = split?.length - 1;
        if (lastIndex > -1 && upperCaseUnits) split[lastIndex] = split[lastIndex]?.toUpperCase();
        if (split?.includes('CAPS') || split?.includes('CAP')) return split?.join(' ');
        return split?.join(removeSpacing ? '' : ' ');
      })
    );
  }

  public readonly maxNumberOfPropertiesToUseForPropertyBasedPooling$ = this.cardData$.pipe(
    map(cardData => {
      const numVisibleCompounds = cardData?.menu?.hydratedTheme?.printConfig?.visibleCompoundMap;
      const cardSize = cardData?.menu?.metadata?.printCardSize;
      return numVisibleCompounds?.get(cardSize) ?? 0;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public propertiesBasedOnPools$ = combineLatest([
    this.cardData$,
    this.maxNumberOfPropertiesToUseForPropertyBasedPooling$
  ]).pipe(
    map(([data, nProperties]) => this.getProductPropertiesBasedOnPools(data, nProperties)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasPropertiesBasedOnPools$ = this.propertiesBasedOnPools$.pipe(
    map(properties => properties?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected getProductPropertiesBasedOnPools(
    cardData: CardData,
    maxProps: number
  ): CannabinoidOrTerpeneFromPool[] {
    if (cardData?.isNonCannabinoidVariant()) {
      return [];
    }
    const properties: CannabinoidOrTerpeneFromPool[] = this.getPrimaryPoolOfProductProperties(cardData, maxProps);
    const remainingPropertiesSpaces = maxProps - (properties?.length ?? 0);
    const secondaryProperties = this.getSecondaryPoolOfProductProperties(cardData, remainingPropertiesSpaces);
    secondaryProperties?.forEach(sp => properties.push(sp));
    const sortFinalListOfProductPropertiesInPool = this.finalSortOnProductPropertiesInPoolLambda();
    if (sortFinalListOfProductPropertiesInPool) {
      properties.sort(sortFinalListOfProductPropertiesInPool);
    }
    return properties;
  }

  protected finalSortOnProductPropertiesInPoolLambda(): PropertyPoolSorter | null {
    return null;
  }

  protected propertyAsStringExists(value: string): boolean {
    return exists(value) && value !== '-' && value !== '--';
  }

  protected getPrimaryPoolOfProductProperties(
    cardData: CardData,
    maxProps: number
  ): CannabinoidOrTerpeneFromPool[] {
    const totalTerpenes = cardData?.getTerpene(SectionColumnConfigCollectiveTerpeneKey.TotalTerpene);
    if (this.propertyAsStringExists(totalTerpenes)) {
      return [{ title: 'TERPS', value: totalTerpenes }];
    }
    return [];
  }

  /**
   * Defaults to cannabinoids only.
   */
  protected whatPropertiesToUseForSecondaryPoolOfProductProperties(cardData: CardData): (Cannabinoid | Terpene)[] {
    return [
      Cannabinoid.CBD,
      Cannabinoid.THC,
      ...(cardData?.companyConfig?.enabledCannabinoids || [])
    ];
  }

  /**
   * const x = ['1', '2', '4', '3', '0.001', '', '-', '--', null, undefined];
   * x.filter(exists).sort((a, b) => SortUtils.parseFloatFromStringAscending(a, b));
   *   ["0.001", "1", "2", "3", "4", "-", "--"]
   * x.filter(exists).sort((a, b) => SortUtils.parseFloatFromStringDescending(a, b));
   *   ["4", "3", "2", "1", "0.001", "-", "--"]
   */
  protected getSecondaryPoolOfProductProperties(
    cardData: CardData,
    remainingPropertiesSpaces: number
  ): CannabinoidOrTerpeneFromPool[] {
    if (remainingPropertiesSpaces <= 0) return [];
    const propertiesToLookThrough = this.whatPropertiesToUseForSecondaryPoolOfProductProperties(cardData);
    let properties: CannabinoidOrTerpeneFromPool[] = [];
    const allCannabinoidKeys = EnumUtils.getCannabinoids();
    const allTerpeneKeys = EnumUtils.getTerpenes();
    propertiesToLookThrough?.forEach(cannabinoidOrTerpene => {
      if (allCannabinoidKeys.includes(cannabinoidOrTerpene as Cannabinoid)) {
        properties.push({ title: cannabinoidOrTerpene, value: cardData?.getCannabinoid(cannabinoidOrTerpene) });
      } else if (allTerpeneKeys.includes(cannabinoidOrTerpene as Terpene)) {
        properties.push({ title: cannabinoidOrTerpene, value: cardData?.getTerpene(cannabinoidOrTerpene) });
      }
    });
    properties = properties
      ?.filter(this.getSecondaryPoolOfProductPropertiesFilterLambda())
      ?.sort(this.getSecondaryPoolOfProductPropertiesSortLambda());
    return properties?.take(remainingPropertiesSpaces);
  }

  protected getSecondaryPoolOfProductPropertiesFilterLambda(): (a: CannabinoidOrTerpeneFromPool) => boolean {
    return (a: CannabinoidOrTerpeneFromPool) => this.propertyAsStringExists(a?.value);
  }

  protected getSecondaryPoolOfProductPropertiesSortLambda(): PropertyPoolSorter {
    return (a: CannabinoidOrTerpeneFromPool, b: CannabinoidOrTerpeneFromPool) => {
      return SortUtils.parseFloatFromStringDescending(a?.value, b?.value);
    };
  }

}
