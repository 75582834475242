import { Deserializable } from '../../protocols/deserializable';
import { Size } from '../../shared/size';
import { DisplayOptions } from '../../shared/display-options';
import { DateUtils } from '../../../utils/date-utils';
import { Cachable } from '../../protocols/cachable';
import { Menu } from '../../menu/menu';
import { SortUtils } from '../../../utils/sort-utils';
import { MarketingLoopingContentMenu } from '../../menu/marketing/marketing-looping-content-menu';
import { ProductMenu } from '../../menu/product-menu';
import { MarketingFeaturedCategoryStaticGridMenu } from '../../menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { FeaturedCategoryUtils } from '../../../utils/featured-category-utils';
import { MarketingMenu } from '../../menu/marketing-menu';
import { PolymorphicDeserializationKey } from '../../enum/shared/polymorphic-deserialization-key.enum';
import { PagableObject } from '../../protocols/pagable-object';
import { MarketingSmartPlaylistMenu } from '../../menu/marketing/marketing-smart-playlist-menu';
import { Section } from '../../menu/section/section';
import { exists } from '../../../functions/exists';
import { MarketingUrlPlaylistMenu } from '../../menu/marketing/marketing-url-playlist-menu';

/**
 * This object lives within display inception. What does that mean Kevin?
 * This object can represent a display and a template collection, and has references to itself within
 * the templateCollections property.
 *
 * This means we can think of the display object as a file system tree. The tree only stores menus.
 * The root folder represents the display that the external world will see, and all child folders
 * (template collections) can contain more folders or menus.
 *
 * The folders get flattened into a single parent folder upon deserialization, so the tree becomes
 * a single node of viewable content to the external world. This single node has the correct shape to
 * go down all preexisting display pipelines.
 */
export class Display implements Deserializable, Cachable, PagableObject {

  public id: string;
  public locationId: number;
  public companyId: number;
  public name: string;
  public url: string;
  public priority: number;
  public configurationIds: string[];
  public configurations: Menu[];
  public displaySize: Size;
  public options: DisplayOptions;
  public lastSession: number;
  public pagingKey: string;
  public lastModified: number;

  // Set within onDeserialize - not from API
  public activeMenus: Menu[];
  public intervalMenus: Menu[];

  // Template collections added to display
  public templateCollectionIds: string[];
  public templateCollections: Display[]; // deleted after decodeAndFlattenDisplayTemplateCollectionsIntoMenuPool runs

  /*
   * If this display object represents a template collection
   * See clientModels.TemplateCollection
   * https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/clientModels/TemplateCollection.go
   */
  public templateIds: string[];  // do not delete, is needed in order to know if isTemplateCollection()
  public templates: Menu[];      // deleted after makeTemplateCollectionViewable runs
  public templatedMenus: Menu[]; // deleted after makeTemplateCollectionViewable runs

  // Cache
  public cachedTime: number;

  static buildArrayCacheKey(companyId, locationId: number): string {
    return `Displays-${companyId}-${locationId}`;
  }

  static buildCacheKey(displayId: string): string {
    return `Display-${displayId}`;
  }

  public getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Display;
  }

  /**
   * Order matters within this function.
   * Add template pointers before deserializing, so pointer data is available upon deserialization.
   * SetOrientationOfMenusBasedOnDisplay must be called before checkForMenuColumnCountDiscrepancies, so that
   * menu column counts are adjusted to the display's orientation.
   *
   * The concept of template collections is removed from the display app after deserialization. This means we can
   * safely delete the templateCollections property, which allows the garbage collector to traverse that data
   * and cleanup anything that isn't being used (free up memory).
   */
  public onDeserialize() {
    if (this.isTemplateCollection()) this.addTemplatePointersToTemplatedObjects();
    const Deserialize = window?.injector?.Deserialize;
    this.configurationIds = Array.from(this.configurationIds || []);
    this.configurations = Deserialize?.arrayOf(Menu, this.configurations) ?? [];
    this.displaySize = Deserialize?.instanceOf(Size, this.displaySize);
    this.options = Deserialize?.instanceOf(DisplayOptions, this.options);
    this.templateCollections = Deserialize?.arrayOf(Display, this.templateCollections) ?? [];
    this.templateIds = Array.from(this.templateIds || []);
    this.templates = Deserialize?.arrayOf(Menu, this.templates) ?? [];
    this.templatedMenus = Deserialize?.arrayOf(Menu, this.templatedMenus) ?? [];
    if (this.isTemplateCollection()) this.makeTemplateCollectionViewable();
    if (this.isDisplayWithAddedTemplateCollections()) {
      this.decodeAndFlattenDisplayTemplateCollectionsIntoMenuPool();
      delete this.templateCollections;
    }
    this.decodeDisplayIntervals();
    this.setOrientationOfMenusBasedOnDisplay();
    this.checkForMenuColumnCountDiscrepancies();
    this.filterAndSortActiveMenus();
  }

  /**
   * Don't use class methods or types in here. The pointers are added before the data is deserialized,
   * so the data is in a raw JavaScript object format.
   */
  private addTemplatePointersToTemplatedObjects() {
    this.configurations?.forEach(configuration => {
      configuration.template = this.templates.find(t => t?.id === configuration?.templateId);
      Section.setTemplatePointers(configuration);
    });
    this.templatedMenus?.forEach(templatedMenu => {
      templatedMenu.template = this.templates.find(t => t?.id === templatedMenu?.templateId);
      Section.setTemplatePointers(templatedMenu);
    });
  }

  /**
   * Push template collection data into root node (top level display). This gets rid of the concept of template
   * collections, which significantly reduces the complexity down stream. This means we can safely delete
   * the templates and templatedMenus properties, which allows the garbage collector to traverse that data
   * and cleanup anything that isn't being used (free up memory).
   */
  private makeTemplateCollectionViewable(): void {
    this.templateCollectionAddTemplatesAndTemplatedMenusToConfigurationPool();
    this.templateCollectionSwapTemplateOptionKeysForTemplatedMenuOptionKeys();
    delete this.templates;
    delete this.templatedMenus;
  }

  /**
   * Transform the template collection to look like a display with menus added to it.
   * We do not want to filter out templates that do not have corresponding templated menus.
   * This is so users can see what the template collection would look like at a given location.
   * Templates without templated menus will be filtered out on DISPLAYS (an object representing a physical display).
   * This filter is done in case a location does not want a specific template added to their template rotation.
   * See decodeAndFlattenDisplayTemplateCollectionsIntoMenuPool for details on filtering out
   * templates without templated menus.
   */
  private templateCollectionAddTemplatesAndTemplatedMenusToConfigurationPool(): void {
    this.templates?.forEach(menuTemplate => {
      const templatedMenu = this.templatedMenus?.find(it => it?.templateId === menuTemplate?.id);
      if (exists(templatedMenu)) {
        this.configurationIds.push(templatedMenu?.id);
        templatedMenu.combineWithTemplateData(templatedMenu?.template);
        this.configurations.push(templatedMenu);
      } else {
        const existingMenu = this.configurations?.find(regular => regular?.templateId === menuTemplate?.id);
        if (exists(existingMenu)) {
          existingMenu.combineWithTemplateData(menuTemplate);
        } else {
          this.configurationIds.push(menuTemplate.id);
          this.configurations.push(menuTemplate);
        }
      }
    });
  }

  /**
   * Template ids are stored inside the option map on template collections. This means if a templated
   * menu exists, then the time intervals, rotation order, and overrides will be stored under the
   * template id. We need to swap the template id for the templated menu id so that we know how long
   * to show the templated menu for, what order to show it in, and what overrides to apply.
   */
  private templateCollectionSwapTemplateOptionKeysForTemplatedMenuOptionKeys(): void {
    const updateMap = (templateId: string, templatedId: string, map: Map<string, any>) => {
      if (map?.has(templateId)) {
        map?.set(templatedId, map?.get(templateId));
        map?.delete(templateId);
      }
    };
    const mapToTemplatedId = (id: string) => {
      const templatedMenu = this.templatedMenus?.find(it => it?.id === id);
      if (templatedMenu) {
        updateMap(templatedMenu?.templateId, templatedMenu?.id, this.options?.rotationOrder);
        updateMap(templatedMenu?.templateId, templatedMenu?.id, this.options?.rotationInterval);
        updateMap(templatedMenu?.templateId, templatedMenu?.id, this.options?.overrides);
        return templatedMenu?.id;
      }
      return id;
    };
    this.configurationIds = this.configurationIds?.map(mapToTemplatedId)?.unique() ?? [];
  }

  /**
   * Templates without templated menus will be filtered out. This filtering is done in case a location does
   * not want a specific template added to their template rotation.
   */
  private decodeAndFlattenDisplayTemplateCollectionsIntoMenuPool(): void {
    this.templateCollections?.forEach(templateCollection => {
      const position = this.options?.rotationOrder?.get(templateCollection?.id);
      this.options?.rotationOrder?.delete(templateCollection?.id);
      templateCollection?.transformTemplateCollectionMenuIdsToAllowForDuplicateMenusAcrossCollections();
      templateCollection?.configurations?.forEach(menu => {
        if (menu?.isTemplateActiveAtLocation(this.locationId)) {
          const menuId = menu?.id;
          this.configurationIds?.push(menuId);
          this.configurations?.push(menu);
          // add rotation order to display
          const subPosition = templateCollection?.options?.rotationOrder?.get(menuId);
          if (subPosition >= 0) this.options?.rotationOrder?.set(menuId, position + (subPosition / 1000));
          // add interval to display
          const interval = templateCollection?.options?.rotationInterval?.get(menuId);
          if (interval !== 0) this.options?.rotationInterval?.set(menuId, interval);
          // add override to display
          const override = templateCollection?.options?.overrides?.get(menuId);
          if (exists(override)) this.options?.overrides?.set(menuId, override);
        }
      });
    });
  }

  /**
   * Some menus store a loop count instead of the displayed time in seconds.
   * In these cases, we need to convert the loop counts into (content time x loop count),
   * which equals the display time in seconds.
   */
  private decodeDisplayIntervals(): void {
    this.configurations?.filter(menu => !menu?.intervalDecoded)?.forEach(menu => {
      this.decodeProductMenuSectionLevelTransitionDisplayIntervalsFromLoopCount(menu);
      this.decodeMarketingFeaturedCategoryDisplayIntervalsFromLoopCount(menu);
      this.decodeMarketingLoopingContentDisplayIntervalsFromLoopCount(menu);
    });
  }

  private decodeProductMenuSectionLevelTransitionDisplayIntervalsFromLoopCount(menu: Menu) {
    if (menu instanceof ProductMenu && menu?.isSectionLevelOverflow()) {
      const loopCount = this.options?.rotationInterval?.get(menu?.id);
      const lengthOfOneLoopInSeconds = menu?.calculateSectionLevelOverflowLongestContentLoopInSeconds();
      const duration = (loopCount * lengthOfOneLoopInSeconds) || Menu.defaultRotationInterval;
      this.options?.rotationInterval?.set(menu?.id, duration);
      menu.intervalDecoded = true;
    }
  }

  private decodeMarketingFeaturedCategoryDisplayIntervalsFromLoopCount(menu: Menu) {
    if (menu instanceof MarketingFeaturedCategoryStaticGridMenu) {
      const loopCount = this.options?.rotationInterval?.get(menu?.id);
      if (FeaturedCategoryUtils.cardTypeIsForStaticGridMenu(menu?.metadata?.cardType)) {
        const lengthOfOneLoopInSeconds = menu?.calculateLongestSingleLoopDurationInSeconds();
        this.options?.rotationInterval?.set(menu?.id, loopCount * lengthOfOneLoopInSeconds);
      }
      menu.intervalDecoded = true;
    }
  }

  private decodeMarketingLoopingContentDisplayIntervalsFromLoopCount(menu: Menu) {
    if (menu instanceof MarketingLoopingContentMenu || menu instanceof MarketingSmartPlaylistMenu) {
      const loopCount = this.options?.rotationInterval?.get(menu?.id);
      const lengthOfOneLoopInSeconds = menu?.calculateMarketingLoopDurationInSeconds();
      this.options?.rotationInterval?.set(menu?.id, loopCount * lengthOfOneLoopInSeconds);
      menu.intervalDecoded = true;
    }
  }

  private setOrientationOfMenusBasedOnDisplay() {
    this.configurations?.forEach(it => it.displaySize = this.displaySize);
  }

  private checkForMenuColumnCountDiscrepancies() {
    const productMenus = this.configurations?.filter(it => it instanceof ProductMenu) as ProductMenu[] ?? [];
    productMenus.forEach(it => it.checkForMenuColumnCountDiscrepancies());
  }

  private filterAndSortActiveMenus(): void {
    const overrideIds: string[] = [];
    this?.options?.overrides?.forEach((value, key) => {
      if (value?.hasIntervalOrTimeWindow()) overrideIds.push(key);
    });
    this.activeMenus = this.configurations?.filter(menu => !overrideIds.includes(menu.id)) ?? [];
    this.intervalMenus = this.configurations?.filter(menu => overrideIds.includes(menu.id)) ?? [];
    this.setMenuIntervals();

    if (exists(this.activeMenus?.length)) {
      this.activeMenus = SortUtils.sortMenusByRotationOrder(this.activeMenus, this.options);
      this.activeMenus = this.filterMenusForDisplay(this.activeMenus);
    }

    if (exists(this.intervalMenus?.length)) {
      // Set override times on menu objects explicitly
      this.intervalMenus.forEach(it => it.overrideTime = this.options?.overrides?.get(it.id));
      this.intervalMenus = this.filterMenusForDisplay(this.intervalMenus);
    }
  }

  /**
   * If all menus are filter out, but there was a marketing menu with nothing to show (that was removed), add it back
   * so that the display will show the empty marketing menu warning instead of a black screen.
   */
  private filterMenusForDisplay(menus: Menu[]): Menu[] {
    const isMarketingMenu = (menu): menu is MarketingMenu => menu instanceof MarketingMenu;
    const removeSmartPlaylistMenusWhereAllProductsAreOutOfStock = (menu: Menu): boolean => {
      if (menu instanceof MarketingSmartPlaylistMenu) {
        return !menu?.hasEnabledContentButAllLinkedProductsAreOutOfStock();
      }
      return true;
    };
    const removeUrlPlaylistMenusWithoutUrls = (menu: Menu): boolean => {
      if (menu instanceof MarketingUrlPlaylistMenu) {
        return menu?.hasURL();
      }
      return true;
    };
    const marketingMenus = menus
      ?.filter(isMarketingMenu)
      ?.filter(removeSmartPlaylistMenusWhereAllProductsAreOutOfStock)
      ?.filter(removeUrlPlaylistMenusWithoutUrls) || [];
    const updatedMenus = menus?.filter(menu => (menu?.rotationInterval || 0) !== 0) || [];
    if (exists(marketingMenus?.length) && !updatedMenus?.length) {
      updatedMenus.push(marketingMenus?.firstOrNull());
    }
    return updatedMenus;
  }

  public transformTemplateCollectionMenuIdsToAllowForDuplicateMenusAcrossCollections(): void {
    const updateId = (menuId: string): string => `collectionId:${this.id} menuId:${menuId}`;
    const updateMap = (menuId: string, map: Map<string, any>) => {
      if (map?.has(menuId)) {
        map?.set(updateId(menuId), map?.get(menuId));
        map?.delete(menuId);
      }
    };
    this.configurationIds?.forEach(menuId => {
      updateMap(menuId, this.options?.rotationOrder);
      updateMap(menuId, this.options?.rotationInterval);
      updateMap(menuId, this.options?.overrides);
    });
    this.configurationIds = this.configurationIds?.map(updateId)?.unique();
    this.configurations?.forEach(menu => {
      const menuStyle = menu.styling
        ?.filter(style => style.configurationId === menu.id)
        ?.forEach(style => style.configurationId = updateId(menu.id));
      menu.sections?.forEach(section => section.configurationId = updateId(menu.id));
      menu.templateSections?.forEach(section => section.configurationId = updateId(menu.id));
      menu.id = updateId(menu.id);
    });
  }

  public getActiveIntervalMenus(timezone?: string): Menu[] {
    const activeIntervalMenus = [];
    this.intervalMenus.forEach(it => {
      if (it.isActiveIntervalMenu(timezone)) {
        activeIntervalMenus.push(it);
      }
    });
    return activeIntervalMenus;
  }

  /**
   * Set override times on menu objects explicitly
   */
  private setMenuIntervals() {
    this.activeMenus?.forEach(it => {
      const interval = this?.options?.rotationInterval?.get(it.id) ?? 0;
      it.setMenuIntervalFromDisplay(interval);
    });
    this.intervalMenus?.forEach(it => {
      const interval = this?.options?.rotationInterval?.get(it.id) ?? 0;
      it.setMenuIntervalFromDisplay(interval);
    });
  }

  public isTemplateCollection(): boolean {
    return this.templateIds?.length > 0;
  }

  public isDisplayWithAddedTemplateCollections(): boolean {
    return this.templateCollectionIds?.length > 0;
  }

  /**
   * Wait until consolidation is complete before returning a typed deserialized object.
   *
   * @param data - array of paginated JSON data
   */
  consolidatePagedData(data: any[]): Display {
    const main = data?.firstOrNull();
    const remainingPages = data?.slice(1);
    remainingPages?.forEach(fragment => {
      main.configurations = (main.configurations ?? [])?.concat((fragment?.configurations ?? []));
      main.templateCollections = (main.templateCollections ?? [])?.concat((fragment?.templateCollections ?? []));
    });
    return window.injector.Deserialize.instanceOf(Display, main);
  }

  hasScheduledMenus(): boolean {
    return exists(this.intervalMenus?.length);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return Display.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

}
