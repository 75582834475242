import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';

@Injectable()
export class HarlequinAddressCardStackContentViewModel extends PrintCardContentViewModel {

  public firstColumnProperties$ = this.propertiesBasedOnPools$.pipe(
    map(properties => properties?.length > 2 ? properties.slice(0, 2) : properties)
  );

  public secondColumnProperties$ = this.propertiesBasedOnPools$.pipe(
    map(properties => properties?.length > 2 ? properties.slice(2, 4) : [])
  );

}
