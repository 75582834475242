import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';
import { BehaviorSubject, combineLatest, forkJoin, from, interval, merge, Observable, of, throwError } from 'rxjs';
import { Menu } from '../../models/menu/menu';
import { Asset } from '../../models/image/dto/asset';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, pairwise, sample, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CachePolicy } from '../../models/enum/shared/cachable-image-policy.enum';
import { AssetSize } from '../../models/enum/dto/asset-size.enum';
import { MediaUtils } from '../../utils/media-utils';
import { MenuAPI } from '../../api/menu-api';
import { SortUtils } from '../../utils/sort-utils';
import { DisplayOptions } from '../../models/shared/display-options';
import { ToastService } from '../../services/toast-service';
import { MarketingAsset } from '../../models/image/dto/marketing-asset';
import { ActiveToast } from 'ngx-toastr';
import { DisplayMenuCoupling } from '../../couplings/display-menu-coupling.service';
import { DeprecatedMarketingMenu } from '../../models/menu/deprecated-marketing-menu';
import { MarketingSmartPlaylistMenu } from '../../models/menu/marketing/marketing-smart-playlist-menu';
import { ProductMenu } from '../../models/menu/product-menu';
import { PrefetchMediaAsset } from '../../models/image/dto/prefetch-media-asset';
import { Section } from '../../models/menu/section/section';
import { LoopingContentSection } from '../../models/menu/section/looping-content/looping-content-section';
import { exists } from '../../functions/exists';

type ToastData = [number, number, string, boolean];
const emptyToastData = [null, null, null, null];
type DownloadStatus = { loaded: number; total: number };

@Injectable({ providedIn: 'root' })
export class PrefetchMediaService extends BaseService {

  constructor(
    private menuAPI: MenuAPI,
    private toastService: ToastService,
    private displayMenuCoupling: DisplayMenuCoupling
  ) {
    super();
    this.startClearToastSafetyNet();
  }

  private readonly MEGA_BYTE = 1000000;
  private readonly SAMPLE_RATE_IN_MILLISECONDS = 1000;
  private readonly CLEAR_TOAST_DELAY_IN_MILLISECONDS = 1000 * 5;
  private readonly SAFETY_NET_DELAY_IN_MILLISECONDS = 1000 * 60 * 10;

  private activeToast: ActiveToast<any>;
  private safetyNetJobNumber: NodeJS.Timeout;
  private readonly hideDownloadToast$ = this.displayMenuCoupling.hideDownloadToast$;
  private readonly screenshotMode$ = this.displayMenuCoupling.screenshotMode;

  private readonly _firstLoad = new BehaviorSubject<boolean>(true);
  private readonly firstLoad$ = this._firstLoad.pipe(distinctUntilChanged());
  connectToFirstLoad = (firstLoad: boolean) => this._firstLoad.next(firstLoad);

  private readonly _allMenus = new BehaviorSubject<Menu[]>(null);
  public readonly allMenus$ = this._allMenus as Observable<Menu[]>;
  connectToAllMenus = (menus: Menu[]) => this._allMenus.next(menus);

  private readonly _displayOptions = new BehaviorSubject<DisplayOptions>(null);
  private readonly displayOptions$ = this._displayOptions as Observable<DisplayOptions>;
  connectToDisplayOptions = (displayOptions: DisplayOptions) => this._displayOptions.next(displayOptions);

  private readonly _downloadedBytes = new BehaviorSubject<DownloadStatus>(null);
  private readonly downloadedBytes$ = this._downloadedBytes as Observable<DownloadStatus>;
  connectToDownloadedBytes = (downloadedBytes: DownloadStatus) => this._downloadedBytes.next(downloadedBytes);

  private readonly _nAssets = new BehaviorSubject<number>(0);
  public readonly nAssets$ = this._nAssets as Observable<number>;
  connectToNumberOfAssets = (nAssets: number) => this._nAssets.next(nAssets);

  private readonly _nAssetsDownloaded = new BehaviorSubject<number>(0);
  public readonly nAssetsDownloaded$ = this._nAssetsDownloaded as Observable<number>;
  connectToNumberOfAssetsDownloaded = (nAssetsDownloaded: number) => this._nAssetsDownloaded.next(nAssetsDownloaded);

  public readonly downloadSpeedString$ = this.downloadedBytes$.pipe(
    sample(interval(this.SAMPLE_RATE_IN_MILLISECONDS)),
    pairwise(),
    map(([prev, curr]) => {
      const sampleFromSameDownload = prev?.total === curr?.total;
      const megaBytes = (curr?.loaded - prev?.loaded) / this.MEGA_BYTE;
      const megaBytesPerSecond = megaBytes / (this.SAMPLE_RATE_IN_MILLISECONDS / 1000);
      const speedString = megaBytesPerSecond < 1
        ? this.getNormalizedSpeed(megaBytesPerSecond * 1000) + ' KB/s'
        : this.getNormalizedSpeed(megaBytesPerSecond) + ' MB/s';
      return sampleFromSameDownload ? speedString : null;
    }),
    filter(speedInMegaBytes => speedInMegaBytes !== null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * We can remove duplicates from this pipeline because the asset service is smart enough to share the same downloaded
   * blob across multiple asset objects referencing the same md5 hash.
   */
  private uniqueSortedMenus = ([menus, displayOptions]: [Menu[], DisplayOptions]): Menu[] => {
    const removeDuplicates = (m: Menu, i: number, arr: Menu[]) => {
      return arr?.findIndex(menu => menu?.getOriginalMenuId() === m?.getOriginalMenuId()) === i;
    };
    const uniqueMenus = menus?.filter(removeDuplicates);
    return exists(displayOptions)
      ? SortUtils.sortMenusByRotationOrder(uniqueMenus, displayOptions)
      : uniqueMenus;
  };

  /**
   * Within the updated marketing menu architecture, we use section data models. Therefore, the asset is
   * stored within the section data model, and we don't have to fetch the assets separately from the API.
   */
  private parallelPrefetchAssetPipelines$ = (menus: Menu[]): Observable<[Menu, PrefetchMediaAsset[]][]> => {
    const getAssets$: Observable<[Menu, PrefetchMediaAsset[]]>[] = [];
    menus?.forEach(menu => {
      if (menu instanceof MarketingSmartPlaylistMenu || menu instanceof ProductMenu) {
        const prefetchAssets = (menu?.sections as Section[] | null | undefined)
          ?.filter(section => section instanceof LoopingContentSection ? !section?.hideSection : true)
          ?.map(section => section?.image)
          ?.filter((image): image is PrefetchMediaAsset => image instanceof PrefetchMediaAsset && image?.isValid())
          ?? [];
        getAssets$.push(of([menu, prefetchAssets]));
      } else if (menu instanceof DeprecatedMarketingMenu) {
        getAssets$.push(this.getMarketingMenuAssets(menu));
      } else {
        getAssets$.push(of([menu, [] as PrefetchMediaAsset[]]));
      }
    });
    return forkJoin(...getAssets$);
  };

  private buildAssetMap = (allAssets: [Menu, PrefetchMediaAsset[]][]) => {
    const assetMap = new Map<string, PrefetchMediaAsset[]>();
    allAssets?.forEach(([menu, assets]) => assetMap?.set(menu?.getOriginalMenuId(), assets));
    return assetMap;
  };

  /**
   * Must use menu?.getOriginalMenuId() when setting the map keys, and when accessing the map keys,
   * or else you'll be pointing to undefined data.
   */
  public readonly prefetchMenuAssets$ = combineLatest([
    this.allMenus$,
    this.displayOptions$
  ]).pipe(
    map(menus => this.uniqueSortedMenus(menus)),
    switchMap(uniqueSortedMenus => this.parallelPrefetchAssetPipelines$(uniqueSortedMenus)),
    map(marketingAssets => this.buildAssetMap(marketingAssets)),
    shareReplay(1)
  );

  public readonly marketingMenuAssets$ = this.prefetchMenuAssets$.pipe(
    map(assetMap => {
      const marketingMenuAssets = new Map<string, MarketingAsset[]>();
      assetMap?.forEach((assets, menuId) => {
        const isMarketingAsset = (a: PrefetchMediaAsset): a is MarketingAsset => a instanceof MarketingAsset;
        const marketingAssets = assets?.filter(isMarketingAsset);
        if (marketingAssets?.length) marketingMenuAssets?.set(menuId, marketingAssets);
      });
      return marketingMenuAssets;
    }),
    shareReplay(1)
  );

  public showDownloadSpeedToast$ = combineLatest([
    this.hideDownloadToast$,
    this.screenshotMode$,
    this.downloadSpeedString$,
    this.firstLoad$.pipe(debounceTime(2000))
  ]).pipe(
    map(([hideDownloadToast, screenshotMode, speedString, firstLoad]) => {
      if (hideDownloadToast) return false;
      return !screenshotMode && exists(speedString) && firstLoad;
    })
  );

  private listenAndDownloadAssets = this.prefetchMenuAssets$.pipe(
    filter(assetMap => assetMap?.size > 0),
    tap(_ => this.connectToNumberOfAssetsDownloaded(0)),
    switchMap(assetMap => {
      const allAssets: Asset[] = [];
      assetMap?.forEach((assets, _) => allAssets?.push(...(assets ?? [])));
      this.connectToNumberOfAssets(allAssets?.length);
      return from(allAssets).pipe(
        concatMap(asset => {
          asset?.getAsset(CachePolicy.Service, AssetSize.Original, MediaUtils.DefaultCacheTimeInSeconds);
          return merge(
            asset?.sizePriorityUrl$?.pipe(filter(it => exists(it))),
            asset?.priorityUrlFailed$
          ).pipe(
            take(1)
          );
        })
      );
    }),
    takeUntil(this.onDestroy)
  ).subscribe(_ => {
    this.startClearToastSafetyNet();
    this.nAssetsDownloaded$.once(nDownloaded => this.connectToNumberOfAssetsDownloaded(nDownloaded + 1));
  });

  private downloadSpeedData$ = combineLatest([
    this.nAssetsDownloaded$,
    this.nAssets$,
    this.downloadSpeedString$,
    this.firstLoad$
  ]);

  private listenToNumberOfAssetsDownloaded = combineLatest([
    this.nAssetsDownloaded$,
    this.nAssets$.pipe(filter(nAssets => nAssets > 0)),
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([nDownloaded, nAssets]) => {
      if (nDownloaded === nAssets) this.connectToFirstLoad(false);
    });

  private listenForToastState = this.showDownloadSpeedToast$.pipe(
    switchMap(show => (show ? this.downloadSpeedData$ : of(emptyToastData))),
    takeUntil(this.onDestroy)
  ).subscribe((toastData: ToastData) => {
    const [nAssetsDownloaded, nAssets, speed, firstLoad] = toastData;
    const canUpdate = (nAssets > 0) && exists(speed);
    if (canUpdate) {
      this.updateToast(toastData);
      if (nAssetsDownloaded === nAssets) {
        this.updateToastWithComplete();
        this.clearToastAfterDelay(this.CLEAR_TOAST_DELAY_IN_MILLISECONDS);
      }
    }
  });

  private startClearToastSafetyNet(): void {
    if (this.safetyNetJobNumber) clearTimeout(this.safetyNetJobNumber);
    this.safetyNetJobNumber = this.clearToastAfterDelay(this.SAFETY_NET_DELAY_IN_MILLISECONDS);
  }

  private updateToastWithComplete(): void {
    if (exists(this.activeToast)) {
      this.activeToast.toastRef.componentInstance.message = `Completed`;
    }
  }

  private updateToast([nAssetsDownloaded, nAssets, speed]: ToastData): void {
    const title = 'Downloading Assets';
    const msg = `${nAssetsDownloaded}/${nAssets} at ${speed}`;
    const opts = { disableTimeOut: true };
    if (!this.activeToast) {
      this.activeToast = this.toastService.publishInfoMessage(msg, title, opts);
    } else {
      this.activeToast.toastRef.componentInstance.message = msg;
    }
  }

  private clearToastAfterDelay(ms: number): NodeJS.Timeout {
    const clearToastData = () => {
      this.activeToast?.toastRef?.close();
      this.activeToast = undefined;
    };
    return setTimeout(clearToastData, ms);
  }

  private getMarketingMenuAssets(menu: DeprecatedMarketingMenu): Observable<[Menu, PrefetchMediaAsset[]]> {
    return this.menuAPI.GetMarketingMenuAssets(menu).pipe(
      map(marketingAssets => {
        const enabledSortedAssets = marketingAssets
          ?.filter(asset => menu?.isAssetEnabled(asset))
          ?.sort((a, b) => menu?.getAssetPosition(a) - menu?.getAssetPosition(b));
        return [menu, enabledSortedAssets] as [Menu, MarketingAsset[]];
      }),
      catchError(err => {
        console.error('getMarketingMenuAssets api error');
        return throwError(err);
      })
    );
  }

  private getNormalizedSpeed(speed: number): string {
    switch (true) {
      case speed >= 1000:
        return speed?.toFixed(0);
      case speed >= 100:
        return speed?.toFixed(1);
      case speed >= 10:
        return speed?.toFixed(2);
      default:
        return speed?.toFixed(3);
    }
  }

}
