import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { AddressCardStack } from './address-card-stack';
import { FikaBeverageAddressCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/address-cards-1x3/fika-beverage-address-card-stack-background/fika-beverage-address-card-stack-background.component';
import { FikaBeverageAddressCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/address-cards-1x3/fika-beverage-address-card-stack-content/fika-beverage-address-card-stack-content.component';
import { PaperSize } from '../../../../shared/paper-size';
import type { CardData } from '../../../../print-cards/card-data';

export class FikaBeverageAddressCardStack extends AddressCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return FikaBeverageAddressCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FikaBeverageAddressCardStackContentComponent;
  }

  getCardHeightInInches(): number {
    return 1.25;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 12;
  }

  nRows(): number {
    return 6;
  }

}
