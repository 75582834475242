import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { NumberUtils } from '../../../utils/number.utils';

export class VariantInventory implements Deserializable, UniquelyIdentifiable {

  public variantId: string;
  public quantityInStock: number;
  public firstInventory: number;
  public lastRestock: number;
  public lastThresholdRestock: number;
  public lastModified: number;
  // Unique Identifier
  public uniqueIdentifier: string = '';

  public onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `
      -${this.variantId}
      -${this.quantityInStock}
      -${this.firstInventory}
      -${this.lastRestock}
      -${this.lastThresholdRestock}
    `;
  }

  inStock(): boolean {
    return NumberUtils.floatFirstGreaterThanSecond(this.quantityInStock, 0);
  }

}
