import { Component, OnChanges, SecurityContext, SimpleChanges } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../menu-header-title-image.component';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime, map } from 'rxjs/operators';
import { AssetSize } from '../../../../../../../../../../models/enum/dto/asset-size.enum';
import { Menu } from '../../../../../../../../../../models/menu/menu';
import { DateUtils } from '../../../../../../../../../../utils/date-utils';
import { CachePolicy } from '../../../../../../../../../../models/enum/shared/cachable-image-policy.enum';
import { exists } from '../../../../../../../../../../functions/exists';

@Component({
  selector: 'app-menu-header-title-image-with-background-asset',
  templateUrl: './menu-header-title-image-with-background-asset.component.html'
})
export class MenuHeaderTitleImageWithBackgroundAssetComponent extends MenuHeaderTitleImageComponent
  implements OnChanges {

  constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  private menuSubject = new BehaviorSubject<Menu>(null);
  private bgImg: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public image$ = combineLatest([
    this.menuSubject,
    this.bgImg
  ]).pipe(
    map(([menu, url]) => {
      if (menu.backgroundImage?.isImage()) {
        return url;
      } else {
        return null;
      }
    }),
    debounceTime(1)
  );

  setupViews() {
    super.setupViews();
    this.setMenuSubject();
  }

  setupBindings() {
    super.setupBindings();
    this.listenToImg();
  }

  listenToImg() {
    const imgKey = 'backgroundKey';
    this.destroyImageSub(imgKey);
    let s;
    this.fetchAsset();
    if (this?.menu?.backgroundImage?.sizePriorityUrl$) {
      const asset = this?.menu.backgroundImage;
      s = this.bgImg
        .bind(this?.menu.backgroundImage?.sizePriorityUrl$
          .pipe(map((url) => {
            if (url && url !== '' && asset.isImage()) {
              const imgUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
              return `url(${imgUrl})`;
            } else {
              return null;
            }
          })));
    } else {
      this.bgImg.next(null);
    }
    if (s) {
      this.pushImageSub(imgKey, s);
    }
  }

  private fetchAsset() {
    const asset = this?.menu?.backgroundImage;
    const size = AssetSize.Original;
    const cacheForNSeconds = (DateUtils.unixOneHour() * 12);
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (exists(asset) && exists(size) && validCacheTime) {
      const policy = CachePolicy.Service;
      this?.menu?.backgroundImage?.getAsset(policy, size, cacheForNSeconds);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) {
      this.setMenuSubject();
    }
  }

  private setMenuSubject() {
    this.menuSubject.next(this.menu);
  }

}
