import { SectionSortProductInfo } from '../models/enum/dto/section-sort-option.enum';
import { AssetLibraryType } from '../models/enum/dto/asset-library-type.enum';
import type { DisplayOptions } from '../models/shared/display-options';
import type { VariantBadge } from '../models/product/dto/variant-badge';
import type { SectionColumnViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { VariantAsset } from '../models/image/dto/variant-asset';
import type { Label } from '../models/menu/labels/label';
import type { Section } from '../models/menu/section/section';
import type { Menu } from '../models/menu/menu';
import type { OrderFeaturedVariants } from '../models/menu/marketing/FeaturedProduct/order-featured-variants';

/**
 * A simplified interface for sorting in JavaScript.
 *
 * Sort functions in JavaScript take in two parameters, a and b, and return a number.
 * If the number is negative, a is sorted before b.
 * If the number is positive, b is sorted before a.
 * If the number is zero, a and b are sorted in the same order.
 * Therefore, this is a handy dandy enum to transform these numbers into a human-readable format.
 */
export enum Move {
  ALeft = -1,
  ARight = 1,
  BLeft = 1,
  BRight = -1,
  Nothing = 0
}

export enum SortOrderPosition {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3
}

// @dynamic
export class SortUtils {

  static DEFAULT_SECTION_TERTIARY_SORT = SectionSortProductInfo.TitleAsc;

  static getMovement(num: number): Move {
    if (num < 0) return Move.ALeft;
    if (num > 0) return Move.ARight;
    return Move.Nothing;
  }

  static sharedSortId(s: string) {
    return s?.replace(/_((asc|ASC)|(desc|DESC))/, '');
  }

  static numberAscending = (a: number, b: number): Move => SortUtils.getMovement(a - b);
  static numberDescending = (a: number, b: number): Move => SortUtils.getMovement(b - a);

  static stringAscending = (a: string, b: string): Move => {
    return SortUtils.getMovement(a?.localeCompare(b, 'en', { numeric: true }));
  };
  static stringDescending = (a: string, b: string): Move => {
    return SortUtils.getMovement(b?.localeCompare(a, 'en', { numeric: true }));
  };

  static parseFloatFromStringAscending = (a: string, b: string): Move => {
    const aCompare = parseFloat(a);
    const bCompare = parseFloat(b);
    if (Number.isNaN(aCompare)) return Move.ARight;
    if (Number.isNaN(bCompare)) return Move.BRight;
    return SortUtils.numberAscending(aCompare, bCompare);
  };
  static parseFloatFromStringDescending = (a: string, b: string): Move => {
    const aCompare = parseFloat(a);
    const bCompare = parseFloat(b);
    if (Number.isNaN(aCompare)) return Move.ARight;
    if (Number.isNaN(bCompare)) return Move.BRight;
    return SortUtils.numberDescending(aCompare, bCompare);
  };

  static numberAscendingNegativesAndZerosAndNullsLast = (a: number, b: number): Move => {
    const aIsNull = !a || a <= 0;
    const bIsNull = !b || b <= 0;
    if (aIsNull && bIsNull) return Move.Nothing;
    if (aIsNull) return Move.ARight;
    if (bIsNull) return Move.BRight;
    return SortUtils.numberAscending(a, b);
  };
  static numberDescendingNegativesAndZerosAndNullsLast = (a: number, b: number): Move => {
    const aIsNull = !a || a <= 0;
    const bIsNull = !b || b <= 0;
    if (aIsNull && bIsNull) return Move.Nothing;
    if (aIsNull) return Move.ARight;
    if (bIsNull) return Move.BRight;
    return SortUtils.numberDescending(a, b);
  };

  static numberAscendingNegativesAndNullsLast = (a: number, b: number): Move => {
    const aIsNull =  a < 0 || a === null || a === undefined;
    const bIsNull =  b < 0 || b === null || b === undefined;
    if (aIsNull && bIsNull) return Move.Nothing;
    if (aIsNull) return Move.ARight;
    if (bIsNull) return Move.BRight;
    return SortUtils.numberAscending(a, b);
  };
  static numberDescendingNegativesAndNullsLast = (a: number, b: number): Move => {
    const aIsNull =  a < 0 || a === null || a === undefined;
    const bIsNull =  b < 0 || b === null || b === undefined;
    if (aIsNull && bIsNull) return Move.Nothing;
    if (aIsNull) return Move.ARight;
    if (bIsNull) return Move.BRight;
    return SortUtils.numberDescending(a, b);
  };

  static numberAscendingZerosAndNullsLast = (a: number, b: number): Move => {
    if (!a && !b) return Move.Nothing;
    if (!a) return Move.ARight;
    if (!b) return Move.BRight;
    return SortUtils.numberAscending(a, b);
  };
  static numberDescendingZerosAndNullsLast = (a: number, b: number): Move => {
    if (!a && !b) return Move.Nothing;
    if (!a) return Move.ARight;
    if (!b) return Move.BRight;
    return SortUtils.numberDescending(a, b);
  };

  static nullsLast(a: any, b: any): Move {
    const aIsNull = a === null || a === undefined || a === '' || a === '-' || a === '--' || a <= 0;
    const bIsNull = b === null || b === undefined || b === '' || b === '-' || b === '--' || b <= 0;
    if (aIsNull && !bIsNull) return Move.ARight;
    if (bIsNull && !aIsNull) return Move.BRight;
    return Move.Nothing;
  }

  static stringAscendingNullsLast = (a: string, b: string): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.stringAscending(a, b);
  };
  static stringDescendingNullsLast = (a: string, b: string): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.stringDescending(a, b);
  };

  /**
   * DefaultAssetLibrarySortOrder as defined in API.
   * Client should only pass this value to API if they want to bypass any untagged assets.
   */
  static defaultAssetLibrarySortOrder(): AssetLibraryType[] {
    return [
      AssetLibraryType.Product,
      AssetLibraryType.Package,
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
      AssetLibraryType.Marketing,
      AssetLibraryType.BitBud,
      AssetLibraryType.Custom1,
      AssetLibraryType.Custom2,
      AssetLibraryType.Custom3
    ];
  }

  static variantAssetLibrarySort(
    a: VariantAsset,
    b: VariantAsset,
    assetTypeSortOrder: string[]
  ): Move {
    const sortOrderMap = new Map<string, number>();
    assetTypeSortOrder = assetTypeSortOrder?.length > 0 ? assetTypeSortOrder : SortUtils.defaultAssetLibrarySortOrder();
    assetTypeSortOrder.forEach((assetType, index) => sortOrderMap.set(assetType, index));
    const getOrderNumber = (assetLibraryType: AssetLibraryType) => {
      return sortOrderMap.get(assetLibraryType);
    };
    const aOrderNumber = getOrderNumber(a?.assetType);
    const bOrderNumber = getOrderNumber(b?.assetType);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  static columnViewModelByTitle = (a: SectionColumnViewModel, b: SectionColumnViewModel): Move => {
    return SortUtils.getMovement((a.columnTitle).localeCompare(b.columnTitle, 'en', { numeric: true }));
  };

  static columnViewModelByOrdering(a: SectionColumnViewModel, b: SectionColumnViewModel): Move {
    return SortUtils.numberAscending(a?.position, b?.position);
  }

  // Sort Menus

  static sortMenusByRotationOrder<T extends Menu>(menus: T[], displayOptions: DisplayOptions): T[] {
    return menus?.sort((a, b) => SortUtils.sortMenusByRotationOrderFunction(a, b, displayOptions));
  }

  protected static sortMenusByRotationOrderFunction<T extends Menu>(a: T, b: T, displayOptions: DisplayOptions): Move {
    return SortUtils.numberAscending(
      SortUtils.getSortOrder(displayOptions, a.id),
      SortUtils.getSortOrder(displayOptions, b.id)
    );
  }

  protected static getSortOrder(displayOptions: DisplayOptions, configId: string): number {
    if (!displayOptions || !displayOptions.rotationOrder) {
      return 99;
    }
    return displayOptions.rotationOrder.get(configId) ?? 99;
  }

  // Section

  public static sortSectionsByPriority(a: Section, b: Section): Move {
    return SortUtils.numberAscending(a?.priority, b?.priority);
  }

  // Badge

  static sortBadges = (a: VariantBadge, b: VariantBadge): Move => {
    return SortUtils.getMovement(a?.id?.localeCompare(b?.id));
  };

  static sortBadgesByName = (a: VariantBadge, b: VariantBadge): number => {
    return SortUtils.getMovement(a?.name?.localeCompare(b?.name, 'en', { numeric: true }));
  };

  // Labels

  static sortLabelsByPriority = (a: Label, b: Label): Move => {
    return SortUtils.numberAscending(a?.priority, b?.priority);
  };

  static menuAssetByAscendingPos = (a: OrderFeaturedVariants, b: OrderFeaturedVariants): Move => {
    return SortUtils.numberAscending(a?.position, b?.position);
  };

}
