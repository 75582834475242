import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { PrintCardInterface } from '../../../../interface/print-card.interface';
import type { CardData } from '../../../../../../models/print-cards/card-data';
import { PrintCardContentViewModel } from './print-card-content-view-model';
import { takeUntil } from 'rxjs/operators';
import { LabelInflatorComponent } from '../../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import { CardContentComponent } from './card-content.component';

@Component({ selector: 'app-print-card-content', template: '' })
export abstract class PrintCardContentComponent extends CardContentComponent
  implements PrintCardInterface, OnChanges {

  constructor(
    public viewModel: PrintCardContentViewModel,
    protected elementRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  @Input() data: CardData;
  @Input() parentHeight: number;
  @Input() parentWidth: number;
  @Input() preview: boolean;
  @ViewChild('labelCalculator') labelCalculator?: LabelInflatorComponent;

  setupViews() {
    this.handleBleedClass();
  }

  setupBindings() {
    this.labelCalculator?.currentLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToLabelText(labelText));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.handleBleedClass();
      this.viewModel.connectToData(this.data);
    }
    if (changes.preview) {
      this.handleBleedClass();
      this.viewModel.connectToPreview(this.preview);
    }
  }

  private handleBleedClass(): void {
    (this.data?.section?.isOnRegularPaper() && !this.preview)
      ? this.renderer2.addClass(this.elementRef.nativeElement, 'bleeding')
      : this.renderer2.removeClass(this.elementRef.nativeElement, 'bleeding');
  }

}
