import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { DougShelfTalkerContentViewModel } from './doug-shelf-talker-content-view-model';
import type { DougShelfTalkerCardData } from '../../../../../../../../models/shelf-talkers/doug-shelf-talker-card-data';

@Component({
  selector: 'app-doug-shelf-talker-content',
  templateUrl: './doug-shelf-talker-content.component.html',
  styleUrls: ['./doug-shelf-talker-content.component.scss'],
  providers: [DougShelfTalkerContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougShelfTalkerContentComponent extends RenderContextComponent implements OnChanges {

  constructor(public viewModel: DougShelfTalkerContentViewModel) {
    super();
  }

  @Input() shelfTalkerCardData: DougShelfTalkerCardData;

  setupBindings() {}

  setupViews() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shelfTalkerCardData) {
      this.viewModel.connectToShelfTalkerCardData(this.shelfTalkerCardData);
    }
  }

}
