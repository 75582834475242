import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { PopsCannabis5By5CardStackContentViewModel } from './pops-cannabis-5-by-5-card-stack-content-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-pops-cannabis5by5-card-stack-content',
  templateUrl: './pops-cannabis-5-by-5-card-stack-content.component.html',
  styleUrls: ['./pops-cannabis-5-by-5-card-stack-content.component.scss'],
  providers: [PopsCannabis5By5CardStackContentViewModel]
})
export class PopsCannabis5By5CardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: PopsCannabis5By5CardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @ViewChild('compounds') compounds: ElementRef<HTMLDivElement>;
  @ViewChild('labelAndBadges') labelAndBadges: ElementRef<HTMLDivElement>;

  private readonly _compoundsBottom = new BehaviorSubject<number>(null);
  private readonly _labelAndBadgesTop = new BehaviorSubject<number>(null);

  public readonly labelAndBadgesFlexDirection$ = combineLatest([
    this._compoundsBottom,
    this._labelAndBadgesTop
  ]).pipe(
    map(([thcBottom, labelAndBadgesTop]) => {
      if (Number.isFinite(thcBottom) && Number.isFinite(labelAndBadgesTop)) {
        return (labelAndBadgesTop > thcBottom) ? 'row' : null;
      }
      return null;
    })
  );

  public readonly labelSelfAlignment$ = this.labelAndBadgesFlexDirection$.pipe(
    map(flexDirection => flexDirection === 'row' ? 'center' : null)
  );

  setupBindings() {
    super.setupBindings();
    this.viewModel.badgeOrLabelVisible$.pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: visible => {
        if (visible) {
          const compoundsBounds = this.compounds?.nativeElement?.getBoundingClientRect();
          this._compoundsBottom.next(compoundsBounds?.bottom);
          const labelBounds = this.labelAndBadges?.nativeElement?.getBoundingClientRect();
          this._labelAndBadgesTop.next(labelBounds?.top);
        }
      }
    });
  }

}
