import { MarketingMenu } from './marketing-menu';
import { LoopingContentSection } from './section/looping-content/looping-content-section';
import { SectionType } from '../enum/dto/section-type.enum';
import { exists } from '../../functions/exists';

export abstract class UpgradedMarketingLoopingContentMenu extends MarketingMenu {

  public override template?: UpgradedMarketingLoopingContentMenu;
  override sections: LoopingContentSection[];
  override templateSections: LoopingContentSection[];

  public abstract calculateMarketingLoopDurationInSeconds(): number;

  /**
   * Add the section id to the image so that we can use the asset object directly to access rotation intervals.
   */
  protected override deserializeSections(): void {
    this.sections?.forEach(section => {
      section.sectionType = SectionType.LoopingContentSection;
      if (exists(section?.image)) {
        section.image.sectionId = section.id;
      }
    });
    this.sections = window?.injector?.Deserialize?.arrayOf(LoopingContentSection, this.sections) ?? [];
  }

  /**
   * Add the template section id to the image so that we can use the asset object directly to access rotation intervals.
   */
  protected override deserializeTemplateSections(): void {
    this.templateSections?.forEach(templateSection => {
      templateSection.sectionType = SectionType.LoopingContentSection;
      if (exists(templateSection?.image)) {
        templateSection.image.sectionId = templateSection.id;
      }
    });
    const Deserialize = window?.injector?.Deserialize;
    this.templateSections = Deserialize?.arrayOf(LoopingContentSection, this.templateSections) ?? [];
  }

  /**
   * The option maps for templated looping content menus use the TEMPLATE section id as the key. We need to translate
   * this id to the TEMPLATED section id, because we use the templated section for displaying information.
   */
  protected override combineMenuDisplayOptionsWithTemplate(menuTemplate: UpgradedMarketingLoopingContentMenu): void {
    super.combineMenuDisplayOptionsWithTemplate(menuTemplate);
    Object.keys(menuTemplate?.options || {})?.forEach(key => {
      const templateDataField = menuTemplate?.options[key];
      const sectionTemplateIds = this.sections?.map(section => section?.templateSectionId)?.filterNulls();
      if (templateDataField instanceof Map) {
        templateDataField?.forEach((value, subKey) => {
          const section = this.sections?.find(sec => sec?.templateSectionId === subKey);
          const translateKeyFromTemplateSectionIdToSectionId = exists(section);
          if (translateKeyFromTemplateSectionIdToSectionId) {
            this.options[key].set(section?.id, value);
            this.options[key].delete(subKey);
          }
        });
      }
    });
  }

}
