import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';
import type { FireAndFlowerShelfTalkerCardData } from '../../../../../../../../models/shelf-talkers/fire-and-flower-shelf-talker-card-data';
import type { VariantsGroupedByBrand } from '../../../../../../models/variants-grouped-by-brand';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class FireAndFlowerShelfTalkerContentViewModel extends BaseViewModel {

  constructor(
    protected activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public viewingInLiveView$ = this.activatedRoute.url.pipe(
    map(segments => exists(segments?.find(segment => segment?.path === 'live-view')))
  );

  private readonly _shelfTalkerCardData = new BehaviorSubject<FireAndFlowerShelfTalkerCardData|null>(null);
  public readonly shelfTalkerCardData$ = this._shelfTalkerCardData as Observable<FireAndFlowerShelfTalkerCardData|null>;
  connectToShelfTalkerCardData = (data: FireAndFlowerShelfTalkerCardData) => this._shelfTalkerCardData.next(data);

  public readonly cardSize$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.section?.cardSize ?? null)
  );

  public headerText$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getCardTitle() ?? '')
  );

  public readonly companyLogo$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.menu?.companyLogo)
  );

  public readonly variantsGroupedByBrandThenSorted$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getVariantsGroupedByBrandThenSortedBySectionSpecifications()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly variantsSplitInto7Rows$ = this.variantsGroupedByBrandThenSorted$.pipe(
    map(sortedByBrand => {
      const twoListsPerRow: [VariantsGroupedByBrand, VariantsGroupedByBrand][] = [];
      for (let i = 0; i < sortedByBrand.length; i += 2) {
        const first = sortedByBrand?.[i];
        const second = sortedByBrand?.[i + 1] ?? null;
        if (exists(first) || exists(second)) {
          twoListsPerRow.push([first, second]);
        }
      }
      return twoListsPerRow?.take(7);
    })
  );

}
