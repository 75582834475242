import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { ShelfTalkerCardContentComponent } from '../../shared/shelf-talker-card-content.component';
import { DougShelfTalkerPostCardContentViewModel } from './doug-shelf-talker-post-card-content-view-model';

@Component({
  selector: 'app-doug-shelf-talker-post-card-content',
  templateUrl: './doug-shelf-talker-post-card-content.component.html',
  styleUrls: ['./doug-shelf-talker-post-card-content.component.scss'],
  providers: [DougShelfTalkerPostCardContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougShelfTalkerPostCardContentComponent extends ShelfTalkerCardContentComponent {

  constructor(
    public viewModel: DougShelfTalkerPostCardContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2,
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
