import { SectionRowViewModel } from './SectionRowViewModel';
import { StringUtils } from '../../../../../../../../../utils/string-utils';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/enum/shared/section-column-config-data-value';

export class SectionRowViewModelLaCanapa extends SectionRowViewModel {

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid, displayFormat));
  }

  override getTerpene(
    terpenePascalCased: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getTerpene(terpenePascalCased, displayFormat));
  }

}
