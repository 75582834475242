import { ProductMenu } from '../product-menu';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { PriceUtils } from '../../../utils/price-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { BadgeLegendFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/badge-legend-footer/badge-legend-footer.component';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';

export class BlackJackMenu extends ProductMenu {

  getThemeClass(): string {
    return 'black-jack-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowFooter(): boolean {
    return true;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return BadgeLegendFooterComponent;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    let decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    decimal = PriceUtils.removeTrailingZerosFromPriceString(decimal);
    decimal = decimal.replace('.', '');
    return decimal;
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 5) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 7) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.montserratExtraBold,
      FONT.montserratMedium,
      FONT.tzimmiesBold,
      FONT.tzimmiesSemiBold
    ];
  }

}
