import { Pipe, PipeTransform } from '@angular/core';
import type { CardData } from '../../../models/print-cards/card-data';
import { VariantBadge } from '../../../models/product/dto/variant-badge';

@Pipe({
  name: 'printCardBadges'
})
export class PrintCardBadgesPipe implements PipeTransform {

  transform(cardData: CardData): VariantBadge[] | null {
    return cardData?.getAllVariantBadges() || null;
  }

}
