@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/shared/components/fire-and-flower-shelf-talker-content/fire-and-flower-shelf-talker-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
}
:host .fire-and-flower-shelf-talker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  gap: 1rem;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-header {
  width: 100%;
  display: flex;
  gap: 0.625rem;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-header .header-title {
  flex: 1;
  overflow: clip;
  overflow-clip-margin: 0;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  align-content: center;
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 100%;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-header .header-logo {
  height: 2.5rem;
  width: auto;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-body .shelf-talker-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.75rem;
  box-sizing: border-box;
}
:host .fire-and-flower-shelf-talker .fire-and-flower-shelf-talker-body .shelf-talker-row.even {
  background-color: #f6f5f5;
}
/*# sourceMappingURL=fire-and-flower-shelf-talker-content.component.css.map */
