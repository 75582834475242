import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { MissJonesCardStackBackgroundViewModel } from './miss-jones-card-stack-background-view-model';
import type { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-miss-jones-card-stack-background',
  templateUrl: './miss-jones-card-stack-background.component.html',
  styleUrls: ['./miss-jones-card-stack-background.component.scss'],
  providers: [MissJonesCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissJonesCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: MissJonesCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: CardData;

}
