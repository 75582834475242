<div class="component-container" [style.justify-content]="viewModel.backgroundImageAlignment$ | push">
  <img
    *rxIf="viewModel.backgroundImage$ as asset"
    appImage
    class="image-with-color-overlay-and-opacity"
    [asset]="asset"
    [style.object-fit]="viewModel.backgroundImageObjectFit$ | push"
    [style.width.%]="viewModel.backgroundImageWidthAsPercentage$ | push"
    (load)="imageRenderSucceeded()"
    (error)="imageRenderFailed()"
    src=""
    alt="" />
  <!-- color overlay -->
  <div
    class="image-with-color-overlay-and-opacity"
    [class]="viewModel.backgroundAssetWrapperClass$ | push"
    [style.background-color]="viewModel.cardColor$ | push"
    [style.opacity]="viewModel.backgroundOpacity$ | push"
    [style.border-radius]="viewModel.getColorBackgroundAssetBorderRadius$ | push"></div>
</div>
