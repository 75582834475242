import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { Doug5By7ShelfTalkerContentViewModel } from './doug-5-by-7-shelf-talker-content-view-model';
import { ShelfTalkerCardContentComponent } from '../../shared/shelf-talker-card-content.component';

@Component({
  selector: 'app-doug-5-by-7-shelf-talker-content',
  templateUrl: './doug-5-by-7-shelf-talker-content.component.html',
  styleUrls: ['./doug-5-by-7-shelf-talker-content.component.scss'],
  providers: [Doug5By7ShelfTalkerContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Doug5By7ShelfTalkerContentComponent extends ShelfTalkerCardContentComponent {

  constructor(
    public viewModel: Doug5By7ShelfTalkerContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2,
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
