import type { SectionColumnType } from '../enum/shared/section-column-type';

export class ColWidth {

  public type: SectionColumnType;
  public widthPercentage: string;

  constructor(type: SectionColumnType, widthPercentage: number) {
    this.type = type;
    this.widthPercentage = `${widthPercentage}`;
  }

}
