import type { SectionColumnType } from '../../enum/shared/section-column-type';
import type { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class ColumnGrouping {

  columns: SectionColumnType[];
  exists: boolean;

  constructor(columns: SectionColumnType[]) {
    this.columns = columns;
  }

  public setIfGroupingExists(sectionColumnViewModels: SectionColumnViewModel[]): void {
    const visibleColumnTypes = sectionColumnViewModels?.map(column => column.columnType);
    this.exists = visibleColumnTypes?.some(visibleColumn => this.columns.contains(visibleColumn));
  }

}
