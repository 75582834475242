import { Deserializable } from '../../protocols/deserializable';
import { Variant } from './variant';
import { DisplayAttribute } from '../../display/dto/display-attribute';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { InventoryProvider } from '../../enum/dto/inventory.provider';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { StringUtils } from '../../../utils/string-utils';
import { exists } from '../../../functions/exists';

export class Product implements Deserializable, Cachable, UniquelyIdentifiable {

  // DTO Properties
  public id: string;
  public provider: InventoryProvider;
  public name: string;
  public isArchived: boolean;
  public variants: Variant[];
  public displayAttributes: DisplayAttribute;
  // inherited properties from child variants
  public CBD: string;
  public THC: string;
  public classification: StrainType;
  // Cache
  public cachedTime: number;

  static buildArrayCacheKey(companyId, locationId: number): string {
    return `Products-${companyId}-${locationId}`;
  }

  static buildCacheKey(companyId, locationId: number, productId: string): string {
    return `Product-${companyId}-${locationId}-${productId}`;
  }

  static empty(): Product {
    const p = new Product();
    p.id = '';
    p.name = '';
    p.isArchived = false;
    p.variants = [];
    p.CBD = '';
    p.THC = '';
    p.classification = StrainType.UNKNOWN;
    p.cachedTime = 0;
    return p;
  }

  public onDeserialize() {
    this.deserializeVariants();
    this.displayAttributes = window?.injector?.Deserialize?.instanceOf(DisplayAttribute, this.displayAttributes);

    // Set variant properties for parent product
    this.variants.forEach(v => {
      v.productId = this.id;
      v.productName = this.name;
      return v;
    });
  }

  protected deserializeVariants() {
    this.variants = window?.injector?.Deserialize?.arrayOf(Variant, this.variants);
  }

  mergeWithTemplateProduct(templateProduct: Product) {
    if (!templateProduct) return;
    if (!this.variants) this.variants = [];
    templateProduct?.variants?.forEach(templateVariant => {
      const existingVariant = this.variants.find(v => v?.id === templateVariant?.id);
      if (!existingVariant) this.variants.push(templateVariant);
    });
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(companyId, locationId: number): string {
    return Product.buildCacheKey(companyId, locationId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  public setImplicitProperties(enabledVariantIds: string[] | null, secondaryPriceGroupId: string | null) {
    // Set THC/CBD/Classification on product
    const minTHC = Math.min(...this.variants.map(v => v.getNumericCannabinoidOrTerpene('THC'))) ?? 0;
    const minCBD = Math.min(...this.variants.map(v => v.getNumericCannabinoidOrTerpene('CBD'))) ?? 0;
    this.THC = `${minTHC}`;
    this.CBD = `${minCBD}`;
    // Set the label property on the variants
    if (enabledVariantIds === null) {
      this.classification = (this.variants || []).map(v => v.classification).filterNulls().mode();
    } else {
      this.classification = (this.variants.filter(v => enabledVariantIds.contains(v.id)) || [])
        .map(v => v.classification).filterNulls().mode();
    }
    // Compute secondary company price if secondaryPriceGroupId is set on location configuration
    if (exists(secondaryPriceGroupId)) {
      this.computeAndSetSecondaryCompanyPrice(secondaryPriceGroupId);
    }
  }

  computeAndSetSecondaryCompanyPrice(secondaryPriceGroupId: string): void {
    this.variants?.forEach(v => {
      const companyPricing = v?.locationPricing?.find(lp => lp.locationId === v.companyId);
      if (exists(companyPricing)) {
        companyPricing.secondaryPrice = companyPricing?.pricingGroupPrices?.[secondaryPriceGroupId] || null;
      }
    });
  }

  getProductTitle(): string {
    const hasDisplayAttributeName = exists(this.displayAttributes) && exists(this.displayAttributes?.displayName);
    const hasInheritedDisplayAttributeName = exists(this.displayAttributes?.inheritedDisplayAttribute)
      && exists(this.displayAttributes?.inheritedDisplayAttribute?.displayName);
    if (hasDisplayAttributeName) {
      return this.displayAttributes.displayName;
    }
    if (hasInheritedDisplayAttributeName) {
      return this.displayAttributes.inheritedDisplayAttribute.displayName;
    }
    return this.name;
  }

  public getBrand(variantIds: string[] = null): string {
    const selectedVariants = this.getSelectedVariants(variantIds);
    const brands = selectedVariants?.filter(v => exists(v?.brand))?.map(v => v.brand);
    return StringUtils.getStringMode(brands);
  }

  public getManufacturer(variantIds: string[] = null): string {
    const selectedVariants = this.getSelectedVariants(variantIds);
    return StringUtils.getStringMode(selectedVariants?.filter(v => exists(v.manufacturer))?.map(v => v.manufacturer));
  }

  private getSelectedVariants(variantIds: string[]): Variant[] {
    let selectedVariants: Variant[];
    if (variantIds?.length > 0) {
      selectedVariants = this.variants.filter(v => variantIds.contains(v.id));
    } else {
      selectedVariants = this.variants;
    }
    return selectedVariants;
  }

  getStrainType(): StrainType {
    const classifications = this?.variants?.map(v => v.classification);
    if (classifications?.length > 0) {
      return classifications.reduce((accu, curr) => accu || curr);
    }
    return StrainType.UNKNOWN;
  }

  getUniqueIdentifier(): string {
    const variantsId = this.variants.map(v => v?.getUniqueIdentifier()).sort().join(',');
    return `
      -${this.id}
      -${this.name}
      -${variantsId}
      -${this.displayAttributes?.getUniqueIdentifier()}
    `;
  }

}
