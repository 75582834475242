import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { Section } from '../section/section';
import { Product } from '../../product/dto/product';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionWithProducts } from '../section/section-with-products';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { SectionColumnType } from '../../enum/shared/section-column-type';
import { SectionColumnProductInfoType } from '../../enum/shared/section-column-product-info-type';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class JimmyPrintMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'jimmy-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    const thc = rowVm.getCannabinoid('THC');
    const cbd = rowVm.getCannabinoid('CBD');
    if (thc !== '-' && cbd !== '-') {
      return `THC ${thc} \xa0\xa0 CBD ${cbd}`;
    } else {
      return '-';
    }
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    const rowStyling = super.getProductWrapperStyling(section, sectionRowViewModel, odd);
    rowStyling['border-top'] = `1px solid ${this.menuOptions.bodyTextColor}`;
    return rowStyling;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.edsMarketNarrowSlant,
      FONT.futuraPtHeavy,
      FONT.futuraPtExtraBold
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Badge, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Brand, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.QuantityAndSize, 4],
      [SectionColumnProductInfoType.Quantity, 5],
      [SectionColumnProductInfoType.Size, 6],
      [SectionColumnProductInfoType.VariantType, 7],
      [SectionColumnProductInfoType.Stock, 8],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(9),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(10),
      [SectionColumnProductInfoType.VariantPrice, 11],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 12],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
