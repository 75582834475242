import { SectionRowViewModel } from '../../../../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SpotlightMenu } from '../../../../../../../../models/menu/spotlight-menu';
import type { SpotlightSection } from '../../../../../../../../models/menu/section/spotlight-section';
import { ProductType } from '../../../../../../../../models/enum/dto/product-type.enum';
import { StringUtils } from '../../../../../../../../utils/string-utils';
import { SectionColumnConfigDataValue } from '../../../../../../../../models/enum/shared/section-column-config-data-value';

export class SpotlightSectionRowViewModel extends SectionRowViewModel {

  menu: SpotlightMenu;
  section: SpotlightSection;

  public getSize(): string {
    const productType = this.rowVariants[0]?.productType;
    if (productType !== ProductType.Accessories && productType !== ProductType.Other) {
      if (productType === ProductType.Edible || productType === ProductType.Beverage) {
        // For edibles we dont want to show size as it would be 10.5g or 333mL etc.
        return 'Price';
      } else {
        return this.rowVariants[0].getFormattedUnitSize();
      }
    }
    return '';
  }

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid, displayFormat));
  }

  override getTerpene(
    terpenePascalCased: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getTerpene(terpenePascalCased, displayFormat));
  }

}
