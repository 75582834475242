import { PrintCardContentViewModel } from '../shared/print-card-content-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { VariantType } from '../../../../../../models/enum/dto/variant-type.enum';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class OGKushPrintCardContentViewModel extends PrintCardContentViewModel {

  public hideRightSideOfCard$ = combineLatest([
    this.badgeOrLabelVisible$,
    this.isNonCannabinoidVariant$,
    this.cardStack$
  ]).pipe(
    map(([
      badgeOrLabelVisible,
      isNonCannabinoidVariant,
      cardStack
    ]) => {
      const gridMode = cardStack?.isGridMode();
      return !gridMode && isNonCannabinoidVariant && !badgeOrLabelVisible;
    })
  );

  public showVariantType$ = combineLatest([
    this.cardData$,
    this.cardStack$
  ]).pipe(
    map(([cardData, cardStack]) => {
      const gridMode = cardStack?.isGridMode();
      const isCapsule = cardData?.rowVariantType() === VariantType.Capsule;
      return gridMode || !isCapsule;
    })
  );

}
