import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';
import type { VariantsGroupedByBrand } from '../../../../../../models/variants-grouped-by-brand';
import type { DougShelfTalkerCardData } from '../../../../../../../../models/shelf-talkers/doug-shelf-talker-card-data';

@Injectable()
export class DougShelfTalkerContentViewModel extends BaseViewModel {

  constructor(
    protected activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public viewingInLiveView$ = this.activatedRoute.url.pipe(
    map(segments => exists(segments?.find(segment => segment?.path === 'live-view')))
  );

  private readonly _shelfTalkerCardData = new BehaviorSubject<DougShelfTalkerCardData|null>(null);
  public readonly shelfTalkerCardData$ = this._shelfTalkerCardData as Observable<DougShelfTalkerCardData|null>;
  connectToShelfTalkerCardData = (data: DougShelfTalkerCardData) => this._shelfTalkerCardData.next(data);

  public readonly cardSize$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.section?.cardSize ?? null)
  );

  public headerText$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getCardTitle() ?? '')
  );

  public headerTextColor$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getHeaderTextColor() || '#ffffff')
  );

  public accentColor$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getAccentColor() || '#222222'),
  );

  public readonly companyLogo$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.menu?.companyLogo)
  );

  public readonly variantsGroupedByBrandThenSorted$ = this.shelfTalkerCardData$.pipe(
    map(data => data?.getVariantsGroupedByBrandThenSortedBySectionSpecifications()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly variantsSplitInto6Rows$ = this.variantsGroupedByBrandThenSorted$.pipe(
    map(sortedByBrand => {
      const twoListsPerRow: [VariantsGroupedByBrand, VariantsGroupedByBrand][] = [];
      for (let i = 0; i < sortedByBrand.length; i += 2) {
        const first = sortedByBrand?.[i];
        const second = sortedByBrand?.[i + 1] ?? null;
        if (exists(first) || exists(second)) {
          twoListsPerRow.push([first, second]);
        }
      }
      return twoListsPerRow?.take(6);
    })
  );

}
